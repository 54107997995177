import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL, IMAGE_UPLOAD_ERROR } from '../../config/config'
import axios from 'axios'

class CustomerFindCarUploadFile extends Component{
  constructor (props) {
    super(props);
    this.state = {
      customer: this.props.customer,
      customer_file1: IMAGE_NOPHOTO_URL,
      customer_key1: ''
    }

    this.saveCustomerFindcarImage = this.saveCustomerFindcarImage.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);

  }

  componentDidMount () {
    let customer = this.state.customer
    this.setState({
      customer_file1: customer.customer_file1 === 'nophoto.png' ? IMAGE_NOPHOTO_URL: customer.customer_file1,
      customer_key1: customer.customer_key1
    })
  }

  saveCustomerFindcarImage(e,urlImage){
    e.preventDefault();
    let a = document.createElement('a');
    a.href = urlImage;
    a.target = '_blank';
    a.download = urlImage;
    a.click();
    a.remove();
    return false;
  }

  handleUploadFile (event){
    const customer = this.state.customer
    const input_file_name = event.target.name
    let customer_key = ''

    const that = this;
    const customer_find_car_id = customer.customer_find_car_id

    if (input_file_name === 'customer_file1'){
      customer_key = this.state.customer_key1 === null ? '' : this.state.customer_key1
      this.refs.img_customer_file1.src='img/loading.gif'
      this.refs.img_customer_file1.height=32
    }

    const data = new FormData();
    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('customer_find_car_id', customer_find_car_id)
    data.append('customer_key', customer_key)
    data.append('customer_file', input_file_name)

    axios.put(APIURL+'/customer_find_car/image/'+customer_find_car_id, data).then(function(res) {
      const resData = res.data;
      const filename = resData.image;
      const image_key = resData.image_key;
      let image_url = ''

      if(resData.image === undefined) {
        image_url = IMAGE_UPLOAD_ERROR
      } else if (resData.image === 'uploaderror') {
        image_url = IMAGE_UPLOAD_ERROR
      } else {
        image_url = filename
      }

      if( input_file_name === 'customer_file1'){
        that.setState({
          customer_file1: image_url,
          customer_key1: image_key,
        }, () =>  {
          // close && reload data
          that.props.onToggle()
          that.props.onReload()
        })

      }
    })
  }

  render () {
    const {
      width,
      customer
    } = this.props

    let image_url = ''

    /*if(customer.customer_file1 === 'nophoto.png') {
      image_url = IMAGE_NOPHOTO_URL
    } else {
      if (customer.customer_file1 !== null && customer.customer_file1 !== undefined) {
        if (customer.customer_file1.includes('https')) {
          image_url = customer.customer_file1
        }else{
          image_url = IMAGE_FULL_URL + customer.customer_file1
        }
      }else{
        image_url = IMAGE_NOPHOTO_URL
      }
    }*/

    return (
      <Modal isOpen={this.props.toggle} toggle={this.props.onToggle}
             className={'modal-xs'}
             style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
      >
        <ModalHeader toggle={this.props.onToggle}>แนบไฟล์ภาพ</ModalHeader>
        <ModalBody>
          <div className="row p-5">

            <div className="form-group col-sm-5"/>

            <div className="form-group col-sm-5">
              <label htmlFor="name">1) แนบไฟล์ภาพ
                &nbsp;
                <a rel="noopener noreferrer"
                   href={this.state.customer_file1}
                   target="_blank"
                   onClick={(e)=>this.saveCustomerFindcarImage(e, this.state.customer_file1)}
                   className={this.state.customer_file1===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                  <span>Download</span>
                </a>
              </label>
              <div style={styles.fileInputWrapper} >

                <button style={styles.btnFileInput}>
                  <img src={this.state.customer_file1}
                       style={styles.btnFileInputImage}
                       ref="img_customer_file1"
                       alt="เอกสาร 1"
                  />
                </button>
                <input
                  style={styles.fileInput}
                  type="file"
                  name="customer_file1"
                  onChange={this.handleUploadFile}
                />
              </div>
            </div>

          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.onToggle}>ยกเลิก</Button>
        </ModalFooter>
      </Modal>
    )
  }
}


const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  fileInputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 180,
    width: 200,
  },
  btnFileInput: {
    height: 180,
    width: 200,
    display: 'inline-block',
    backgroundColor: "#D2DDE2",
    backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
    backgroundRepeat: "repeat-x",
    color: "#ffffff",
    textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
  },
  btnFileInputImage:{
    position: 'absolute',
    top: 0,
    left: 0,
    height: 180,
    width: 200,
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    height: 180,
    width: 200,
    cursor: 'pointer'
  }
}

export default CustomerFindCarUploadFile