// CustomerDetail

import React, { Component } from 'react';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import CustomerListRow from '../../../components/Customer/CustomerListRow';
import CustomerForm from '../../../components/Customer/CustomerForm';
import CustomerEdit from '../../../components/Customer/CustomerEdit';
import ProfileForm from '../../../components/Customer/ProfileForm';
import { connect } from 'react-redux';
import ProfileListRow from '../ProfileListRow';
import CustomerDocList from '../CustomerDocList/CustomerDocList';
import CustomerDocAdd from '../CustomerDocAdd/CustomerDocAdd';
import Loading from '../../Loading';
import { AlertError, AlertSuccess, AlertWarning } from '../../Alert/Alert'
import { save } from 'save-file';
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import CustomerInstallmentHistory from "../CustomerInstallmentHistory";
import CustomerCredit from "../CustomerCredit";
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { format } from 'date-fns'
import { DateUtil } from '../../../utils/dateUtil'
import { NumberUtil } from '../../../utils/number-util'
import ProfileDetail from '../ProfileDetail/ProfileDetail'


class CustomerDetail extends Component{
    constructor (props) {
        super(props)

        this.state = {
            menuID: [4, 402, 403, 404, 405],
            grouppermissions: [],
            lists: [],
            load_data: true,
            danger: false,
            primary: false,
            primarycustomeredit: false,
            selectCustomer:'',
            customer_id: 0,
            action: "บันทึก",
            customer: '' ,
            activeTab: '1',
            user: JSON.parse(this.props.user),
            toggle_profile_form: false,
            profile_form_status: 'add',
            profile_lists: [],
            profile_selected: {},
            toggle_customer_add: false,
            customer_docs: [],
            customer_doc_form_status: 'add',
            customer_doc_selected: {},
            isLoading: false,
            customer_all: 0,
            is_search: false,
            profile_count: 0,
            width: 0,
            height: 0,
            page: 1,
            company_setting: null,
            is_date_fill: 0,
            search_type: 'all',
            date_start: null,
            date_end: null,
            btnClick: '',
            isProfileReload: false
        }
        this.togglePrimary= this.togglePrimary.bind(this);
        this.togglePrimaryCustomerEdit= this.togglePrimaryCustomerEdit.bind(this);
        this.toggleProfileForm = this.toggleProfileForm.bind(this);
        this.onSubmitProfile = this.onSubmitProfile.bind(this);
        this.onProfileDelete = this.onProfileDelete.bind(this);
        this.onProfileEdit = this.onProfileEdit.bind(this);
        this.toggleCustomerDocAdd = this.toggleCustomerDocAdd.bind(this);
        this.deleteCustomerDoc = this.deleteCustomerDoc.bind(this);
        this.editCustomerDoc = this.editCustomerDoc.bind(this);
        this.loadDataPage = this.loadDataPage.bind(this);
        this.loadProfileDataPage = this.loadProfileDataPage.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.updateCustomer = this.updateCustomer.bind(this);
        this.toggleDangerDelete = this.toggleDangerDelete.bind(this);
        this.loadGroupPermission = this.loadGroupPermission.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);

        // customer detail
        this.loadCustomer = this.loadCustomer.bind(this);
        this.getCustomerBirthDayBB = this.getCustomerBirthDayBB.bind(this);
        this.loadCustomerDoc = this.loadCustomerDoc.bind(this);
    }


    loadGroupPermission() {
        let type = this.state.user.type
        let menuID = this.state.menuID
        getGroupPermission(type, menuID).then(res => {
            if(res.data.result === 'success') {
                this.setState({
                    grouppermissions: res.data.grouppermissions
                })
            }
        })
    }

    loadData () {
        axios.get(APIURL + '/customer/')
          .then(res => {
              //alert (res.data.length)

              this.setState({lists: res.data, load_data: false, customer_id: 0, danger: false})
          }).catch(error => {
            //alert (error)

            this.setState({lists: [], load_data: true, customer_id: 0, danger: false})
        })
    }

    handlePageChange(event) {
        const page = Number(event.selected) + 1

        this.setState({
            page: page,
            customer_all: 0,
            lists: [],
        }, () => this.loadDataPage(page, 'not_search') )
    }

    loadDataPage (page, search_text) {
        // search_type => 'customer' : 'all'
        let search_type = this.state.search_type
        // let search_text2 = search_text === '' ? 'empty' : search_text
        // search_text = not_search
        // console.log('search_type=', search_type) audience
        let date_start =  this.state.date_start === null ? '' : moment(this.state.date_start).format('YYYY-MM-DD')
        let date_end   =  this.state.date_end === null ? '' : moment(this.state.date_end).format('YYYY-MM-DD')

        let criteria = {
            page: page,
            search_type: search_type,
            search_text: search_text,
            date_start: date_start,
            date_end: date_end
        }

        this.setState({
            isLoading: true
        }, () => {
            // let uri = APIURL + '/customer/page/'+page+'/'+search_type+'/'+search_text
            let uri = APIURL + '/customer/criteria';

            // let uri = APIURL + '/customer/page/'+page
            // axios.get(uri)
            axios.post(uri, criteria)
              .then(res => {

                  let customers = res.data.customers
                  let customer_all = res.data.customer_all

                  this.setState({
                      lists: customers,
                      customer_all: customer_all,
                      load_data: false,
                      customer_id: 0,
                      danger: false,
                      isLoading: false,
                      page: page
                  })
              }).catch(error => {
                //alert (error)
                this.setState({lists: [], load_data: true, customer_id: 0, danger: false, isLoading: false})
            })
        })
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            },()=>{
                if(this.state.activeTab === '2' ){
                    // this.loadProfileData();
                    this.loadProfileDataPage(1)
                }else if(this.state.activeTab === '3'){
                    this.loadCustomerDoc();
                }
            });
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidMount () {
        this.mounted = true
        const queryString = require('query-string')
        let customer = queryString.parse(this.props.location.search)

        this.loadGroupPermission();
        // this.loadCompanySetting();

        let customer_id = customer.customer_id

        this.setState({
            customer_id: customer.customer_id
        }, () => {
            this.loadCustomer(customer_id)

        })

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    loadCompanySetting() {
        axios.get(APIURL + '/company_setting/1').then(res => {
            let data = res.data
            let company_setting = data.company_setting
            const is_date_fill = company_setting.is_date_fill;
            this.setState({
                company_setting: company_setting,
                is_date_fill: is_date_fill
            })
        })
    }

    loadCustomer(customer_id) {
        axios.get(APIURL + '/customer/'+customer_id)
          .then(res => {
              if(res.status === 200) {
                  let customer = res.data[0]
                  // console.log(customer)
                  this.setState({
                      selectCustomer: customer,
                      customer_doc_selected: customer
                  }, () => {
                      this.loadCustomerDoc()
                  })
              }
          })
    }

    toggleDanger () {
        this.setState({
            danger: !this.state.danger
        })
    }

    toggleDangerDelete (id) {
        axios.delete(APIURL + '/customer/' + id).then(res => {
            if (res.status === 200) {
                let page = this.state.page
                // this.loadData()
                this.loadDataPage(page, 'not_search')
                this.setState({action: "บันทึก"});
            }
        })
    }

    togglePrimary(customer) {
        let isClose = !this.state.primary

        this.setState({
            primary: isClose
        },()=>{
            if (isClose === false) {
                let page = this.state.page
                this.loadDataPage(page, 'not_search');
            }
        });
    }

    togglePrimaryCustomerEdit() {
        this.setState({
            primarycustomeredit: !this.state.primarycustomeredit
        });
    }

    deleteCustomer (customer) {
        this.setState({customer_id: customer.customer_id, customer:customer})
        this.toggleDanger()
    }

    editCustomer () {
        // this.setState({action: "แก้ไข", customer: customer, customer_id: customer.customer_id});
        // this.setState ({selectCustomer: customer})
        this.togglePrimaryCustomerEdit()
        //alert(customer);
    }

    openDetail(customer) {
        console.log('---openDetail---')
        console.log(customer)

        let action = 'customer-detail'

        this.setState({
            btnClick: action,
            customer_id: customer.customer_id
        })
    }


    addCustomer (event){
        event.preventDefault()
        let that = this ;
        let customer_name = event.target.customer_name.value
        let customer_lastname = event.target.customer_lastname.value
        let customer_card_id = event.target.customer_card_id.value
        let customer_birth = event.target.customer_birth.value
        let customer_age = event.target.customer_age.value
        let customer_mobile = event.target.customer_mobile.value
        let customer_address = event.target.customer_address.value

        let customer_line = event.target.customer_line.value
        let customer_facebook = event.target.customer_facebook.value

        if(customer_name===''){
            return ;
        }
        event.target.customer_name.value = ""
        event.target.customer_lastname.value = ""
        event.target.customer_card_id.value = ""
        event.target.customer_birth.value = ""
        event.target.customer_age.value = ""
        event.target.customer_mobile.value = ""
        event.target.customer_address.value = ""

        event.target.customer_line.value = ""
        event.target.customer_facebook.value = ""

        axios.post(APIURL + '/customer/', {
            customer_name: customer_name,
            customer_lastname: customer_lastname,
            customer_card_id : customer_card_id,
            customer_birth: customer_birth,
            customer_age : customer_age,
            customer_mobile: customer_mobile,
            customer_address : customer_address,
            customer_line : customer_line,
            customer_facebook : customer_facebook,
            status_field: 'show',
            username: 'admin'
        })
          .then(function (response) {

              that.loadData()
              that.setState({ customer: '', customer_id: 0, action: "บันทึก"})
          })
          .catch(function (error) {

          })
    }

    updateCustomer(event){
        event.preventDefault()

        let page = this.state.page;
        let that = this ;
        let customer_id = event.target.customer_id.value ;
        let customer_name = event.target.customer_name.value
        let customer_lastname = event.target.customer_lastname.value
        let customer_card_id = event.target.customer_card_id.value
        let customer_birth = event.target.customer_birth.value
        let customer_age = event.target.customer_age.value
        let customer_mobile = event.target.customer_mobile.value
        let customer_address = event.target.customer_address.value
        //
        let occupation_id = event.target.occupation_id.value
        let customer_income = event.target.customer_income.value
        let province_id = event.target.province_id.value
        //alert(customer_name);
        let guarantor_name = event.target.guarantor_name.value
        let guarantor_address = event.target.guarantor_address.value
        let guarantor_mobile = event.target.guarantor_mobile.value

        let employee_id = event.target.employee_id.value
        let customer_date_book_text = event.target.customer_date_book_text.value
        //let customer_date_book = customer_date_book_text === '' ? '' : this.state.startDateCustomerDateBook
        let customer_date_book = event.target.customer_date_book.value
        let customer_note = event.target.customer_note.value

        let customer_line = event.target.customer_line.value
        let customer_facebook = event.target.customer_facebook.value


        if(customer_name===''){
            return ;
        }
        event.target.customer_name.value = ""
        event.target.customer_lastname.value = ""
        event.target.customer_card_id.value = ""
        event.target.customer_birth.value = ""
        event.target.customer_age.value = ""
        event.target.customer_mobile.value = ""
        event.target.customer_address.value = ""
        //
        event.target.occupation_id.value = ""
        event.target.customer_income.value = ""
        event.target.province_id.value = ""
        //
        event.target.guarantor_name.value = ""
        event.target.guarantor_address.value = ""
        event.target.guarantor_mobile.value = ""

        event.target.employee_id.value = ""
        //event.target.customer_date_book_text.value = ""
        event.target.customer_date_book.value = ""
        event.target.customer_note.value = ""

        event.target.customer_line.value = ""
        event.target.customer_facebook.value = ""

        axios.put(APIURL + '/customer/'+customer_id, {
            customer_id:customer_id,
            customer_name: customer_name,
            customer_lastname: customer_lastname,
            customer_card_id : customer_card_id,
            customer_birth: customer_birth,
            customer_age : customer_age,
            customer_mobile: customer_mobile,
            customer_address : customer_address,
            status_field: 'show',
            username: 'admin',
            guarantor_name: guarantor_name,
            guarantor_address: guarantor_address,
            guarantor_mobile: guarantor_mobile,
            occupation_id: occupation_id,
            customer_income: customer_income,
            province_id: province_id,
            employee_id: employee_id,
            customer_date_book: customer_date_book,
            customer_note: customer_note,
            customer_line: customer_line,
            customer_facebook: customer_facebook
        })
          .then( (res) => {


              AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');



          })
          .catch(function (error) {

          })
    }

    changeCustomer(event){
        let xname =event.target.name
        if(xname==="customer_name"){
            this.setState ({customer:{
                    customer_name: event.target.value
                }})
        }else if(xname==="customer_lastname"){
            this.setState ({customer:{
                    customer_lastname: event.target.value
                }})
        }else if(xname==="customer_card_id"){
            this.setState ({customer:{
                    customer_card_id: event.target.value
                }})
        }else if(xname==="customer_birth"){
            this.setState ({customer:{
                    customer_birth : event.target.value
                }})
        }else if(xname==="customer_age"){
            this.setState ({customer:{
                    customer_age : event.target.value
                }})
        }else if(xname==="customer_mobile"){
            this.setState ({customer:{
                    customer_mobile : event.target.value
                }})
        }else if(xname==="customer_address"){
            this.setState ({customer:{
                    customer_address : event.target.value
                }})
        }else if(xname==="customer_date_book"){
            this.setState ({customer:{
                    customer_date_book : event.target.value
                }})
        }else if(xname==="customer_note"){
            this.setState ({customer:{
                    customer_note : event.target.value
                }})
        }else if(xname==="customer_line"){
            this.setState ({customer:{
                    customer_line : event.target.value
                }})
        }else if(xname==="customer_facebook"){
            this.setState ({customer:{
                    customer_facebook : event.target.value
                }})
        }
    }

    toggleProfileForm() {
        this.setState({
            toggle_profile_form: !this.state.toggle_profile_form
        },()=>{
            // this.loadData();
        });
    }

    toggleCustomerDocAdd() {
        if(!this.state.toggle_customer_add){
            // customer_doc_selected: {},
            this.setState({
                customer_doc_form_status: 'add',
                toggle_customer_add: !this.state.toggle_customer_add
            })
        }else {
            this.setState({
                toggle_customer_add: !this.state.toggle_customer_add
            });
        }
    }

   /* loadProfileData() {
        axios.get(APIURL + '/profile/')
          .then(res => {
              this.setState({profile_lists: res.data})
          }).catch(error => {
            this.setState({profile_lists: []})
        })
    }*/

    loadProfileDataPage(page) {
        axios.get(APIURL + '/profile/page/'+page)
          .then(res => {
              this.setState({
                  profile_lists: res.data.profiles,
                  profile_count: res.data.profile_count
              })
          }).catch(error => {
            this.setState({profile_lists: [], profile_count: 0})
        })
    }

    onSubmitProfile(event) {
        event.preventDefault();
        let profile_from = event.target.profile_from.value;
        let profile_address = event.target.profile_address.value;
        let profile_fn1 = event.target.profile_fn1.value;
        let profile_fn2 = event.target.profile_fn2.value;
        let profile_fn3 = event.target.profile_fn3.value;
        let profile_name = event.target.profile_name.value;
        let profile_ncb1 = event.target.profile_ncb1.value;
        let profile_guarantor1 = event.target.profile_guarantor1.value;
        let profile_guarantor_ncb1 = event.target.profile_guarantor_ncb1.value;
        let profile_guarantor2 = event.target.profile_guarantor2.value;
        let profile_guarantor_ncb2 = event.target.profile_guarantor_ncb2.value;
        let user = this.state.user.user;
        let customer_id = this.state.customer_id
        let profile_id = event.target.profile_id.value;

        if(profile_name==='') {
            AlertError('กรุณากรอกโปรไฟล์ลูกค้า');
            return;
        }

        const data = {
            profile_from: profile_from,
            profile_address: profile_address,
            profile_fn1: profile_fn1,
            profile_fn2: profile_fn2,
            profile_fn3: profile_fn3,
            profile_name: profile_name,
            profile_ncb1: profile_ncb1,
            profile_guarantor1: profile_guarantor1,
            profile_guarantor_ncb1: profile_guarantor_ncb1,
            profile_guarantor2: profile_guarantor2,
            profile_guarantor_ncb2: profile_guarantor_ncb2,
            user: user,
            customer_id: customer_id,
            profile_id: profile_id
        }

        // console.log(this.state.profile_form_status)
        // console.log(data)
        // return

        if(this.state.profile_form_status === 'add') {
            axios.post(APIURL + '/profile', data)
              .then((res) => {
                  if (res.status === 200) {
                      this.toggleProfileForm();
                      // this.loadProfileData();
                      this.setState({
                          isProfileReload: true
                      })
                  }
              })
              .catch((err) => {
                  console.log(err);
              });
        }else{
            axios.put(APIURL + '/profile/'+profile_id, data)
              .then((res) => {
                  if (res.status === 200) {
                      this.toggleProfileForm();
                      // this.loadProfileData();
                      this.setState({
                          isProfileReload: true
                      })
                  }
              })
              .catch((err) => {
                  console.log(err);
              });
        }
    }

    onProfileDelete(profile) {
        // alert('onProfileDelete');
        let profile_id = profile.profile_id;

        if(window.confirm('คุณต้องการลบโปรไฟล์ '+profile_id+ ' ใช่หรือไม่ ?')) {
            axios.delete(APIURL + '/profile/' + profile_id)
              .then((res) => {
                  if (res.status === 200) {
                      //this.toggleProfileForm();
                      this.loadProfileData();
                  }
              })
              .catch((err) => {
                  console.log(err);
              });
        }

    }

    onProfileEdit(profile) {
        this.setState({
            profile_form_status: 'edit',
            toggle_profile_form: !this.state.toggle_profile_form,
            profile_selected: profile
        });
    }

    addCustomerDoc(event){
        event.preventDefault();
        let that = this ;
        let customer_id = event.target.customer_id.value
        let doc_detail = event.target.doc_detail.value
        let form_status = event.target.form_status.value
        let customer_doc_id = event.target.customer_doc_id.value

        if(form_status === 'add')
        {
            const data = new FormData();
            data.append('file', event.target.doc_file.files[0], event.target.doc_file.files[0].name);
            data.append('customer_id', customer_id);
            data.append('doc_detail', doc_detail);
            data.append('user_created', this.state.user.user);

            axios.post(APIURL + '/customer_doc', data)
              .then(function (response) {
                  //that.loadCarDocData()
                  that.loadCustomerDoc()
                  that.toggleCustomerDocAdd()
              })

        }else if(form_status === 'edit') {
            const data = new FormData();
            data.append('file', event.target.doc_file.files[0], event.target.doc_file.files[0].name);
            data.append('customer_id', customer_id);
            data.append('doc_detail', doc_detail);
            data.append('user_created', this.state.user.user);

            axios.post(APIURL + '/customer_doc/edit/'+customer_doc_id, data)
              .then(function (response) {
                  //that.loadCarDocData()
                  that.loadCustomerDoc()
                  that.toggleCustomerDocAdd()
              })

        }

    }

    addCustomerDocNoFile(event) {
        event.preventDefault();
        var that = this ;
        var customer_id = event.target.customer_id.value
        var doc_detail = event.target.doc_detail.value
        var form_status = event.target.form_status.value
        var customer_doc_id = event.target.customer_doc_id.value

        var data = {
            customer_id: customer_id,
            doc_detail: doc_detail,
            user_created: this.state.user.user
        }

        if(form_status === 'add') {
            axios.post(APIURL + '/customer_doc/nofile', data)
              .then(function (response) {

                  //that.loadCarDocData()
                  that.loadCustomerDoc()
                  that.toggleCustomerDocAdd()
              })
        }else if(form_status === 'edit') {
            // alert('form_status='+form_status+' , customer_doc_id='+customer_doc_id)
            axios.put(APIURL + '/customer_doc/edit/nofile/'+customer_doc_id, data)
              .then(function (response) {

                  //that.loadCarDocData()
                  that.loadCustomerDoc()
                  that.toggleCustomerDocAdd()
              })
        }

    }

    loadCustomerDoc() {
        let customer_id = this.state.customer_id
        // customer_docs
        if (customer_id !== '' && customer_id !== 0 && customer_id !== null) {
            axios.get(APIURL+'/customer_doc/'+customer_id)
              .then((res) => {
                  this.setState({customer_docs: res.data})
              })
        }
        // load all customer_doc
        // axios.get(APIURL+'/customer_doc')
        //   .then((res) => {
        //
        //       this.setState({customer_docs: res.data})
        //
        //   })
    }

    deleteCustomerDoc(customer_doc) {
        if(window.confirm('คุณต้องการลบเอกสารลูกค้า '+ customer_doc.customer_doc_id + ' ใช่หรือไม่ ?')) {
            let customer_doc_id= customer_doc.customer_doc_id
            let data = {
                doc_file: customer_doc.doc_file
            }
            axios.post(APIURL+'/customer_doc/delete/'+customer_doc_id,data)
              .then((res) => {
                  this.loadCustomerDoc()
              })
        }
    }
    //
    editCustomerDoc(customer_doc) {
        //open modal for edit
        this.setState({
            customer_doc_selected: customer_doc,
            customer_doc_form_status: 'edit',
            toggle_customer_add: true
        });

    }

    async exportCustomerReport() {

        let search_type = this.state.search_type
        let data = {
            search_type
        }

        this.setState({
            isLoading: true
        }, () => {
            axios.post(APIURL + '/report/customerexcel', data)
              .then(async res => {

                  const excelBuffer = res.data.excelBuffer
                  const currentDatetime = res.data.currentDatetime
                  const fileName = 'รายงานลูกค้า_export_'+currentDatetime+'.xlsx'

                  this.setState({
                      isLoading: false
                  })

                  await save(excelBuffer, fileName)

              }).catch(error => {
            })
        })
    }

    getCustomerBirthDayBB(customer_birth) {

        // console.log(customer_birth, typeof customer_birth)
        if (customer_birth === undefined) {
            return '-'
        }

        let customer_birth_db = customer_birth === null || customer_birth === '0000-00-00' ? '' : customer_birth
        let customer_birth_text = '-'
        if (customer_birth_db !== '') {
            // yyyy-mm-dd
            // let customer_birth_cc = moment(customer_birth_db).format('YYYY-MM-DD')
            let customer_birth_cc = format(new Date(customer_birth_db),'yyyy-MM-dd')
            customer_birth_text = DateUtil.convertCCtoBB(customer_birth_cc)
            // customer_birth = customer_birth_cc
        }
        return customer_birth_text
    }

    render(){

        // if (this.state.btnClick === 'customer-detail') {
        //     const target = '/customer/detail/?customer_id=' + this.state.customer;
        //     console.log('target=', target)
        //     return <Redirect push to={target} target={"_blank"} />;
        // }
        let width = this.state.width
        let page = this.state.page
        let customer = this.state.selectCustomer

        return (
          <div className="col-md-12 mb-4">

              <Loading isLoading={this.state.isLoading} />

              <Nav tabs>

                  {
                      checkGroupPermission(4, this.state.grouppermissions).viewed ? (
                        <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === '1' })}
                              onClick={() => { this.toggle('1'); }}
                            >
                                <i className="icon-options-vertical"/> รายละเอียดลูกค้า
                            </NavLink>
                        </NavItem>
                      ) : null
                  }

                  {
                    checkGroupPermission(402, this.state.grouppermissions).viewed ? (
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                            >
                                <i className="icon-badge"/> โปรไฟล์ลูกค้า
                            </NavLink>
                        </NavItem>
                    ) : null
                  }

                  {
                    checkGroupPermission(403, this.state.grouppermissions).viewed ? (
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}
                            >
                                <i className="icon-docs"/> เอกสารลูกค้า
                            </NavLink>
                        </NavItem>
                    ) : null
                  }

                  {
                    checkGroupPermission(404, this.state.grouppermissions).viewed ? (
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '4' })}
                                onClick={() => { this.toggle('4'); }}
                            >
                                <i className="icon-docs"/> ประวัติการผ่อน
                            </NavLink>
                        </NavItem>
                    ) : null
                }

                  {
                    checkGroupPermission(405, this.state.grouppermissions).viewed ? (
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '5' })}
                                onClick={() => { this.toggle('5'); }}
                            >
                                <i className="icon-docs"/> ประวัติการเช็คเครดิต
                            </NavLink>
                        </NavItem>
                    ) : null
                }

              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                      <div className="row">
                          <div className="col-sm-12 col-md-12">
                              <div className="card card-accent-success">
                                  <div className="card-header card-customer">

                                      <div className="row">
                                          <div className="col-8">
                                              <strong>
                                               รายละเอียดลูกค้า
                                               : {customer.customer_name}{' '}{customer.customer_lastname}
                                              </strong>
                                          </div>
                                          <div className="col-4">
                                              {
                                                  checkGroupPermission(4, this.state.grouppermissions).modified ? (
                                                    <button className="btn btn-head-bar btn-warning float-right "
                                                            style={styles.button}
                                                            onClick={ () => this.editCustomer() }
                                                    >
                                                        <i className="fa fa-edit"/> แก้ไขลูกค้า
                                                    </button>
                                                  ) : null
                                              }
                                          </div>
                                      </div>

                                  </div>

                                  <div className="card-block">
                                      <div className="row">
                                          <div className="col-lg-12">
                                              <div className=" form-group row" style={styles.bottomLine}>
                                                  <label className="col-md-2 "><strong> ชื่อ </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong> {customer.customer_name} </strong>
                                                      </p>
                                                  </div>

                                                  <label className="col-md-2 "><strong> นามสกุล </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong> {customer.customer_lastname} </strong>
                                                      </p>
                                                  </div>

                                                  <label className="col-md-2 "><strong> เลขบัตรประชาชน </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong> {customer.customer_card_id} </strong>
                                                      </p>
                                                  </div>
                                              </div>

                                              <div className=" form-group row" style={styles.bottomLine}>
                                                  <label className="col-md-2 "><strong> วันเดือนปีเกิด (พ.ศ.) </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong> {this.getCustomerBirthDayBB(customer.customer_birth)} </strong>
                                                      </p>
                                                  </div>

                                                  <label className="col-md-2 "><strong> อายุ </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong> {customer.customer_age} </strong>
                                                      </p>
                                                  </div>

                                                  <label className="col-md-2 "><strong> เบอร์โทรศัพท์(1)/(2) </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong> {customer.customer_mobile} /  {customer.customer_mobile2} </strong>
                                                      </p>
                                                  </div>
                                              </div>

                                              <div className=" form-group row" style={styles.bottomLine}>
                                                  <label className="col-md-2"><strong> ที่อยู่ปัจจุบัน </strong></label>
                                                  <div className="col-md-6">
                                                      <p className="text-justify text-primary">
                                                          <strong>{customer.customer_address}</strong>
                                                      </p>
                                                  </div>

                                                  <label className="col-md-2"><strong> จังหวัด </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong>{customer.province_name === null || customer.province_name === '' ? '-' : customer.province_name}</strong>
                                                      </p>
                                                  </div>

                                              </div>

                                              <div className=" form-group row" style={styles.bottomLine}>

                                                  <label className="col-md-2"><strong> Line </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong>{customer.customer_line === null || customer.customer_line === '' ? '-' : customer.customer_line }</strong>
                                                      </p>
                                                  </div>

                                                  <label className="col-md-2"><strong> Facebook </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                        <strong>{customer.customer_facebook === null || customer.customer_facebook === '' ? '-' : customer.customer_facebook }</strong>
                                                      </p>
                                                  </div>

                                                  <label className="col-md-2"><strong> เซลล์ผู้ดูแล </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong>{customer.employee_name === null || customer.employee_name === '' ? '-' : customer.employee_name}</strong>
                                                      </p>
                                                  </div>


                                              </div>

                                              <div className=" form-group row" style={styles.bottomLine}>

                                                  <label className="col-md-2"><strong> อาชีพ </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong>{customer.occupation_name === null || customer.occupation_name === '' ? '-' : customer.occupation_name }</strong>
                                                      </p>
                                                  </div>

                                                  <label className="col-md-2"><strong> รายวัน-รายเดือน </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong>{customer.employment_type === null || customer.employment_type === '' ? '-' : customer.employment_type }</strong>
                                                      </p>
                                                  </div>

                                                  <label className="col-md-2"><strong> อายุงาน </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong>{customer.work_experience === null || customer.work_experience === '' ? '-' : customer.work_experience }</strong>
                                                      </p>
                                                  </div>

                                              </div>
                                              <div className=" form-group row" style={styles.bottomLine}>

                                                  <label className="col-md-2 "><strong> วันที่อายุงานครบ 1 ปี </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong> {this.getCustomerBirthDayBB(customer.date_oneyear_employment)} </strong>
                                                      </p>
                                                  </div>

                                                  <label className="col-md-2"><strong> รายได้/เงินเดือน </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong> {customer.customer_income === null || customer.customer_income === '' ? '-' : NumberUtil.addCommasZeroInt(customer.customer_income)} </strong></p>
                                                  </div>

                                                  <label className="col-md-2"><strong> ช่องทางรับเงินเดือน </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong>{customer.salary_channel === null || customer.salary_channel === '' ? '-' : customer.salary_channel }</strong>
                                                      </p>
                                                  </div>
                                              </div>

                                              <div className=" form-group row" style={styles.bottomLine}>
                                                  <label className="col-md-2"><strong> ประเภทใบรับเงินเดือน </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong>{customer.salary_receipt_type === null || customer.salary_receipt_type === '' ? '-' : customer.salary_receipt_type }</strong>
                                                      </p>
                                                  </div>
                                              </div>

                                          </div>

                                          <div className="col-lg-12">
                                             <strong>ข้อมูลผู้ค้ำประกัน</strong>
                                          </div>

                                          <div className="col-lg-12" style={{marginTop: 20}}>

                                              <div className=" form-group row" style={styles.bottomLine}>
                                                  <label className="col-md-2"><strong> ชื่อผู้ค้ำ </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong>
                                                              {customer.guarantor_name === null || customer.guarantor_name === '' ? '-' : customer.guarantor_name  }
                                                          </strong></p>
                                                  </div>

                                                  <label className="col-md-2"><strong> เบอร์โทรผู้ค้ำ </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong> {customer.guarantor_mobile === null || customer.guarantor_mobile === '' ? '-' : customer.guarantor_mobile} </strong></p>
                                                  </div>

                                                  <label className="col-md-2"><strong> ที่อยู่ผู้ค้ำ </strong></label>
                                                  <div className="col-md-2">
                                                      <p className="text-justify text-primary">
                                                          <strong>{customer.guarantor_address === null || customer.guarantor_address === '' ? '-' : customer.guarantor_address}</strong></p>
                                                  </div>
                                              </div>

                                          </div>

                                      </div>
                                  </div>

                                  <Modal isOpen={this.state.primary} toggle={this.togglePrimary}
                                         className={'modal-lg ' + this.props.className}
                                         style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                                  >
                                      <ModalBody>
                                          <CustomerForm
                                            onToggle={this.togglePrimary}
                                            username={this.state.user.user}
                                          />
                                      </ModalBody>
                                  </Modal>

                                  <Modal isOpen={this.state.primarycustomeredit} toggle={this.togglePrimaryCustomerEdit}
                                         className={'modal-lg ' + this.props.className}
                                         style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
                                      <ModalBody>
                                          <CustomerEdit
                                            onToggle={this.togglePrimaryCustomerEdit}
                                            customer={this.state.selectCustomer}
                                            onCustomerSubmit={
                                                {
                                                    add: this.addCustomer.bind(this),
                                                    change: this.changeCustomer.bind(this)
                                                }
                                            }
                                            onReloadCustomer={() => {
                                                this.loadCustomer(this.state.customer_id)
                                                this.togglePrimaryCustomerEdit();
                                            }}
                                            actionType={this.state.action}
                                            customers={this.state.customer}

                                          />
                                      </ModalBody>
                                  </Modal>


                                  <Modal isOpen={this.state.danger} toggle={this.toggleDanger.bind(this)}
                                         className={'modal-danger'}>
                                      <ModalHeader toggle={this.toggleDanger.bind(this)}>ลบข้อมูล</ModalHeader>
                                      <ModalBody>
                                          <strong> คุณต้องการลบข้อมูล {this.state.customer.customer_name} ใช่หรือไม่ ?? </strong>
                                          <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
                                      </ModalBody>
                                      <ModalFooter>
                                          <Button color="primary"
                                                  onClick={this.toggleDangerDelete.bind(this, this.state.customer_id)}>ตกลง</Button>{' '}
                                          <Button color="secondary" onClick={this.toggleDanger.bind(this)}>ยกเลิก</Button>

                                      </ModalFooter>
                                  </Modal>

                              </div>
                          </div>
                      </div>
                  </TabPane>

                  <TabPane tabId="2" >

                      {
                          this.state.toggle_profile_form && <Modal isOpen={this.state.toggle_profile_form}
                                                                   toggle={this.toggleProfileForm}
                                                                   className={'modal-lg ' + this.props.className}
                                                                   style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                          >
                              <ModalBody>
                                  <ProfileForm
                                    customer_id = {this.state.customer_id }
                                    profile_selected={this.state.profile_selected}
                                    form_status={this.state.profile_form_status}
                                    onToggle={this.toggleProfileForm}
                                    onSubmit={this.onSubmitProfile}
                                  />
                              </ModalBody>
                          </Modal>
                      }


                      {
                          this.state.customer_id === 0 ? null :
                            <ProfileDetail
                              customer={customer}
                              customer_id = {this.state.customer_id }
                              grouppermissions = {this.state.grouppermissions}
                              isProfileReload = {this.state.isProfileReload}

                              setProfileReloadComplete = {() => {
                                  this.setState({
                                      isProfileReload: false
                                  })
                              }}

                              openCreateForm={() => {
                                  this.setState({
                                      profile_form_status: 'add',
                                      toggle_profile_form: !this.state.toggle_profile_form
                                  });
                              }}

                              onProfileEdit={(profile) => {
                                  this.setState({
                                      profile_form_status: 'edit',
                                      toggle_profile_form: !this.state.toggle_profile_form,
                                      profile_selected: profile
                                  });
                              }}
                            />
                      }

                  </TabPane>

                  <TabPane tabId="3">
                      <div className="row">
                          <div className="col-lg-12">
                              <div className="card">
                                  <div className="card-header card-customer">
                                      <strong className="text-title">
                                          เอกสารลูกค้า : {customer.customer_name}{' '}{customer.customer_lastname}
                                      </strong>

                                      {
                                          checkGroupPermission(403, this.state.grouppermissions).created ? (
                                            <button className="btn btn-head-bar float-right"  onClick={this.toggleCustomerDocAdd} >
                                                <i className="icon-plus"/>&nbsp; เพิ่มเอกสาร
                                            </button>
                                          ) : null
                                      }
                                  </div>

                                  <div className="card-block">
                                      <CustomerDocList
                                        customer_id = {this.state.customer_id}
                                        customer_docs={this.state.customer_docs}
                                        onDelete={this.deleteCustomerDoc}
                                        onEdit={this.editCustomerDoc}
                                        grouppermissions={this.state.grouppermissions}
                                      />
                                  </div>

                                  <Modal isOpen={this.state.toggle_customer_add} toggle={this.toggleCustomerDocAdd}
                                         className={'modal-lg ' + this.props.className}
                                         style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
                                      <ModalBody>
                                          <CustomerDocAdd
                                            onToggle={this.toggleCustomerDocAdd}
                                            onCustomerDocSubmit={
                                                {
                                                    add: this.addCustomerDoc.bind(this),
                                                    cancel: this.toggleCustomerDocAdd,
                                                    addNoFile: this.addCustomerDocNoFile.bind(this),
                                                }
                                            }
                                            form_status = {this.state.customer_doc_form_status}
                                            customer_doc_selected={this.state.customer_doc_selected}
                                          />
                                      </ModalBody>
                                  </Modal>

                              </div>
                          </div>
                      </div>
                  </TabPane>


                  <TabPane tabId="4">
                      {
                          this.state.customer_id !== 0 && <CustomerInstallmentHistory
                            customer_id={this.state.customer_id}
                            customer={this.state.selectCustomer}
                            username={this.state.user.user}
                            grouppermissions={this.state.grouppermissions}
                          />
                      }

                  </TabPane>

                  <TabPane tabId="5">
                      {
                          this.state.customer_id !== 0 && <CustomerCredit
                            customer_id={this.state.customer_id}
                            customer={this.state.selectCustomer}
                            username={this.state.user.user}
                            isDateFill={this.state.is_date_fill}
                            grouppermissions={this.state.grouppermissions}
                          />
                      }

                  </TabPane>

              </TabContent>

          </div>
        );
    }
}

const styles = {
    inputSearch: {
        marginLeft: 20,
        width: 300,
        display: 'inline'
    },

    buttonHeight: {
        height: 36,
        paddingTop:0,
        paddingBottom:0,
        display: 'inline',
        marginTop: '0rem',
        borderRadius:5,
    },
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    },
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CustomerDetail);
