import React,{Component} from  'react'
import Autosuggest from 'react-autosuggest';
import NumberFormat from 'react-number-format';
import { format } from "date-fns";
// import DatePicker from 'react-datepicker'
// import moment from 'moment'
// import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios'
import { APIURL, PORT } from '../../../config/config'
import {connect} from 'react-redux' ;
import { AlertError, AlertWarning } from '../../Alert/Alert'
import BDatePicker from '../../BDatePicker'
import SelectSearch from "react-select-search";

const suggestionHighlightedDivStyle = { backgroundColor: '#5bc0de' };
const suggestionDivStyle = { padding: '10px'};

const car_repair_name = [
  { id: 'ทำสี', name: 'ทำสี' },
  { id: 'ทำเบาะ', name: 'ทำเบาะ' },
  { id: 'ระบบไฟ', name: 'ระบบไฟ' },
  { id: 'ระบบแอร์', name: 'ระบบแอร์' },
  { id: 'ถอดแก๊ส', name: 'ถอดแก๊ส' },
  { id: 'อุปกรณ์ตกแต่ง', name: 'อุปกรณ์ตกแต่ง' },
  { id: 'อุปกรณ์ตกแต่ง', name: 'อุปกรณ์ตกแต่ง' },
  { id: 'ฟิล์ม', name: 'ฟิล์ม' },
  { id: 'ช่วงล่าง', name: 'ช่วงล่าง' },
  { id: 'ล้อแม๊กซ์', name: 'ล้อแม๊กซ์' },
  { id: 'ยาง', name: 'ยาง' },
];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : car_repair_name.filter(lang =>
     lang.name.toLowerCase().slice(0, inputLength) === inputValue
  );
};

// When suggestion is clicked, Autosuggest needs to populate the input
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <span>
    {suggestion.name}
  </span>
);

class CarRepairNewForm extends Component{
  constructor (props){
     super(props)
     this.state = {
       date: new Date().toISOString(),
       previousDate: null,
       minDate: null,
       maxDate: null,
       focused: false,
       invalid: false,
       value: '',
       suggestions: [],
       cost: 0,
       // dateCarSend: moment(),
       // dateCarComplete: moment(),
       dateCarSend: new Date(),
       dateCarComplete: new Date(),
       center_list: [],
       user: {},
       car_sell_id: 0,
       w_car_repair_name: false,
       w_car_repair_cost: false,
       w_car_repair_file: false,
      // w_car_repair_note: false,
      // car_repair_name : null
       car: null,
      // car_license_search: '',
      // car_list: [],
       car_repair_send: null,
       car_repair_complete: null,
       pickup_date: null,
       is_pickup: 0,
       repair_type_id: 0,
       repair_types: [],
       is_after_sell : Number(this.props.is_after_sell),
       car_repair_vat: 0,
       car_repair_cost_text: 'ค่าใช้จ่าย',
       select_center_id: 0
     }

     this.handleDateCarSend = this.handleDateCarSend.bind(this);
     this.handleDateCarComplete = this.handleDateCarComplete.bind(this);
     this.handleSubmit = this.handleSubmit.bind(this);
     this.loadRepairTypeData = this.loadRepairTypeData.bind(this);
     this.loadCenterData = this.loadCenterData.bind(this);
     // this.searchCar = this.searchCar.bind(this);
     // this.onCarSelect = this.onCarSelect.bind(this);
     // this.loadCarById = this.loadCarById.bind(this);
  }

  handleDateCarSend(date) {
    this.setState({
      dateCarSend: date
    });
  }

  handleDateCarComplete(date) {
    this.setState({
      dateCarComplete: date
    });
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  //load data to select center
  loadCenterData(){
    axios.get(APIURL + '/center')
      .then(res => {
           // todx: filter แจ้งซ่อม
           let user_type = this.state.user.type
           let center_list = res.data

           if (PORT === 9021 && user_type === 'sale') {
             center_list = center_list.filter(center => center.center_name.includes('แจ้งซ่อม'))
           }
           this.setState({center_list: center_list })
      }).catch(error => {
       // alert("error"+error)
      this.setState({center_list: []})
    })
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user),
      car: this.props.car
    });
  }

  componentDidMount () {
    this.loadCenterData()
    this.loadRepairTypeData()
    if(this.props.is_search_car === false) {
      this.loadCarSellByCarId()
    }

    let currDate = format(new Date(), 'yyyy-MM-dd')
    this.setState({
      car_repair_send: currDate,
      car_repair_complete: currDate
    })

  }

  loadRepairTypeData(){
    axios.get(APIURL + '/repair_type')
      .then(res => {
        this.setState({repair_types: res.data })
      }).catch(error => {
      // alert("error"+error)
      this.setState({repair_types: []})
    })
  }

  loadCarSellByCarId() {

    axios.get(APIURL + '/car_sell/car/'+this.state.car.car_id)
      .then(res => {
        if(res.data.length > 0) {
          this.setState({
            car_sell_id: res.data[0].car_sell_id
          })
        }else{
          this.setState({car_sell_id: 0 })
        }
      }).catch(error => {
         // alert("error"+error)
         this.setState({car_sell_id: 0})
    })
  }

  handleSubmit (event) {
     event.preventDefault();
    let car = this.state.car
    let is_after_sell = this.state.is_after_sell
    // console.log('is_after_sell=', is_after_sell, typeof is_after_sell)
    // return;
    // console.log(typeof car)
    if (this.props.is_search_car === true && car === null) {
      AlertError('กรุณาค้นหาและเลือกรถ ที่ต้องการเพิ่มรายการซ่อม')
      return
    }
    // return
    //let center_id = event.target.center_id.value
    let center_id = this.state.select_center_id
    let repair_type_id = event.target.repair_type_id.value
    let car_repair_name = event.target.car_repair_name.value
    // let car_repair_cost = event.target.car_repair_cost.value
    // let car_repair_note = event.target.car_repair_note.value

    let is_pickup = this.state.is_pickup
    let pickup_date = is_pickup === 0 ? null : this.state.pickup_date
    let car_repair_send = this.state.car_repair_send
    let car_repair_complete = this.state.car_repair_complete
    let car_repair_vat = this.state.car_repair_vat

    let car_repair_data = {
      car_repair_send: this.state.car_repair_send,
      car_repair_complete: this.state.car_repair_complete,
      is_pickup: is_pickup,
      pickup_date: pickup_date,
      car_repair_cost: this.state.cost,
      car_repair_vat: car_repair_vat,
      repair_type_id: repair_type_id,
      is_after_sell: is_after_sell,
      center_id: center_id
    }

    if(center_id===''){
      AlertError('ไม่พบข้อมูลศูนย์ซ่อม เพิ่มข้อมูลที่เมนู "ข้อมูลศูนย์ซ่อมบำรุงรถ"')
      this.refs.center_id.focus()
      return
    }

    if(car_repair_name===''){
       this.setState({
         w_car_repair_name: true
       });
       return;
    }

    if(car_repair_send === null) {
      AlertError('กรุณากรอกวันส่งซ่อม');
      return;
    }

    if(car_repair_complete === null) {
      AlertError('กรุณากรอกวันแล้วเสร็จ');
      return;
    }

    if(is_pickup === 1 && pickup_date === null) {
      AlertError('กรุณาระบุวันที่รับรถ');
      return
    }
    // return

    if(event.target.car_repair_file.files.length === 0){
      this.props.onCarRepairSubmit.addNoFile(event, car_repair_data)
    }else{
      let filename = event.target.car_repair_file.files[0].name ;
      if(!(/\.(gif|jpg|jpeg|tiff|png)$/i).test(filename)){
        this.setState({
          w_car_repair_file: true
        });
        return;
      }

      this.props.onCarRepairSubmit.add(event, car_repair_data)
    }
  }

  render(){

    let is_after_sell = Number(this.state.is_after_sell)

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'ชื่อรายการซ่อม',
      value: this.state.value ,
      onChange: this.onChange ,
      type: 'search',
    };

    /*let center_list = this.state.center_list.map((center,index)=>{
      return( <option key={index} value={center.center_id}>{center.center_name}</option> )
    })*/

    let repair_type_list = this.state.repair_types.map((repair_type, index) => {
      return( <option key={index} value={repair_type.repair_type_id}>{repair_type.repair_type_name}</option> )
    })

    return (
      <div className="card card-accent-primary ">
        <div className="card-header">
          <strong className="text-title">เพิ่มข้อมูลการซ่อม </strong>
        </div>

        {
          this.props.car === null ? null
            :
            <div className=" mt-2 mr-4 ml-4">
              <div className="row col-12">
                <strong>
                {this.state.car.car_name} &nbsp;|&nbsp;
                เดิม : {this.state.car.car_license_plate_old} {this.state.car.province_name_old}
                /
                 ใหม่ : {this.state.car.car_license_plate_new} {this.state.car.province_name_new}
                  &nbsp;|&nbsp;
                  สถานะ: {this.state.car.car_status_name}
                </strong>
              </div>
            </div>
        }

        <form
          action="" method="post"
          onSubmit={ this.handleSubmit }
          encType="multipart/form-data"
        >
        <div className="card-block">

          <div className="row">
            <div className="form-group col-md-6">
                <label htmlFor="center_id">ศูนย์ซ่อมบำรุง</label>

              <SelectSearch
                  ref="center_id"
                  options={this.state.center_list}
                  value={this.state.select_center_id}
                  search={true}
                  name="center_id"
                  placeholder="เลือกศูนย์ซ่อมบำรุง"
                  onChange={(val) => {
                    let center_list = this.state.center_list
                    let center_filter = center_list.filter(item => item.center_id === val)
                    let center_name = center_filter[0].center_name

                    if(center_filter.length > 0) {
                      center_name = center_filter[0].center_name
                    }
                      this.setState({
                      select_center_id: val,
                    })
                  }}
              />
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="center_name">ชื่อรายการซ่อม</label>
              <input type="text"
                     className="form-control hidden"
                     value={this.state.value}
                     name="car_repair_name"
                     id="car_repair_name"
                     placeholder="hidden"
                     onChange={()=>{}}
              />
              <Autosuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                theme={{
                  input: 'form-control',
                  suggestion: suggestionDivStyle,
                  suggestionsList: 'list-unstyled ',
                  suggestionHighlighted: suggestionHighlightedDivStyle
                }}

              />
              {this.state.w_car_repair_name && this.state.value ==='' ? <span  className="help-block text-danger">กรุณากรอกชื่อรายการซ่อม</span>: null }

            </div>
          </div>

          <div className={PORT === 9043 ? "hidden" : "row"} >
            <div className="form-group col-md-4">
              <label htmlFor="center_name"> {this.state.car_repair_cost_text} </label>
              <input
                     className="form-control hidden"
                     id="car_repair_cost"
                     name="car_repair_cost"
                     placeholder="hidden"
                     value={this.state.cost}
                     onChange={()=>{}}
              />
              <NumberFormat
                className="form-control"
                thousandSeparator={true}
                prefix={'฿'}
                placeholder="ค่าใช้จ่าย"
                min={0}
                max={1000000000}
                step={1000}
                size={10}
                allowNegative={false}
                onValueChange = {(values) => {
                  const {value} = values;
                  this.setState({cost: value})
                }}
              />
              {this.state.w_car_repair_cost && this.state.cost === 0 ? <span  className="help-block text-danger">กรุณากรอกค่าใช้จ่าย</span>: null }
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="center_name"> VAT </label>
              <input
                  className="form-control hidden"
                  id="car_repair_vat"
                  name="car_repair_vat"
                  placeholder="hidden"
                  value={this.state.car_repair_vat}
                  onChange={()=>{}}
              />
              <NumberFormat
                  className="form-control"
                  thousandSeparator={true}
                  prefix={'฿'}
                  placeholder="VAT"
                  min={0}
                  max={1000000000}
                  step={1000}
                  size={10}
                  allowNegative={false}
                  value={this.state.car_repair_vat}
                  onValueChange = {(values) => {
                    const {value} = values;
                    if (value>0) {
                      this.setState({
                        car_repair_cost_text: 'ค่าใช้จ่าย(ไม่รวม VAT)',
                        car_repair_vat: value
                      })
                    }  else {
                      this.setState({
                        car_repair_cost_text: 'ค่าใช้จ่าย',
                        car_repair_vat: value
                      })
                    }

                  }}
              />

            </div>

            <div className="form-group col-md-4">
              <label htmlFor="center_id">ประเภทการซ่อม</label>
              <select
                className="form-control"
                id="repair_type_id"
                name="repair_type_id">
                {repair_type_list}
              </select>
            </div>

          </div>


          <div className="row">
            <div className="form-group col-md-6">

              {/* <label htmlFor="car_repair_send">วันที่ส่งซ่อม </label><br/>
                <DatePicker
                  selected={this.state.dateCarSend}
                  onChange={this.handleDateCarSend}
                  dateFormat="yyyy-MM-dd"
                  type="text"
                  className="form-control"
                  id="car_repair_send"
                  name="car_repair_send"
                  placeholder="วันที่ส่งซ่อม"
                />
                */}

              <BDatePicker
                isDateFill={this.props.is_date_fill}
                name={'car_repair_send'}
                value={this.state.car_repair_send}
                label={'วันที่ส่งซ่อม '}
                onUpdateDate={(buddhist_date, christ_date) => {
                  this.setState({
                    car_repair_send: christ_date
                  })
                }}
              />

            </div>
            <div className="form-group col-md-6">
               {/* <label htmlFor="car_repair_complete">วันที่แล้วเสร็จ </label><br/>
                <DatePicker
                  selected={this.state.dateCarComplete}
                  onChange={this.handleDateCarComplete}
                  dateFormat="yyyy-MM-dd"
                  type="text"
                  className="form-control"
                  id="car_repair_complete"
                  name="car_repair_complete"
                  placeholder="วันที่แล้วเสร็จ"
                />*/}

              <BDatePicker
                isDateFill={this.props.is_date_fill}
                name={'car_repair_complete'}
                value={this.state.car_repair_complete}
                label={'วันที่แล้วเสร็จ '}
                onUpdateDate={(buddhist_date, christ_date) => {
                  this.setState({
                    car_repair_complete: christ_date
                  })
                }}
              />

              </div>
          </div>

          <div className="row">
            <div className="col-6 form-inline">
              <input
                type="checkbox"
                className="form-control"
                name="is_pickup"
                value={this.state.is_pickup}
                checked={this.state.is_pickup === 1}
                onChange={(e) => {
                  let val = Number(e.target.value) === 0 ? 1 : 0
                  // console.log(val, typeof val)
                  this.setState({
                    is_pickup: val
                  })
                }}

              /> &nbsp;&nbsp;  รับรถแล้ว
            </div>
            <div className="form-group col-md-6">
              {/*<label htmlFor="pickup_date">วันที่รับรถ </label><br/>
              <DatePicker

                onChange={(e) => {
                  console.log(e, typeof e)
                }}
                dateFormat="yyyy-MM-dd"
                type="text"
                className="form-control"
                name="pickup_date"
                placeholder="วันที่รับรถ"
              />*/}

              {
                this.state.is_pickup === 1 && <BDatePicker
                  isDateFill={this.props.is_date_fill}
                  name={'pickup_date'}
                  value={this.state.pickup_date}
                  label={'วันที่รับรถ '}
                  onUpdateDate={(buddhist_date, christ_date) => {
                    this.setState({
                      pickup_date: christ_date
                    })
                  }}
                />
              }


            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="car_repair_file">เอกสารแนบ </label>
              <input type="file"
                     id="car_repair_file"
                     name="car_repair_file"
                     accept="image/*"
                     className="form-control"
                    onChange={(e)=>{
                       const { target } = e
                        if(target.value.length > 0){
                          this.setState({
                            w_car_repair_file: false
                          });
                        } else {
                          //target.reset();
                          this.setState({
                            w_car_repair_file: true
                          });
                        }

                    }}
              />
              { this.state.w_car_repair_file ? <span  className="help-block text-danger">กรุณาเลือกเอกสารแนบ</span>: null }

            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="car_repair_note">หมายเหตุ</label>
              <input type="text"
                     className="form-control"
                     //value={this.state.car_repair_note}
                     name="car_repair_note"
                     id="car_repair_note"
                     placeholder="ข้อมูลการซ่อมเพิ่มเติม"/>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-3 bg-success"
                 style={{minWidth: 40, paddingTop: 6}}
            >
              <label htmlFor="car_repair_type">สถานะ:  {this.state.car_sell_id === 0 ? 'ซ่อมปรับแต่ง' : 'ซ่อมเคลม' }</label>

              <input type="text"
                     className="form-control hidden"
                     value={this.state.car_sell_id === 0 ? 'ซ่อมปรับแต่ง' : 'ซ่อมเคลม' }
                     id="car_repair_type"
                     name="car_repair_type"
                     placeholder="hidden"
                     onChange={()=>{}}
              />

            </div>

           {/* {
              this.props.is_after_sell !== null && <div className="form-group col-md-2">
                <label htmlFor="is_after_sell">*** {is_after_sell === 0 ? 'ก่อนขาย' : 'หลังขาย' }</label>
                <input type="text"
                       className="form-control hidden"
                       value={is_after_sell === 0 ? 0 : 1 }
                       id="is_after_sell"
                       name="is_after_sell"
                       placeholder="hidden"
                       onChange={()=>{}}
                />
              </div>
            }*/}
            <div className="form-group form-inline col-md-6 bg-danger">
              <div style={{minWidth: 40, paddingTop: 0}}>
                บันทึกเป็น : &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div style={{minWidth: 40, padding: 0}}>
                <input type="radio"
                       name="is_after_sell"
                       value="0"
                       className="btn btn-primary"
                       checked={is_after_sell === 0}
                       onChange={(e) => {
                         this.setState({
                           is_after_sell: Number(e.target.value)
                         })
                       }}
                /> &nbsp;<span style={{marginTop: 6}}>ซ่อมก่อนขาย</span>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div style={{minWidth: 40, padding: 0}}>
                <input type="radio"
                       name="is_after_sell"
                       value="1"
                       className="btn btn-primary"
                       checked={is_after_sell === 1}
                       onChange={(e) => {
                         this.setState({
                           is_after_sell: Number(e.target.value)
                         })
                       }}
                /> &nbsp;<span style={{marginTop: 6}}>ซ่อมหลังขาย</span>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            </div>


          </div>

          {
            this.state.car === null ? null :
              <input type="hidden" id="car_id" name="car_id" value={this.state.car.car_id}/>
          }

        </div>
        <div className="card-footer text-right">
          <button type="reset"
                   onClick={this.props.onCarRepairSubmit.cancel}
                   className="btn btn-sm btn-danger mr-2">
              <i className="fa fa-refresh"/> ยกเลิก
          </button>
          <button type="submit"
                  className="btn btn-sm btn-primary">
               <i className="fa fa-save"/> บันทึก
          </button>

        </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarRepairNewForm);
