import React, { Component } from 'react';
import Loading from '../Loading';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
import { APIURL } from '../../config/config';
import { save } from 'save-file';
import { CarUtil } from '../../utils/carUtil';
import { connect } from 'react-redux'
import { NumberUtil } from '../../utils/number-util'
import { checkGroupPermission, getGroupPermission } from '../../services/grouppermission';
import {AlertError, AlertWarning} from '../Alert/Alert';
import { format } from 'date-fns';
import { th } from 'date-fns/locale';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import {ExcelUtil} from "../../utils/excelUtil";
import {getSumCostBeforeSell} from "../../services/costProfitService";

// import { formatWithOptions } from 'date-fns/fp';
// const dateToString = formatWithOptions({locale: th}, 'dd MMMM yyyy');

class CarInCostSummaryReport extends Component{
    constructor (props) {
        super(props);
        this.state = {
            menuID: [107, 118],
            grouppermissions: [],
            user: {},
            isLoading: false,
            date_start: new Date(),
            date_end: new Date(),
            lists: [],
            data_by: 'all', // all, byDate
            branches: [],
            branch_id: '',
            orders: [
                {order_id: 'car_in_asc', order_name: 'วันที่ซื้อรถเข้า : เก่าไปใหม่'},
                {order_id: 'car_in_desc', order_name: 'วันที่ซื้อรถเข้า : ใหม่ไปเก่า'},
                {order_id: 'car_license_asc', order_name: 'ทะเบียนรถใหม่ : ก-ฮ'},
                {order_id: 'car_license_desc', order_name: 'ทะเบียนรถใหม่ : ฮ-ก'},
            ],
            order_by: 'car_in_desc',
            person_vat_id: '',
            expenses: [],
            is_convert_date: 0
        }
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleDateStart = this.handleDateStart.bind(this)
        this.handleDateEnd = this.handleDateEnd.bind(this)
        this.loadCarInData = this.loadCarInData.bind(this)

        this.exportCarInSummaryReport = this.exportCarInSummaryReport.bind(this)
        this.loadBranchData = this.loadBranchData.bind(this)
        this.loadGroupPermission = this.loadGroupPermission.bind(this)
    }

    loadGroupPermission() {
        let type = this.props.type
        let menuID = this.state.menuID
        if (type === undefined) {

            return
        }

        getGroupPermission(type, menuID).then(res => {
            if(res.data.result === 'success') {
                this.setState({
                    grouppermissions: res.data.grouppermissions
                })
            }
        }).catch(e => {
            console.log('error: ', e)
            this.setState({
                grouppermissions: []
            })
        })
    }

    componentDidMount () {
        this.loadBranchData()
        this.loadGroupPermission()
        this.loadCarInData()
    }

    // defaultChecked={this.state.data_by === 'byDate'}
    // defaultChecked={this.state.data_by === 'all'}

    handleDateStart(date){
        this.setState({
            date_start: date
        })
    }

    handleDateEnd(date){
        this.setState({
            date_end: date
        })
    }

    handleOnChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loadCarInData() {
        if(this.state.date_start > this.state.date_end){
            AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }

        // let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
        // let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

        let start_date = format(new Date(this.state.date_start), 'yyyy-MM-dd')
        let end_date = format(new Date(this.state.date_end), 'yyyy-MM-dd')

        this.setState({
            isLoading: true
        })

        const data_by = this.state.data_by
        const branch_id = this.state.branch_id
        const order_by = this.state.order_by
        const person_vat_id = this.state.person_vat_id

        const data = {
            start_date: start_date,
            end_date: end_date,
            data_by: data_by,
            branch_id: branch_id,
            order_by: order_by,
            person_vat_id: person_vat_id
        }

        // axios.get(APIURL + '/report/carin/'+start_date+'/'+end_date)
        axios.post(APIURL + '/report_cost_summary/carin', data)
            .then(res => {
                let data = res.data
                let cars = data.cars
                let expenses = data.expenses

                this.setState({
                    isLoading: false,
                    lists: cars,
                    expenses: expenses
                })

            }).catch(error => {
            console.log('error:', error)
        })

    }

    exportCarInSummaryReport(tableExcels) {
        let cars = this.state.lists
        if(cars.length === 0) {
            AlertError('ไม่พบข้อมูลรถเข้า')
            return
        }
        
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        let curr = format(new Date(), 'dd/MM/yyyy HH:mm:ss')
        let branches = this.state.branches
        let branch_id = this.state.branch_id
        let branch_name = ''
        if(branch_id !== ''){
            let branch =  branches.filter(branch=>branch.branch_id === Number(branch_id))[0]
            branch_name = branch.branch_name
        }

        let title1 = 'รายงานต้นทุนอย่างย่อ' + ' ' + ' ' + (branch_name === '' ? '( ทุกสาขา )' : 'สาขา : ' +  branch_name)

        const title = [
            {
                A: title1,
            },
            {
                A: 'พิมพ์เมื่อ : ' + curr,
            },
            {
                A: 'ลำดับ',
                B: 'ทะเบียนรถ',
                C: 'ยี่ห้อรถ',
                D: 'วันที่ซื้อรถเข้า',
                E: 'ราคาซื้อเข้า',
                F: 'VAT ซื้อ',
                G: 'ราคาซื้อรวม VAT',
                H: 'ต้นทุน+คชจ. ไม่รวม VAT',
                I: 'คชจ.(ไม่รวม VAT)',
                J: 'รวม'
            },
        ]


        const reportData = [...title, ...tableExcels]

        this.setState({isLoading: true})

        // หาว่าแถวไหนไม่มี vat F: VAT ซื้อ,
        let arrRowNoVat= tableExcels.map(row => {
            if (Number(row.E) === Number(row.G)){
                return row
            }
        })

        setTimeout(() => {
            const ws = XLSX.utils.json_to_sheet(reportData, { origin: 'A1', skipHeader: true });

            let wscols = [
                {wch:8},
                {wch:35},
                {wch:35},
                {wch:20},
                {wch:16},
                {wch:16},
                {wch:16},
                {wch:16},
                {wch:16},
                {wch:16},
            ];

            let last_row = cars.length + 2
            let last_col = 9

            let wsrows = [];
            for(let  i=1 ; i <=last_row + 1; i++){
                if (i===3){
                    wsrows.push({hpt: 40})
                }
                wsrows.push({hpt: 20})
            }

            ws['!cols'] = wscols;
            ws['!rows'] = wsrows

            let rangeTitle = { s: {r:2, c:0}, e: {r:2, c: last_col} };
            ExcelUtil.setCellBoldWithBorder(ws, rangeTitle);

            let rangeOrder = { s: {r:3, c:0}, e: {r:last_row, c:0} };
            ExcelUtil.setCellCenterNormalWithBorder(ws, rangeOrder);

            last_row = last_row +1

            let rangeNumber = { s: {r:3, c:1}, e: {r:last_row, c: last_col} };
            ExcelUtil.setCellNumberFormatWIthBorder(ws, rangeNumber);

            let rangeSum = { s: {r:last_row, c:0}, e: {r:last_row, c: last_col} };
            ExcelUtil.setCellTextRightBoldWithBorder(ws, rangeSum);

            for(let i =0 ; i < arrRowNoVat.length ; i++) {
                let rowNoVatBuy = arrRowNoVat[i]

                if( rowNoVatBuy === undefined) continue;

                let rowOrder = rowNoVatBuy.A
                let row = 2+rowOrder
                let rangeBlue = { s: {r: row, c: 1}, e: {r:row, c:last_col} };
                ExcelUtil.setCellNumberFormatBlueWithBorder(ws, rangeBlue);
            }

            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});

            let currFileName = format(new Date(), 'yyyy-MM-dd')
            let fileName = 'report_cost_summary'+'_'+currFileName
            FileSaver.saveAs(data, fileName + fileExtension);

            this.setState({isLoading: false})

        }, 1200)

    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branches: res.data})
            }).catch(error => {
            this.setState({branches: []})
        })
    }

    render () {
        const {
            lists,
            expenses,
            is_convert_date
        } = this.state


        const viewed = true

        const emptyBody = <tr><td colSpan={10} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

        let sumCarCost = 0
        let sumCarSalePrice = 0
        let sumVatBuy = 0
        let sumCostOther = 0
        let sumCostRepair = 0
        let sumCostWithVat = 0
        let sumCostWithExpenseNoVat = 0
        let sumTotal = 0
        let excelTables = []

        const listRows = lists.map((car, index) => {

            let car_id = car.car_id
            let car_date_in = format(new Date(car.car_date_in), 'dd MMMM yyyy',  { locale: th });

            if (is_convert_date === 1) {
                car_date_in = format(new Date(car.car_date_in), 'yyyy-MM-dd');
            }

            let car_cost = parseFloat(String(car.car_cost)).toFixed(2)
            // let car_sale_price = parseFloat(String(car.car_sale_price)).toFixed(2)
            let vat_buy = parseFloat(String(car.vat_buy)).toFixed(2)
            let car_cost_repair = car.car_cost_repair === null ? 0 : parseFloat(String(car.car_cost_repair)).toFixed(2)

            let cost_operation = car.cost_operation; // ค่าดำเนินการ
            let cost_service = car.cost_service; // ค่าบริการ
            let cost_transport = car.cost_transport; // ค่าขนย้าย
            let cost_porobor_lan = car.cost_porobor_lan; // ค่า พรบ. จากลาน
            let cost_bangkhab_phasi_lan = car.cost_bangkhab_phasi_lan; // ค่าบังคับต่อภาษี จากลาน
            let cost_bangkhab_xon_lan = car.cost_bangkhab_xon_lan;  // ค่าบังคับโอนเข้าเต็นท์ จากลาน
            let cost_check_ton = car.cost_check_ton;  // ค่าเช็คต้น

            let car_license_plate_new = car.car_license_plate_new.trim()
            let car_license_plate_old = car.car_license_plate_old.trim()

            let car_license_old_new = car.car_license_plate_old +' ' + car.province_name_old+ ' / '+ car.car_license_plate_new+' '+ car.province_name_new
            let car_license = car_license_plate_new === car_license_plate_old ? car.car_license_plate_new+' '+car.province_name_new : car_license_old_new

            // TODOx: sum expenses
            // include_vat = 1 คือ expense_amount บวก vat เข้าไปแล้ว
            // car_cost_expenses_other ค่าใช้จ่ายอื่นๆ ของรถ บันทึกผ่าน TAB ค่าใช้จ่ายอื่นๆ ของรถ
            // ยอด Expense.expense_amount ยังไม่รวม vat ถ้ามี vat ให้บวกเข้าไปเพิ่ม
            // ยอด car_cost_expenses_other รวม vat แล้ว

            let expense_by_car = expenses.filter(expense => expense.car_id===car_id)
            let car_cost_expenses_other_no_vat = expense_by_car.reduce((acc, expense)=>{
                if (expense.include_vat === 1) {
                    let expense_amount_no_vat = expense.expense_amount - expense.expense_amount_vat
                    return acc + expense_amount_no_vat
                }
                return acc + expense.expense_amount
            }, 0);


            /* let car_cost_other_sum = Number(car.car_cost_other)  + Number(car.car_cost_commissions) + Number(car.car_cost_act) + Number(car.car_cost_tax)
            // let car_cost_other_sum = 0
            car_cost_other_sum += Number(car_cost_expenses_other_no_vat);
            car_cost_other_sum += Number(cost_operation);
            car_cost_other_sum += Number(cost_service);
            car_cost_other_sum += Number(cost_transport);
            car_cost_other_sum += Number(cost_porobor_lan);
            car_cost_other_sum += Number(cost_bangkhab_phasi_lan);
            car_cost_other_sum += Number(cost_bangkhab_xon_lan);
            car_cost_other_sum += Number(cost_check_ton);
            car_cost_other_sum += Number(car_cost_repair);
            car_cost_other_sum = Number(car_cost_other_sum.toFixed(2)) */

            let car_cost_other_sum = getSumCostBeforeSell(car)

            sumCarCost += Number(car_cost)
            sumVatBuy += Number(vat_buy)
            sumCostOther += Number(car_cost_other_sum)

            let car_brand_name = car.car_brand_name
            let car_model_name = car.car_model_name
            let car_year_name = car.car_year_name
            let car_color_name = car.car_color_name

            let car_name = car_brand_name+' '+car_model_name+' / '+car_year_name+' / '+car_color_name
            let car_cost_with_vat = Number(car.car_cost)+Number(vat_buy)
            let car_cost_with_vat_fix = car_cost_with_vat.toFixed(2)

            // ต้นทุน + คชจ. ไม่รวม VAT
            let car_cost_with_expense_no_vat = Number(car.car_cost) + Number(car_cost_other_sum)
            let car_cost_with_expense_no_vat_fix = car_cost_with_expense_no_vat.toFixed(2)

            // รวม = ราคาซื้อรวม vat + คชจ ไม่รวม vat
            let total = Number(car_cost_with_vat_fix) + Number(car_cost_other_sum)

            sumCostWithVat += Number(car_cost_with_vat_fix)
            sumCostWithExpenseNoVat += Number(car_cost_with_expense_no_vat_fix)

            sumTotal += Number(total)


            let order = index+1
            let carCostWithComma = NumberUtil.addCommas(car.car_cost)
            let vatBuyWithComma = NumberUtil.addCommas(vat_buy)
            let carCostWithVatFixWithComma = NumberUtil.addCommas(car_cost_with_vat_fix)
            let carCostWithExpenseNoVatVixWithComma = NumberUtil.addCommas(car_cost_with_expense_no_vat_fix)
            let carCostOtherSumWithComma = NumberUtil.addCommas(car_cost_other_sum)
            let totalWithComma = NumberUtil.addCommas(total)

            // excell data ที่เป็นตัวเลขต้องเป็น number ถึงจะเอาไปคำนวณต่อใน excel ได้
            let excelTableRow = {
                A: order,
                B: car_license,
                C: car_name,
                D: car_date_in,
                E: Number(car.car_cost),
                F: Number(vat_buy),
                G: Number(car_cost_with_vat_fix),
                H: Number(car_cost_with_expense_no_vat_fix),
                I: Number(car_cost_other_sum),
                J: Number(total),
            }
            excelTables.push(excelTableRow)
            return (
                <tr key={index}>
                    <td className="text-center">{order}</td>
                    <td>{car_license}</td>
                    <th className="text-center text-nowrap"> {car_name} </th>
                    <td className="text-center text-nowrap">{car_date_in}</td>
                    <td className={ viewed ? "text-right" : "hidden"} > {carCostWithComma} </td>
                    <td className={ viewed ? "text-right" : "hidden"} > {vatBuyWithComma} </td>
                    <td className={ viewed ? "text-right" : "hidden"} > {carCostWithVatFixWithComma} </td>
                    <td className={ viewed ? "text-right" : "hidden"} > {carCostWithExpenseNoVatVixWithComma} </td>
                    <td className={ viewed ? "text-right" : "hidden"} > {carCostOtherSumWithComma} </td>
                    <td className={ viewed ? "text-right" : "hidden"} > {totalWithComma} </td>

                </tr>
            )
        });

        let sumCarCostWithComma = NumberUtil.addCommas(sumCarCost)
        let sumVatBuyWithComma = NumberUtil.addCommas(sumVatBuy)
        let sumCostWithVatWithComma = NumberUtil.addCommas(sumCostWithVat)
        let sumCostWithExpenseNoVatWithComma = NumberUtil.addCommas(sumCostWithExpenseNoVat)
        let sumCostOtherWithComma = NumberUtil.addCommas(sumCostOther)
        let sumTotalWithComma = NumberUtil.addCommas(sumTotal)

        let excelTableRow = {
            A: '',
            B: '',
            C: '',
            D: 'รวม',
            E: sumCarCost,
            F: sumVatBuy,
            G: sumCostWithVat,
            H: sumCostWithExpenseNoVat,
            I: sumCostOther,
            J: sumTotal,
        }
        excelTables.push(excelTableRow)

        let sumRow = <tr>
            <td colSpan={4}  className="text-right font-weight-bold" >รวม</td >
            <td className="font-weight-bold text-right" >{sumCarCostWithComma}</td>
            <td className="font-weight-bold text-right" >{sumVatBuyWithComma}</td>
            <td className="font-weight-bold text-right" >{sumCostWithVatWithComma}</td>
            <td className="font-weight-bold text-right" >{sumCostWithExpenseNoVatWithComma}</td>
            <td className="font-weight-bold text-right" >{sumCostOtherWithComma}</td>
            <td className="font-weight-bold text-right" >{sumTotalWithComma}</td>
        </tr>

        const tableRows = lists.length === 0 ? emptyBody : listRows

        let car_branch_list = this.state.branches.map((branch, index) => {
            return (
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        });

        let order_list = this.state.orders.map((order, index) => {
            return (
                <option key={index} value={order.order_id}>{order.order_name}</option>
            )
        });

        let width_box = 128

        // if (width <= 980){
        //   width_box = 112
        // }

        return (
            <div>
                <Loading isLoading={this.state.isLoading} />

                <div className="card-block" style={styles.overflowY}>
                    <div className="row">

                        <div className="card card-accent-primary">

                            <div className="card-header">
                                <div className="row col-12 mt-1">
                                    <div className="form-inline col-6 mt-2">
                                        <strong className="text-title">
                                            รายงานต้นทุนอย่างย่อ
                                        </strong>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className="form-group">
                                            <select className="form-control"
                                                    style={{width : 250}}
                                                    id="branch_id"
                                                    name="branch_id"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            branch_id: e.target.value
                                                        })
                                                    }}
                                            >
                                                <option value=''> ตามสาขา </option>
                                                {car_branch_list}
                                            </select>
                                        </div>
                                        <div className="col-1 ml-4 mr-1">
                                            <button type="button" style={{width: 80}} className="btn btn-sm btn-primary" onClick={() => this.loadCarInData()} > ตกลง </button>
                                        </div>

                                    </div>

                                    <div className="col-2"/>

                                    {
                                        checkGroupPermission(118, this.state.grouppermissions).printed ? (
                                    <div className="col-1">
                                        <button type="button"
                                                style={{width: 80, marginTop: 5 }}
                                                className="btn btn-sm btn-success"
                                                onClick={() => this.exportCarInSummaryReport(excelTables)}
                                        > Excel </button>
                                    </div>
                                        ) :null
                                    }

                                    {
                                        this.state.lists.length === 0 ? '': (
                                            <div className="col-3 text-center pt-2 pb-1 bg-primary">
                                                <h5>
                                                    พบ&nbsp; {this.state.lists.length} &nbsp;คัน
                                                </h5>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className="form-group row ml-2 mt-2">
                                    {/*<label className="col-md-3 form-control-label">&nbsp;</label>*/}
                                    <div className="col-md-9">
                                        <div className="checkbox">
                                            <label htmlFor="is_convert_date">
                                                <input
                                                    type="checkbox"
                                                    name="is_convert_date"
                                                    style={{marginTop: 4}}
                                                    value={this.state.is_convert_date}
                                                    checked={this.state.is_convert_date === 1}
                                                    onChange={(e) => {
                                                        let value = e.target.value === '0' ? 1 : 0
                                                        // console.log('value=', value)
                                                        this.setState({
                                                            is_convert_date: value
                                                        })
                                                    }}
                                                />
                                                &nbsp;ใช้วันที่ซื้อเข้าเป็นวันที่อย่างย่อ 2024-08-31
                                            </label>
                                        </div>
                                       {/* <div className="checkbox">
                                            <label htmlFor="checkbox2">
                                                <input type="checkbox" id="checkbox2" name="checkbox2" value="option2"/> Option 2
                                            </label>
                                        </div>
                                        <div className="checkbox">
                                            <label htmlFor="checkbox3">
                                                <input type="checkbox" id="checkbox3" name="checkbox3" value="option3"/> Option 3
                                            </label>
                                        </div>*/}
                                    </div>
                                </div>

                                {/*<div className="row">
                                    <div className="form-group col col-sm-12 col-md-12 col-lg-3 text-left mt-2  ">

                                        <span className="align-self-center text-center">  </span>
                                    </div>
                                </div>*/}
                            </div>

                            <div className="card-block">

                                {/*<div className="row mt-1 hidden">
                                    <div className="mt-1 ml-4">
                                        จาก
                                    </div>

                                    <div className="form-group  ml-2" style={{width: 130}} >
                                        {this.state.data_by === 'all'?
                                            <DatePicker
                                                selected={this.state.date_start}
                                                onChange={this.handleDateStart}
                                                dateFormat="yyyy-MM-dd"
                                                type="text"
                                                className="form-control"
                                                disabled
                                                placeholder=""
                                            /> :
                                            <DatePicker
                                                selected={this.state.date_start}
                                                onChange={this.handleDateStart}
                                                dateFormat="yyyy-MM-dd"
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                            />
                                        }
                                    </div>
                                     <div className="col-1 text-center">
                      ถึง
                    </div>
                                    <div className="form-group ml-1" style={{width: 130}}>
                                        {this.state.data_by === 'all' ?
                                            <DatePicker
                                                selected={this.state.date_end}
                                                onChange={this.handleDateEnd}
                                                dateFormat="yyyy-MM-dd"
                                                type="text"
                                                className="form-control"
                                                disabled
                                                placeholder=""
                                            />
                                            :
                                            <DatePicker
                                                selected={this.state.date_end}
                                                onChange={this.handleDateEnd}
                                                dateFormat="yyyy-MM-dd"
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                            />
                                        }
                                    </div>

                                    <div className="form-group ml-1" style={{width: 160}}>
                                        <select className="form-control"
                                                id="data_by" name="data_by"
                                                onChange={this.handleOnChange}
                                                value={this.state.data_by}
                                        >
                                            <option value='byDate'> ตามวันที่ </option>
                                            <option value='all'> ทั้งหมด(ยังไม่ขาย) </option>
                                        </select>
                                    </div>

                                    <div className="form-group ml-1" style={{width: 130}}>
                                        <select className="form-control" id="branch_id" name="branch_id"
                                                onChange={(e) => {
                                                    this.setState({
                                                        branch_id: e.target.value
                                                    })
                                                }}
                                        >
                                            <option value=''> ตามสาขา </option>
                                            {car_branch_list}
                                        </select>
                                    </div>

                                    <div className="form-group ml-1" style={{width: 150}}>
                                        <select className="form-control"
                                                id="person_vat_id"
                                                name="person_vat_id"
                                                value={this.state.person_vat_id}
                                                onChange={(e) => {
                                                    this.setState({
                                                        person_vat_id : e.target.value
                                                    })
                                                }}
                                        >
                                            <option value="0">ประเภทการซื้อรถ</option>
                                            <option value="1">บุคคล/รถบ้าน</option>
                                            <option value="2">รถ VAT</option>
                                            <option value="3">รถฝากขาย</option>
                                        </select>
                                    </div>

                                    <div className="form-group ml-1" style={{width: 120}}>
                                        <select className="form-control mr-1"
                                                name="order_by"
                                                style={{...styles.selectFilter, width: width_box}}
                                                value={this.state.order_by}
                                                onChange={(e) => {
                                                    this.setState({order_by: e.target.value})
                                                }}
                                        >
                                            <option value="">เรียงตาม</option>
                                            {order_list}
                                        </select>
                                    </div>



                                    <div className="col-1 ml-4 mr-1">
                                        <button type="button" style={{width: 80}} className="btn btn-sm btn-primary" onClick={() => this.loadCarInData()} > ตกลง </button>
                                    </div>

                                    <div className="col-1">
                                        <button type="button" style={{width: 80}} className="btn btn-sm btn-success" onClick={() => this.exportCarInData()} > Excel </button>
                                    </div>

                                </div>*/}

                                <div className="row mt-1">
                                    <div className="col-12">
                                        <table className="table table-striped table-responsive">
                                            <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th style={{width: 200}} className="text-center text-nowrap">ทะเบียนรถ</th>
                                                <th className="text-center text-nowrap"> ยี่ห้อรถ </th>

                                                <th className="text-center text-nowrap" style={{width: 100}}>วันที่ซื้อรถเข้า</th>

                                                <th className={viewed ? "text-center text-nowrap" : "hidden"}>ราคาซื้อเข้า</th>
                                                <th className={viewed ? "text-center text-nowrap" : "hidden"}>VAT ซื้อ</th>
                                                <th className={viewed ? "text-center text-nowrap" : "hidden"}> ราคาซื้อรวม VAT </th>

                                                <th className={viewed ? "text-center text-nowrap" : "hidden"}> ต้นทุน+คชจ.<br/>ไม่รวม VAT </th>
                                                <th className={viewed ? "text-center text-nowrap" : "hidden"}> คชจ.(ไม่รวม VAT) </th>
                                                <th className={viewed ? "text-center text-nowrap" : "hidden"}> รวม </th>

                                                {/*<th className={viewed ? "text-center text-nowrap" : "hidden"}>ทุนซ่อม</th>
                                                <th className={viewed ? "text-center text-nowrap" : "hidden"}>ทุนอื่นๆ</th>
                                                <th className={viewed ? "text-center text-nowrap" : "hidden"}>ราคาขาย</th>*/}

                                            </tr>
                                            </thead>
                                            <tbody>

                                            {tableRows}

                                            {
                                                viewed ? sumRow: null
                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}
// visible
// overflow: 'scroll',
const styles = {
    overflowY: {
        overflow: 'scroll',
    }
}

// const mapStateToProps = (state) => ({
//   user: state.auth.user,
//   token: state.auth.token,
//   isAuthenticated: state.auth.isAuthenticated
// })
const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    type: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated
})

// export default CarInReport;
export default connect(mapStateToProps)(CarInCostSummaryReport);

