import React, {Component} from 'react';

// https://github.com/keitaisozaki/react-line-share-btn/blob/master/src/index.js

export default class LineShareBtn extends Component{
    render(){
        let text = this.props.text;
        const url = this.props.url;
        const size = this.props.size;

        let iconName = "https://s3-ap-northeast-1.amazonaws.com/katy7/line-logo.png";
        let iconClassName = "share-icon"

        if(size == "large"){
            iconName = "https://developers.line.biz/media/line-social-plugins/th/wide-default.png"
            iconClassName = "share-icon-large"
        }

        if(text){
            text = encodeURI(this.props.text) + "%0D%0A";
        }

        // console.log(text)
        // console.log(url)

        //
        // href={"https://line.me/R/msg/text/"+text+url}
        let encodeUrl = encodeURI(url);

        return(
            <a className="share-line-btn" href={"https://social-plugins.line.me/lineit/share?url="+encodeUrl+"&text="+text} target="blank">
                <img src={iconName} className={iconClassName}/>
            </a>
        );
    }
}