import * as XLSX from "xlsx-js-style";


export const formatBoldRight = { font: { name: "AngsanaUPC", sz: 16, bold: true, underline: true }, alignment: {horizontal: 'right'} }
export const formatNormalCenter = { font: { name: "AngsanaUPC", sz: 16, bold: false, underline: false }, alignment: {horizontal: 'center'} }
export const formatBoldCenter = { font: { name: "AngsanaUPC", sz: 16, bold: true, underline: false }, alignment: {horizontal: 'center'} }
export const formatCenterUnderline = { font: { name: "AngsanaUPC", sz: 16, bold: false, underline: true }, alignment: {horizontal: 'center'} }
export const formatCenterBoldUnderline = { font: { name: "AngsanaUPC", sz: 16, bold: true, underline: true }, alignment: {horizontal: 'center'} }
export const formatBorderBottom = { font: { name: "AngsanaUPC", sz: 16, bold: false, underline: false }, alignment: {horizontal: 'center'}, border: { bottom: { style: 'thin', color: { rgb: "000000" } }, } }


export const ExcelUtil = {
    getBorderCell() {
        const cellTable = {
            top: { style: 'thin', color: { rgb: "000000" } },
            bottom: { style: 'thin', color: { rgb: "000000" } },
            left: { style: 'thin', color: { rgb: "000000" } },
            right: { style: 'thin', color: { rgb: "000000" } },
        }
        return cellTable
    },
    getNoBorderCell() {
        const cellTable = {
            top: { style: 'thin', color: { rgb: "ffffff" } },
            bottom: { style: 'thin', color: { rgb: "ffffff" } },
            left: { style: 'thin', color: { rgb: "ffffff" } },
            right: { style: 'thin', color: { rgb: "ffffff" } },
        }
        return cellTable
    },
    setCellBoldWithBorder(ws, rangeTitle) {
        // cell.s = {alignment:{ wrapText: true }}
        for(let R = rangeTitle.s.r; R <= rangeTitle.e.r; ++R) {
            for(let C = rangeTitle.s.c; C <= rangeTitle.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell){
                    continue;
                }
                cell.s = {
                    alignment: { horizontal: "center", wrapText: true },
                    font: { bold: true, name: "AngsanaUPC", sz: "16"},
                    border: this.getBorderCell()
                }
            }
        }
    },
    setCellTextRightBoldWithBorder(ws, rangeTitle) {
        // cell.s = {alignment:{ wrapText: true }}
        for(let R = rangeTitle.s.r; R <= rangeTitle.e.r; ++R) {
            for(let C = rangeTitle.s.c; C <= rangeTitle.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell){
                    continue;
                }
                cell.s = {
                    alignment: { horizontal: "right", wrapText: true },
                    font: { bold: true, name: "AngsanaUPC", sz: "16"},
                    border: this.getBorderCell()
                }
            }
        }
    },
    setCellNormalWithBorder(ws, rangeOrder){
        // alignment: { horizontal: "center" },
        for(let R = rangeOrder.s.r; R <= rangeOrder.e.r; ++R) {
            for(let C = rangeOrder.s.c; C <= rangeOrder.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell) continue;

                cell.s = {
                    font: {name: "AngsanaUPC", sz: "16"},
                    border: this.getBorderCell()
                }
            }
        }
    },
    setCellNormalWithNoBorder(ws, rangeOrder){
        // alignment: { horizontal: "center" },
        for(let R = rangeOrder.s.r; R <= rangeOrder.e.r; ++R) {
            for(let C = rangeOrder.s.c; C <= rangeOrder.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell) continue;

                cell.s = {
                    font: {name: "AngsanaUPC", sz: "16"},
                    // border: this.getNoBorderCell()
                }
            }
        }
    },
    setCellCenterNormalWithBorder(ws, rangeOrder){
        for(let R = rangeOrder.s.r; R <= rangeOrder.e.r; ++R) {
            for(let C = rangeOrder.s.c; C <= rangeOrder.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell) continue;

                cell.s = {
                    alignment: { horizontal: "center" },
                    font: {name: "AngsanaUPC", sz: "16"},
                    border: this.getBorderCell()
                }
            }
        }
    },

    setCellNumberFormatWIthBorder(ws, rangeNumber) {
        // alignment: { horizontal: "right", wrapText: true },
        let fmt = '#,##0.00';
        for(let R = rangeNumber.s.r; R <= rangeNumber.e.r; ++R) {
            for(let C = rangeNumber.s.c; C <= rangeNumber.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell) continue;

                cell.s = {
                    border: this.getBorderCell(),
                    font: {name: "AngsanaUPC", sz: "16"},
                    alignment: { horizontal: "right", wrapText: true },
                }

                if(!cell || cell.t != 'n') continue; // only format numeric cells

                cell.z = fmt;

                // alignment: { horizontal: "right", wrapText: true },
                cell.s = {
                    border: this.getBorderCell(),
                    font: {name: "AngsanaUPC", sz: "16"},
                }
            }
        }
    },
    setCellNumberFormatRightWithBorder(ws, rangeNumber) {
        //
        let fmt = '#,##0.00';
        for(let R = rangeNumber.s.r; R <= rangeNumber.e.r; ++R) {
            for(let C = rangeNumber.s.c; C <= rangeNumber.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell) continue;

                cell.s = {
                    alignment: { horizontal: "right", wrapText: true },
                    border: this.getBorderCell(),
                    font: {name: "AngsanaUPC", sz: "16"},
                }

                if(!cell || cell.t != 'n') continue; // only format numeric cells

                cell.z = fmt;

                cell.s = {
                    alignment: { horizontal: "right", wrapText: true },
                    border: this.getBorderCell(),
                    font: {name: "AngsanaUPC", sz: "16"},
                }
            }
        }
    },
    setCellNumberFormatRightBoldWithBorder(ws, rangeNumber) {
        //
        let fmt = '#,##0.00';
        for(let R = rangeNumber.s.r; R <= rangeNumber.e.r; ++R) {
            for(let C = rangeNumber.s.c; C <= rangeNumber.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell) continue;

                cell.s = {
                    alignment: { horizontal: "right", wrapText: true },
                    border: this.getBorderCell(),
                    font: {name: "AngsanaUPC", sz: "16", bold: true},
                }

                if(!cell || cell.t != 'n') continue; // only format numeric cells

                cell.z = fmt;

                cell.s = {
                    alignment: { horizontal: "right", wrapText: true },
                    border: this.getBorderCell(),
                    font: {name: "AngsanaUPC", sz: "16", bold: true,},
                }
            }
        }
    },
    setCellNumberFormatBlueWithBorder(ws, rangeNumber) {
        let fmt = '#,##0.00';
        let fontStyle = { color: { rgb: "164E9B" } }
        for(let R = rangeNumber.s.r; R <= rangeNumber.e.r; ++R) {
            for(let C = rangeNumber.s.c; C <= rangeNumber.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell) continue;

                cell.s = {
                    border: this.getBorderCell(),
                    font: fontStyle
                }

                if(!cell || cell.t != 'n') continue; // only format numeric cells

                cell.z = fmt;

                cell.s = {
                    border: this.getBorderCell(),
                    font: fontStyle
                }
            }
        }
    },
    toDataURL(url) {
        const promise = new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.onload = function () {
                let reader = new FileReader();
                reader.readAsDataURL(xhr.response);
                reader.onloadend = function () {
                    resolve({ base64Url: reader.result });
                };
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        });

        return promise;
    }
}
