

export const CarSellUtil = {
  setCarSellSelect(car_license_new) {
     window.localStorage.setItem('car_license_new', car_license_new)
  },
  getCarSellSelect() {
    return window.localStorage.getItem('car_license_new')
  },
  removeCarSellSelect() {
    return window.localStorage.removeItem('car_license_new')
  }
}

export const CarsUtil = {
  setCarSelect(car_license_new) {
    window.localStorage.setItem('car_license', car_license_new)
  },
  getCarSelect() {
    return window.localStorage.getItem('car_license')
  },
  removeCarSelect() {
    return window.localStorage.removeItem('car_license')
  }
}

export const CarBookUtil = {
  setCarBookSelect(car_license_new) {
    window.localStorage.setItem('car_license_book', car_license_new)
  },
  getCarBookSelect() {
    return window.localStorage.getItem('car_license_book')
  },
  removeCarBookSelect() {
    return window.localStorage.removeItem('car_license_book')
  }
}

export const getCountAndGroupBy = (arrObjects, keyName) => {
  let _ = require('lodash');
  let countByValueOfKey = _(arrObjects)
      .groupBy(keyName)
      .map(function(item, itemId) {
        var obj = {};
        obj[itemId] = _.countBy(item, keyName)
        return obj
      }).value();
  return countByValueOfKey
}
