import React, { Component } from 'react';
import axios from 'axios'
import {connect} from 'react-redux' ;
import MaskedInput  from 'react-maskedinput'
import NumberFormat from 'react-number-format'
import DatePicker from 'react-datepicker'
import { format } from "date-fns";
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/th';
import {APIURL, PORT} from '../../../config/config'
import BDatePicker from '../../BDatePicker'
import {AlertError, AlertWarning} from "../../Alert/Alert";
moment.locale('th');

const port = Number(window.env.PORT);

class CarEditGeneral extends Component {
    constructor(props) {
        super(props);
        //this.handleOnSubmitCustomer.bind(this)
        this.state = {
            user: {},
            lists: [],
            car_brands : [],
            car_models :[],
            car_sub_models:[],
            car_years :[],
            car_colors :[],
            car_gears:[],
            car_status:[],
            province:[],
            branch:[],
            car_hassock:[],
            car_system_drive:[],
            car_type_engine:[],
            car_type:[],
            car_structure:[],
            load_data: true,
            danger: false,
            liciense_status : [],
            //action: "บันทึก",
            customer: '',
            car: '',
            car_id: this.props.car.car_id,
            branch_id: this.props.car.branch_id,
            car_date_in:  format(new Date(this.props.car.car_date_in), 'yyyy-MM-dd'),
            car_name: this.props.car.car_name,
            car_detail: this.props.car.car_detail,
             // id
            car_brand_id: this.props.car.car_brand_id,
            car_model_id: this.props.car.car_model_id,
            car_sub_model_id: this.props.car.car_sub_model_id,
            car_gear_id: this.props.car.car_gear_id,
            car_color_id: this.props.car.car_color_id,
            car_year_id: this.props.car.car_year_id,
            // name
          car_brand_name: this.props.car.car_brand_name,
          car_model_name: this.props.car.car_model_name,
          car_sub_model_name: this.props.car.car_sub_model_name,
          car_gear_name: this.props.car.car_gear_name,
          car_color_name: this.props.car.car_color_name,
          car_year_name: this.props.car.car_year_name,

            car_license_plate_new: this.props.car.car_license_plate_new,
            province_new_id: this.props.car.province_new_id,
            car_miles: this.props.car.car_miles,
            car_number: this.props.car.car_number,
            car_num_engines: this.props.car.car_num_engines,
            car_date_regis: this.props.car.car_date_regis,
            car_type_id: this.props.car.car_type_id,
            car_structure_id: this.props.car.car_structure_id,
            car_hassock_id: this.props.car.car_hassock_id,
            car_system_drive_id: this.props.car.car_system_drive_id,
            car_type_engine_id: this.props.car.car_type_engine_id,
            car_brand_engine: this.props.car.car_brand_engine,
            car_gas_number: this.props.car.car_gas_number,
            car_weight: this.props.car.car_weight,
            car_size_engine: this.props.car.car_size_engine,
            car_date_tax: this.props.car.car_date_tax,
            car_tax: this.props.car.car_tax,
            car_status_id: this.props.car.car_status_id,
          liciense_status_id: this.props.car.liciense_status_id,
          finance_id: this.props.car.finance_id,
          floorplan_credit: this.props.car.floorplan_credit,
          finance_floorplan_id: this.props.car.finance_floorplan_id,
          insurance_type_id: this.props.car.insurance_type_id,
          insurance_company_id: this.props.car.insurance_company_id,
          floorplan_start_date: this.props.car.floorplan_start_date!==null &&  this.props.car.floorplan_start_date!=='0000-00-00' ? new Date(this.props.car.floorplan_start_date) : null,
          floorplan_due_date: this.props.car.floorplan_due_date!==null &&  this.props.car.floorplan_due_date!=='0000-00-00' ? new Date(this.props.car.floorplan_due_date) : null,
          floorplan_annual_interest: this.props.car.floorplan_annual_interest,
          receipt_date: this.props.car.receipt_date!==null &&  this.props.car.receipt_date!=='0000-00-00' ? new Date(this.props.car.receipt_date) : null,
          //deposit_registration_id
          deposit_registration_id: this.props.car.deposit_registration_id,
          web_info: this.props.car.web_info,

            startDateEdit1: moment(),
            startDateEdit2: moment(),
            startDateEdit3: moment(),
            startcardatein_bb: '',
            startcardatetax_bb:'',
            startcardateregis_bb:'',

          banks: [],
          insurance_companies: [],
          insurance_types: [],
          form_controls: [],
          insurance_end_date: this.props.car.insurance_end_date!==null &&  this.props.car.insurance_end_date!=='0000-00-00' ? new Date(this.props.car.insurance_end_date) : null,
          compulsory_insurance_end_date: this.props.car.compulsory_insurance_end_date!==null &&  this.props.car.compulsory_insurance_end_date!=='0000-00-00' ? format(new Date(this.props.car.compulsory_insurance_end_date), 'yyyy-MM-dd') : null,
          compulsory_insurance: this.props.car.compulsory_insurance,
          compulsory_insurance_company: this.props.car.compulsory_insurance_company,
          car_spare_key: this.props.car.car_spare_key,
          car_manual: this.props.car.car_manual,
          park_location: this.props.car.park_location,
          car_parking_list: [],
          buy_car_from: this.props.car.buy_car_from,
          code_item: this.props.car.code_item,
          // at
          car_book_service: this.props.car.car_book_service,
          car_miles_center: this.props.car.car_miles_center,
          car_miles_center_date: this.props.car.car_miles_center_date !==null &&  this.props.car.car_miles_center_date !=='0000-00-00' ? format(new Date(this.props.car.car_miles_center_date), 'yyyy-MM-dd') : null,
          car_miles_status: this.props.car.car_miles_status,
          car_grade: this.props.car.car_grade,
          warantee_date_end: this.props.car.warantee_date_end !==null &&  this.props.car.warantee_date_end !=='0000-00-00' ? format(new Date(this.props.car.warantee_date_end), 'yyyy-MM-dd') : null,
          car_name_new: '',
          car_detail_new: '',
          tmp_car_brand: '',
          tmp_car_model: '',
          tmp_car_sub_model: '',
          tmp_car_year: '',
          tmp_car_color: '',
          tmp_car_gear: '',
          change_book_status: [],
          car_adapt_status: [],
          change_book_status_id: this.props.car.change_book_status_id,
          car_adapt_status_id: this.props.car.car_adapt_status_id,
            car_explain: this.props.car.car_explain,
        }
        this.handleDateChange1 = this.handleDateChange1.bind(this);
        this.handleDateChange2 = this.handleDateChange2.bind(this);
        this.handleDateChange3 = this.handleDateChange3.bind(this);

        this.convertBBtoCC = this.convertBBtoCC.bind(this);
        this.onMaskedEditChange1 = this.onMaskedEditChange1.bind(this);
        this.onMaskedEditChange2 = this.onMaskedEditChange2.bind(this);
        this.onMaskedEditChange3 = this.onMaskedEditChange3.bind(this);

        this.onInputChange = this.onInputChange.bind(this)
        this.loadBanks = this.loadBanks.bind(this)
        this.loadInsuranceCompanies = this.loadInsuranceCompanies.bind(this)
        this.loadInsuranceType = this.loadInsuranceType.bind(this)
        this.handleFloorplanDueDate = this.handleFloorplanDueDate.bind(this)
        this.handleFloorplanStartDate = this.handleFloorplanStartDate.bind(this)
        this.handleReceiptDate = this.handleReceiptDate.bind(this)
        this.handleCarDateIn = this.handleCarDateIn.bind(this)
        this.handleOnSubmitCarEditGeneral = this.handleOnSubmitCarEditGeneral.bind(this)
        //this.handleOnChangeNoSet = this.handleOnChangeNoSet.bind(this);

        this.loadFormControls = this.loadFormControls.bind(this)
        this.handleInsuranceEndDate = this.handleInsuranceEndDate.bind(this)
        this.loadCarParking = this.loadCarParking.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.makeCarName = this.makeCarName.bind(this)
        this.handleCarModelChange = this.handleCarModelChange.bind(this)
        this.handleCarSubModelChange = this.handleCarSubModelChange.bind(this)
        this.handleCarYearChange = this.handleCarYearChange.bind(this)
        this.loadChangeBookStatus = this.loadChangeBookStatus.bind(this)
        this.loadCarAdaptStatus = this.loadCarAdaptStatus.bind(this)

    }

    // handleOnChangeNoSet() { }

  handleCarSubModelChange (event) {
    let car_sub_model = event.target.options[event.target.selectedIndex].text
    let car_sub_model_id = event.target.value;
    this.setState({
        tmp_car_sub_model: car_sub_model,
        car_sub_model_id: car_sub_model_id,
      },
      () => {
        this.makeCarName()
      })

  }

  handleFloorplanDueDate(date) {
    this.setState({
      floorplan_due_date: date
    })
  }

    handleFloorplanStartDate(date) {
        this.setState({
            floorplan_start_date: date
        })
    }

  handleReceiptDate(date) {
    this.setState({
      receipt_date: date
    })
  }

  handleCarDateIn(date) {
    this.setState({
      car_date_in: date
    })
  }

  handleInsuranceEndDate(date) {
    this.setState({
      insurance_end_date: date
    })
  }

  loadBanks () {
    axios.get(APIURL + '/bank/')
      .then(res => {
        this.setState({banks: res.data})
      }).catch(error => {
      this.setState({banks: []})
    })
  }

  loadInsuranceCompanies() {
    axios.get(APIURL + '/insurance_company/')
      .then(res => {
        this.setState({insurance_companies: res.data})
      }).catch(error => {
      this.setState({insurance_companies: []})
    })
  }

  //insurance_type
  loadInsuranceType() {
    axios.get(APIURL + '/insurance_type/')
      .then(res => {
        this.setState({insurance_types: res.data})
      }).catch(error => {
      this.setState({insurance_types: []})
    })
  }

    handleOnSubmitCarEditGeneral(event){
        event.persist()
        event.preventDefault()

        let branch_id = event.target.branch_id.value
        let car_date_in = this.state.car_date_in

        let car_name = event.target.car_name.value
        let car_detail = event.target.car_detail.value

        let car_brand_id = event.target.car_brand_id.value
        let car_model_id = event.target.car_model_id.value
        let car_sub_model_id = event.target.car_sub_model_id.value
        let car_year_id = event.target.car_year_id.value
        let car_color_id = event.target.car_color_id.value
        let car_gear_id = event.target.car_gear_id.value
        let car_license_plate_new = event.target.car_license_plate_new.value
        let province_new_id = event.target.province_new_id.value
        // let car_miles = event.target.car_miles.value
        let car_number = event.target.car_number.value
        car_number = car_number.toUpperCase()
        let car_num_engines = event.target.car_num_engines.value
        car_num_engines = car_num_engines.toUpperCase()
        //let car_date_regis = event.target.car_date_regis.value
        // let car_date_regis = this.state.startDateEdit3
        let car_date_regis = this.state.car_date_regis
        let car_type_id= event.target.car_type_id.value
        let car_structure_id = event.target.car_structure_id.value
        let car_hassock_id = event.target.car_hassock_id.value
        let car_system_drive_id = event.target.car_system_drive_id.value
        let car_type_engine_id = event.target.car_type_engine_id.value
        let car_brand_engine = event.target.car_brand_engine.value
        let car_gas_number = event.target.car_gas_number.value
        let car_weight = event.target.car_weight.value
        let car_size_engine = event.target.car_size_engine.value
        //let car_date_tax = event.target.car_date_tax.value
        let car_date_tax = this.state.startDateEdit2
        let car_tax = event.target.car_tax.value
        let car_status_id = event.target.car_status_id.value

      // console.log('car_status_id=', car_status_id)
      // return;
      // this.state.startDateEdit3

      //pisan
      let liciense_status_id = event.target.liciense_status_id.value
      let finance_id = event.target.finance_id.value
      let floorplan_credit = event.target.floorplan_credit.value
      let finance_floorplan_id = event.target.finance_floorplan_id.value
      let insurance_type_id = event.target.insurance_type_id.value
      let insurance_company_id = event.target.insurance_company_id.value

        // let receipt_date = event.target.receipt_date.value
      // let floorplan_start_date = event.target.floorplan_start_date.value
      // let floorplan_due_date = event.target.floorplan_due_date.value

        let receipt_date = this.state.receipt_date
        let floorplan_start_date = this.state.floorplan_start_date
        let floorplan_due_date = this.state.floorplan_due_date

      let floorplan_annual_interest = event.target.floorplan_annual_interest.value
      let deposit_registration_id = event.target.deposit_registration_id.value

      // พรบ.
      // let insurance_end_date = event.target.insurance_end_date.value
      let insurance_end_date = this.state.insurance_end_date
      let compulsory_insurance = event.target.compulsory_insurance.value
      let compulsory_insurance_company = event.target.compulsory_insurance_company.value

      let compulsory_insurance_end_date = this.state.compulsory_insurance_end_date
      let car_spare_key = event.target.car_spare_key.value
      let car_manual = event.target.car_manual.value
      let park_location = event.target.park_location.value
      let buy_car_from = event.target.buy_car_from.value
      let web_info = event.target.web_info.value
      //kmhomecar //thadyon

      // let code_item = port === 9038 || port === 9039 ?  event.target.code_item.value : ''
      let code_item =  event.target.code_item.value

      // at
      let car_miles_center = this.state.car_miles_center
      let car_miles_center_date = this.state.car_miles_center_date
      let car_miles = this.state.car_miles
      let car_miles_status =  this.state.car_miles_status
      let car_book_service = event.target.car_book_service.value
      // carok
      let change_book_status_id = event.target.change_book_status_id.value
      let car_adapt_status_id = event.target.car_adapt_status_id.value
      //
      let car_grade = this.state.car_grade
      let warantee_date_end = this.state.warantee_date_end
      let car_explain = this.state.car_explain

      // console.log('car_explain=', car_explain)
      // return;
        if(car_date_in === null || car_date_in === '') {
            AlertError('กรุณาระบุวันที่ซื้อเข้า')
            return
        }

        if (PORT === 9043 && car_name === '') {
            car_name = '-'
        }

      if(car_name===''){
            AlertWarning('กรุณาระบุชื่อรถ');
            return ;
        }
        event.target.branch_id.value = ""

        event.target.car_date_in.value = ""
        event.target.car_name.value = ""
        event.target.car_detail.value = ""
        event.target.car_brand_id.value = ""
        event.target.car_model_id.value = ""
        event.target.car_sub_model_id.value = ""
        event.target.car_year_id.value = ""
        event.target.car_color_id.value = ""
        event.target.car_gear_id.value = ""
        event.target.car_license_plate_new.value = ""
        event.target.province_new_id.value = ""

        event.target.car_number.value = ""
        event.target.car_num_engines.value = ""
        event.target.car_date_regis.value = ""
        event.target.car_type_id.value = ""
        event.target.car_structure_id.value = ""
        event.target.car_hassock_id.value = ""
        event.target.car_system_drive_id.value = ""
        event.target.car_type_engine_id.value = ""
        event.target.car_brand_engine.value = ""
        event.target.car_gas_number.value = ""
        event.target.car_weight.value = ""
        event.target.car_size_engine.value = ""
        event.target.car_date_tax.value = ""
        event.target.car_tax.value = ""
        event.target.car_status_id.value = ""
        event.target.park_location.value = ""
        event.target.buy_car_from.value = ""
        event.target.web_info.value = ""
        event.target.code_item.value = ""
        event.target.car_book_service.value = ""
        event.target.liciense_status_id.value = ""
        event.target.change_book_status_id.value = ""
        event.target.car_adapt_status_id.value = ""

        axios.put(APIURL + '/car/car_general/'+this.state.car_id, {
            branch_id : branch_id,
            car_date_in : car_date_in,
            car_name : car_name,
            car_detail : car_detail,
            car_brand_id : car_brand_id,
            car_model_id : car_model_id,
            car_sub_model_id : car_sub_model_id,
            car_gear_id : car_gear_id,
            car_color_id : car_color_id,
            car_year_id : car_year_id,
            car_license_plate_new : car_license_plate_new,
            province_new_id : province_new_id,

            car_number : car_number,
            car_num_engines : car_num_engines,
            car_date_regis : car_date_regis,
            car_type_id : car_type_id,
            car_structure_id : car_structure_id,
            car_hassock_id : car_hassock_id,
            car_system_drive_id : car_system_drive_id,
            car_type_engine_id : car_type_engine_id,
            car_brand_engine : car_brand_engine,
            car_gas_number : car_gas_number,
            car_weight : car_weight,
            car_size_engine : car_size_engine,
            car_date_tax : car_date_tax,
            car_tax : car_tax,
            car_status_id : car_status_id,
            user_updated: this.state.user.user,
          //pisan
            liciense_status_id: liciense_status_id,
            finance_id: finance_id,
            floorplan_credit: floorplan_credit,
            finance_floorplan_id: finance_floorplan_id,
            insurance_type_id: insurance_type_id,
            insurance_company_id: insurance_company_id,
            floorplan_start_date: floorplan_start_date,
            floorplan_due_date: floorplan_due_date,
            floorplan_annual_interest: floorplan_annual_interest,
            receipt_date: receipt_date,
            deposit_registration_id: deposit_registration_id,
          //
          insurance_end_date : insurance_end_date,
          compulsory_insurance : compulsory_insurance,
          compulsory_insurance_company : compulsory_insurance_company,
          compulsory_insurance_end_date : compulsory_insurance_end_date,
          car_spare_key : car_spare_key,
          car_manual : car_manual,
          park_location : park_location,
          buy_car_from: buy_car_from,
          web_info: web_info,
          code_item : code_item,
          // at
          car_book_service,
          car_miles_center,
          car_miles_center_date,
          car_miles,
          car_miles_status,
          car_grade,
          warantee_date_end,
          change_book_status_id,
          car_adapt_status_id,
            car_explain
        })
            .then(response => {

              if(response.status === 200) {
                this.props.onToggle()
                this.props.onLoadCarById()
              }

            })
            .catch(function (error) {
                 alert("พบข้อผิดพลาด : "+error)
            })
    }

    handleDateChange1(date) {
        //alert (date);
        if (date==='')
            return;

        this.setState({
            startDateEdit1: moment(date).format('YYYY-MM-DD'),
            car_date_in : date
        });
    }

    handleDateChange2(date) {
        this.setState({
            startDateEdit2: moment(date).format('YYYY-MM-DD'),
            car_date_tax : date
        });
    }

    handleDateChange3(date) {
        this.setState({
            startDateEdit3: moment(date),
            car_date_regis: date
        });
    }

     onMaskedEditChange1(e){
         let buddhist_date = e.target.value ;
         //alert('buddhist_date='+buddhist_date);
         if(buddhist_date.length===10){
             let cc_date = this.convertBBtoCC(buddhist_date)
             this.setState({startcardatein_bb: buddhist_date})
             this.handleDateChange1(cc_date)
         }
     }

     onMaskedEditChange2(e){
         let buddhist_date = e.target.value ;
         //alert('buddhist_date='+buddhist_date);
         if(buddhist_date.length===10){
             let cc_date = this.convertBBtoCC(buddhist_date)
             this.setState({startcardatetax_bb: buddhist_date})
             this.handleDateChange2(cc_date)
         }
     }

     onMaskedEditChange3(e){
         let buddhist_date = e.target.value ;
         //alert('buddhist_date='+buddhist_date);
         if(buddhist_date.length===10){
             let cc_date = this.convertBBtoCC(buddhist_date)
             this.setState({startcardateregis_bb: buddhist_date})
             this.handleDateChange3(cc_date)
         }
     }

     convertBBtoCC(buddhist_date){ //dd-mm-yyyy to yyyy-mm-dd
         let date_arr=buddhist_date.split('-');
         let dd = date_arr[0];
         let mm = date_arr[1];
         let yyyy = date_arr[2]-543;
         let cc_date= yyyy+'-'+mm+'-'+dd ;
         if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
             //alert(cc_date);
             return cc_date ;
         }
         return moment().format('YYYY-MM-DD') ;
     }

     convertCCtoBB(cc_date){ //2018-01-27
         let date_arr=cc_date.split('-');
         let yyyy = Number(date_arr[0])+543;
         let mm = date_arr[1];
         let dd = date_arr[2];

         return dd+'-'+mm+'-'+yyyy

     }

     updateCarDateIn(){
         let car_date_in = this.props.car.car_date_in
             if(car_date_in!==undefined) {
                 let date_in = moment(car_date_in).format('YYYY-MM-DD');
                 let startcardatein_bb = this.convertCCtoBB(date_in);
                 this.setState({
                     startDateEdit1: date_in,
                     startcardatein_bb: startcardatein_bb
                 });
             }
     }

     updateCarDateTax(){
         let car_date_tax = this.props.car.car_date_tax

         if(car_date_tax!==undefined) {
             let date_tax = moment(car_date_tax).format('YYYY-MM-DD');
             let startcardatetax_bb = this.convertCCtoBB(date_tax);
             this.setState({
                 startDateEdit2: date_tax,
                 startcardatetax_bb: startcardatetax_bb
             });
         }
     }

     updateCarDateRegis(){
         let car_date_regis = this.props.car.car_date_regis
         if(car_date_regis!==undefined) {
           let date_regis = moment(car_date_regis).format('YYYY-MM-DD');
           let startcardateregis_bb = this.convertCCtoBB(date_regis);
             this.setState({
                 startDateEdit3: date_regis,
                 startcardateregis_bb: startcardateregis_bb,
                 car_date_regis: car_date_regis
             });
         }
     }


    onInputChange(event) {
        event.preventDefault()


        if (event.target.id === 'branch_id') {
            // var name = this.refs.branch_id.val;
            this.setState({branch_id: event.target.value});
        }else if (event.target.id === 'car_date_in'){
            this.setState({car_date_in: this.refs.car_date_in.val})
        }else if (event.target.id === 'car_name') {
            this.setState({car_name: this.refs.car_name.val})
        }else if (event.target.id === 'car_detail') {
            this.setState({car_detail: this.refs.car_detail.val})
        }else if (event.target.id === 'car_brand_id') {
            this.setState({car_brand_id: this.refs.car_brand_id.val})
        }else if (event.target.id === 'car_model_id') {
            this.setState({car_model_id: this.refs.car_model_id.val})
        }else if (event.target.id === 'car_sub_model_id') {
            this.setState({car_sub_model_id: this.refs.car_sub_model_id.val})
        }else if (event.target.id === 'car_gear_id') {
            this.setState({car_gear_id: this.refs.car_gear_id.val})
        }else if (event.target.id === 'car_color_id') {
            this.setState({car_color_id: this.refs.car_color_id.val})
        }else if (event.target.id === 'car_year_id') {
            this.setState({car_year_id: this.refs.car_year_id.val})
        }else if (event.target.id === 'car_license_plate_new') {
            this.setState({car_license_plate_new: event.target.value })
        }else if (event.target.id === 'province_new_id') {
            this.setState({province_new_id: this.refs.province_new_id.val})
        }else if (event.target.id === 'car_miles') {
            this.setState({car_miles: event.target.value  })
        }else if (event.target.id === 'car_number') {
            this.setState({car_number: event.target.value })
        }else if (event.target.id === 'car_num_engines') {
            this.setState({car_num_engines: event.target.value  })
        }else if (event.target.id === 'car_date_regis') {
            this.setState({car_date_regis: this.refs.car_date_regis.val})
        }else if (event.target.id === 'car_type_id') {
            this.setState({car_type_id: this.refs.car_type_id.val})
        }else if (event.target.id === 'car_structure_id') {
            this.setState({car_structure_id: this.refs.car_structure_id.val})
        }else if (event.target.id === 'car_hassock_id') {
            this.setState({car_hassock_id: this.refs.car_hassock_idval})
        }else if (event.target.id === 'car_system_drive_id') {
            this.setState({car_system_drive_id: this.refs.car_system_drive_id.val})
        }else if (event.target.id === 'car_type_engine_id') {
            this.setState({car_type_engine_id: this.refs.car_type_engine_id.val})
        }else if (event.target.id === 'car_brand_engine') {
            this.setState({car_brand_engine: event.target.value})
        }else if (event.target.id === 'car_gas_number') {
            this.setState({car_gas_number: event.target.value})
        }else if (event.target.id === 'car_weight') {
            this.setState({car_weight:  event.target.value })
        }else if (event.target.id === 'car_size_engine') {
            this.setState({car_size_engine: event.target.value })
        }else if (event.target.id === 'car_date_tax') {
            this.setState({car_date_tax: event.target.value})
        }else if (event.target.id === 'car_tax') {
            this.setState({car_tax: event.target.value })
        }else if (event.target.id === 'car_status_id') {
            this.setState({car_status_id: this.refs.car_status_id.val})
        }
        //new
        else if (event.target.id === 'liciense_status_id') {
          this.setState({liciense_status_id: this.refs.liciense_status_id.val })
        } else if (event.target.id === 'finance_id') {
          this.setState({finance_id: event.target.value })
        } else if (event.target.id === 'floorplan_credit') {
          this.setState({floorplan_credit: event.target.value })
        } else if (event.target.id === 'finance_floorplan_id') {
          this.setState({finance_floorplan_id: event.target.value })
        } else if (event.target.id === 'insurance_type_id') {
          this.setState({insurance_type_id: event.target.value })
        } else if (event.target.id === 'insurance_company_id') {
          this.setState({insurance_company_id: event.target.value })
        } else if (event.target.id === 'deposit_registration_id') {
          this.setState({deposit_registration_id: event.target.value})
        } else if (event.target.id === 'floorplan_annual_interest') {
            this.setState({floorplan_annual_interest: event.target.value})
        }
        //new
        else if (event.target.id === 'compulsory_insurance') {
          var value = event.target.value
          this.setState({compulsory_insurance: value},() => {
            if(value === 'ไม่มี') {
              // this.refs.compulsory_insurance_company.value = 99
              this.setState({compulsory_insurance_company: 99 })
            }
          })
        } else if (event.target.id === 'compulsory_insurance_company') {
            this.setState({compulsory_insurance_company: event.target.value })
        } else if (event.target.id === 'car_spare_key') {
          this.setState({car_spare_key: event.target.value })
        } else if (event.target.id === 'car_manual') {
          this.setState({car_manual: event.target.value })
        } else if (event.target.id === 'park_location') {
          this.setState({park_location: event.target.value })
        }else if (event.target.id === 'code_item') {
            this.setState({code_item: event.target.value })
        }else if (event.target.id === 'car_grade') {
          this.setState({car_grade: event.target.value })
        }else if (event.target.id === 'change_book_status_id') {
          this.setState({change_book_status_id: event.target.value })
        }else if (event.target.id === 'car_adapt_status_id') {
          this.setState({car_adapt_status_id: event.target.value })
        }
    }

  handleOnChange(e) {
      let name = e.target.name
      let val = e.target.value
      this.setState({
        [name] : val
      })
  }

    handleCarBrandChange(event) {
        //alert ( event.target.value);
        let car_brand_id = event.target.value;
        let that = this ;
        let car_brand_name = event.target.options[event.target.selectedIndex].text

        this.setState({
          car_brand_id: car_brand_id,
          tmp_car_brand: car_brand_name,
          tmp_car_model: '',
          tmp_car_sub_model: '',
        }, () => {
          axios.get(APIURL + '/car_brand_model/' + car_brand_id)
            .then(res => {
              that.setState({
                car_models: res.data}, () => {
                that.makeCarName()
              })
            }).catch(error => {
            that.setState({car_models: []})
          })
        })

    }

    handleCarModelChange(event) {
        //alert ( event.target.value);
        let car_model_id = event.target.value;
        // let that = this ;

        let car_model = event.target.options[event.target.selectedIndex].text

        this.setState({
          car_model_id: car_model_id,
          tmp_car_model: car_model
        }, () => {

          axios.get(APIURL + '/car_sub_model/' + car_model_id)
            .then(res => {
              this.setState({car_sub_models: res.data}, () => {
                this.makeCarName()
              })

            }).catch(error => {
            this.setState({car_sub_models: []})
          })
        })

    }

  handleCarYearChange (event) {
    let car_year = event.target.options[event.target.selectedIndex].text
    let car_year_id = event.target.value
    this.setState({
      tmp_car_year: car_year,
      car_year_id: car_year_id
    }, () => {
      this.makeCarName()
      // this.makeCarDetail()
    })
  }

    loadCarBrandData () {
        axios.get(APIURL + '/car_brand/')
            .then(res => {
                this.setState({car_brands: res.data})
                this.loadCarModelDataByBrandId(this.state.car_brand_id);

            }).catch(error => {
            this.setState({car_brands: []})
        })
    }
    loadCarModelDataByBrandId(car_brand_id){
      axios.get(APIURL + '/car_brand_model/' + car_brand_id)
        .then(res => {
          this.setState({car_models: res.data})
          this.loadCarSubModelDataByCarModelId(this.state.car_model_id)
        }).catch(error => {
          this.setState({car_models: []})
      })
    }

    loadCarSubModelDataByCarModelId(car_model_id){
        axios.get(APIURL + '/car_sub_model/' + car_model_id)
          .then(res => {
            this.setState({car_sub_models: res.data})
          }).catch(error => {
            this.setState({car_sub_models: []})
          })

      }

    loadCarYearData () {
        axios.get(APIURL + '/car_year/')
            .then(res => {
                this.setState({car_years: res.data})
            }).catch(error => {
            this.setState({car_years: []})
        })
    }

    loadCarColorData () {
        axios.get(APIURL + '/car_color/')
            .then(res => {
                this.setState({car_colors: res.data})
            }).catch(error => {
            this.setState({car_colors: []})
        })
    }

    loadCarGearData () {
        axios.get(APIURL + '/car_gear/')
            .then(res => {
                this.setState({car_gears: res.data})
            }).catch(error => {
            this.setState({car_gears: []})
        })
    }

    loadCarStatusData () {
        // TODx: load สถานะหลังขายตอนแก้ไข กรณีขายแล้ว
        let car_sell_id = this.props.car_sell_id
        let uri = APIURL + '/car_status/new'
        // console.log('car_sell_id=', car_sell_id, typeof car_sell_id)
        if (car_sell_id !== 0 ) {
            uri = APIURL + '/car_status/sell/1'
        }

        axios.get(uri)
            .then(res => {
                this.setState({car_status: res.data})
            }).catch(error => {
            this.setState({car_status: []})
        })

    }

    loadProvinceData () {
        axios.get(APIURL + '/province/')
            .then(res => {
                this.setState({province: res.data})
            }).catch(error => {
            this.setState({province: []})
        })
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branch: res.data})
            }).catch(error => {
            this.setState({branch: []})
        })
    }

    loadCarHassockData () {
        axios.get(APIURL + '/car_hassock/')
            .then(res => {
                this.setState({car_hassock: res.data})
            }).catch(error => {
            this.setState({car_hassock: []})
        })
    }

    loadCarSystemDriveData () {
        axios.get(APIURL + '/car_system_drive/')
            .then(res => {
                this.setState({car_system_drive: res.data})
            }).catch(error => {
            this.setState({car_system_drive: []})
        })
    }

    loadCarTypeEngineData () {
        axios.get(APIURL + '/car_type_engine/')
            .then(res => {
                this.setState({car_type_engine: res.data})
            }).catch(error => {
            this.setState({car_type_engine: []})
        })
    }

    loadCarTypeData () {
        axios.get(APIURL + '/car_type/')
            .then(res => {
                this.setState({car_type: res.data})
            }).catch(error => {
            this.setState({car_type: []})
        })
    }

    loadCarStructureData () {
        axios.get(APIURL + '/car_structure/')
            .then(res => {
                this.setState({car_structure: res.data})
            }).catch(error => {
            this.setState({car_structure: []})
        })
    }

    loadLicienseStatus() {
        axios.get(APIURL + '/liciense_status/')
            .then(res => {
                this.setState({liciense_status: res.data})
            }).catch(error => {
            this.setState({liciense_status: []})
        })
    }

    /*loadData () {
        axios.get(APIURL + '/car/')
            .then(res => {
                this.setState({lists: res.data})
            }).catch(error => {
            this.setState({lists: []})
        })
    }*/

    componentWillMount() {
      this.setState({
       user: JSON.parse(this.props.user)
      });
    }

    componentDidMount () {

       //if(this.state.load_data)
       // this.setState({branch_name: this.props.car.branch_name })
        //this.loadData()
        this.loadFormControls()
        this.loadCarStatusData()
        this.loadCarBrandData()
        this.loadCarYearData()
        this.loadCarColorData()
        this.loadCarGearData()
        this.loadProvinceData()
        this.loadBranchData ()
        this.loadCarHassockData ()
        this.loadCarSystemDriveData ()
        this.loadCarTypeEngineData ()
        this.loadCarTypeData ()
        this.loadCarStructureData ()
        this.updateCarDateIn()
        this.updateCarDateTax()
        this.updateCarDateRegis()
        this.loadBanks()
        this.loadInsuranceType()
        this.loadInsuranceCompanies()
        this.loadCarParking()
        this.loadLicienseStatus()
        this.loadChangeBookStatus()
        this.loadCarAdaptStatus()

    }


  loadChangeBookStatus() {
    axios.get(APIURL + '/change_book_status/')
      .then(res => {
        this.setState({change_book_status: res.data})
      }).catch(error => {
      this.setState({change_book_status: []})
    })
  }

  // car_adapt_status
  loadCarAdaptStatus() {
    axios.get(APIURL + '/car_adapt_status/')
      .then(res => {
        this.setState({car_adapt_status: res.data})
      }).catch(error => {
      this.setState({car_adapt_status: []})
    })
  }

  loadFormControls() {
    let tableName = "car";
    axios.get(APIURL + '/form_controls/'+tableName)
      .then(res => {
        this.setState({form_controls: res.data});
      }).catch(error => {
      this.setState({form_controls: []})
    })
  }

  loadCarParking() {
    let url = APIURL+'/car_parking'
    axios.get(url).then(res => {
      let data = res.data
      this.setState({
        car_parking_list: data
      })
    })
  }

  makeCarName () {
    let brand = this.state.tmp_car_brand === '' ? this.state.car_brand_name : this.state.tmp_car_brand
    let model = this.state.tmp_car_model === '' ? this.state.car_model_name : this.state.tmp_car_model
    let sub_model = this.state.tmp_car_sub_model === '' ? this.state.car_sub_model_name : this.state.tmp_car_sub_model
    let year = this.state.tmp_car_year === '' ? this.state.car_year_name : this.state.tmp_car_year
    // detal
    let color_name = this.state.tmp_car_color === '' ? this.state.car_color_name : this.state.tmp_car_color
    let gear_name = this.state.tmp_car_gear === '' ? this.state.car_gear_name : this.state.tmp_car_gear
    //
    let car_name = ''

    if (sub_model !== '') {
      if (sub_model.includes(model)) {
        car_name = brand + ' ' + sub_model
      } else {
        car_name = brand + ' ' + model + ' ' + sub_model
      }
    } else {
      car_name = brand + ' ' + model + ' ' + sub_model
    }

    if (year !== '') {
      car_name += ' ปี ' + year
    }

    let car_detail_new = car_name
    if (color_name !== '') {
      car_detail_new += ' ' + color_name
    }

    if (gear_name !== '') {
      car_detail_new += ' ' + gear_name
    }

    this.setState({
      car_name_new: car_name,
      car_detail_new: car_detail_new
    })
  }

    render(){
        //const { history } = this.props
        //var that = this
        let car_status = this.state.car_status
        let isDateFill = this.props.isDateFill

      let car_brand_list = this.state.car_brands.map((car_brand,index)=>{
            return( <option key={index} value={car_brand.car_brand_id}>{car_brand.car_brand_name}</option> )
        })
      let car_brand_model = this.state.car_models.map((car_model,index)=>{
            return(
                <option key={index} value={car_model.car_model_id}>{car_model.car_model_name}</option>
            )
        })
      let car_sub_model = this.state.car_sub_models.map((car_sub_model,index)=>{
            return(
                <option key={index} value={car_sub_model.car_sub_model_id}>{car_sub_model.car_sub_model_name}</option>
            )
        })


      let car_year_list = this.state.car_years.map((car_year,index)=>{

          return (
            <option key={index} value={car_year.car_year_id}>{car_year.car_year_name}</option>
          )

        })


        let car_color_list = this.state.car_colors.map((car_color,index)=>{
          return(
            <option key={index} value={car_color.car_color_id}>{car_color.car_color_name}</option>
          )
        })

        let car_gear_list = this.state.car_gears.map((car_gear,index)=>{
            return(
                <option key={index} value={car_gear.car_gear_id}>{car_gear.car_gear_name}</option>
            )
        })

        let car_status_list = this.state.car_status.map((car_status,index)=>{
            return(
                <option key={index} value={car_status.car_status_id}>{car_status.car_status_name}</option>
            )
        })

        let province_new_list = this.state.province.map((province,index)=>{
            return(
                <option key={index} value={province.province_id}>{province.province_name}</option>
            )
        })

        let car_branch_list = this.state.branch.map((branch,index)=>{
          return (
            <option key={index} value={branch.branch_id}>{branch.branch_name} </option>
          )
        })

        let car_hassock_list = this.state.car_hassock.map((car_hassock,index)=>{
          return (
            <option key={index} value={car_hassock.car_hassock_id}>{car_hassock.car_hassock_name}</option>
          )
        })

        let car_system_drive_list = this.state.car_system_drive.map((car_system_drive,index)=>{
          return (
            <option key={index}
                    value={car_system_drive.car_system_drive_id}>{car_system_drive.car_system_drive_name}</option>
          )
        })

        let car_type_engine_list = this.state.car_type_engine.map((car_type_engine,index)=>{
          return (
            <option key={index}
                    value={car_type_engine.car_type_engine_id}>{car_type_engine.car_type_engine_name}</option>
          )
        })

        let car_type_list = this.state.car_type.map((car_type,index)=>{
          return (
            <option key={index} value={car_type.car_type_id}>{car_type.car_type_name}</option>
          )
        })

        let car_structure_list = this.state.car_structure.map((car_structure,index)=>{
          return(
            <option key={index} value={car_structure.car_structure_id}>{car_structure.car_structure_name}</option>
          )
        })

        //const car= this.props.car
      let car_finance_list = this.state.banks.map((bank, index) => {
        if (bank.bank_id > 2) {
          return (
            <option key={index} value={bank.bank_id}>{index-1}.{bank.bank_name}</option>
          )
        } else {
          return null
        }
      });

      let car_floorplan_finance_list = this.state.banks.map((bank, index) => {
        if(port === 9005) {
          if (bank.bank_id > 2 && (bank.bank_name.includes('ทิสโก้') || bank.bank_name.includes('กรุงศรี'))) {
            return (
              <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
            )
          } else {
            return null
          }
        }else{
          if (bank.bank_id > 2 ) {
            return (
              <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
            )
          } else {
            return null
          }
        }
      });

      let insurance_types = this.state.insurance_types.map((insurance_type, index) => {
        return (
          <option key={index} value={insurance_type.insurance_type_id}>{insurance_type.insurance_type_name}</option>
        )
      })

      let insurance_companies = this.state.insurance_companies.map((insurance_company, index) => {
        return (
          <option key={index} value={insurance_company.insurance_company_id}>{insurance_company.insurance_company_name}</option>
        )
      })

      let compulsory_insurance_company = this.state.insurance_companies.map((insurance_company, index) => {
        return (
          <option key={index} value={insurance_company.insurance_company_id}>{insurance_company.insurance_company_name}</option>
        )
      })

      let carParkingOptions = this.state.car_parking_list.map((parking, index) => {
        return <option value={parking.car_parking_name} key={index}> {parking.car_parking_name} </option>
      })

        let liciense_status = this.state.liciense_status.map((liciense_status, index) => {
            return (
                <option key={index} value={liciense_status.liciense_status_id}>{liciense_status.liciense_status_name}</option>
            )
        })

      let change_book_status_option = this.state.change_book_status.map((change_book_status, index) => {
        return (
          <option key={index} value={change_book_status.change_book_status_id}>{change_book_status.change_book_status_name}</option>
        )
      })

      let car_adapt_status_option = this.state.car_adapt_status.map((car_adapt_status, index) => {
        return (
          <option key={index} value={car_adapt_status.car_adapt_status_id}>{car_adapt_status.car_adapt_status_name}</option>
        )
      })

      // let car_parking_list = this.state.car_parking_list

        return(

                    <form action="" method="post" onSubmit={this.handleOnSubmitCarEditGeneral}>
                        <div className="row" >
                            <div className="col-sm-12">
                                <div className="card card-accent-warning">
                                    <div className="card-header card-edit">
                                        <strong className="text-title">แก้ไขข้อมูลทั่วไป</strong>
                                    </div>

                                    <div className="card-block">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="row" style={styles.bottomLine}>


                                                    <div className="form-group col-sm-5">
                                                        <label htmlFor="branch">สาขาที่รถอยู่</label>
                                                        <select className="form-control"
                                                                id="branch_id"
                                                                ref="branch_id"
                                                                onChange={this.onInputChange}
                                                                value={this.state.branch_id}
                                                        >
                                                            {car_branch_list}
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-sm-3">

                                                      <BDatePicker
                                                        isDateFill={isDateFill}
                                                        name={'car_date_in'}
                                                        value={this.state.car_date_in}
                                                        label={'วันที่ซื้อรถเข้ามา'}
                                                        onUpdateDate={(buddhist_date, christ_date) => {
                                                          this.setState({
                                                            car_date_in: christ_date
                                                          })
                                                        }}
                                                      />

                                                      {/*  <label htmlFor="car_date_in">วันที่ซื้อรถเข้ามา</label><br/>
                                                      <DatePicker
                                                        type="text"
                                                        className="form-control col-sm-12"
                                                        name="car_date_in"
                                                        placeholder=""
                                                        selected={this.state.car_date_in}
                                                        value={this.state.car_date_in}
                                                        onChange={this.handleCarDateIn}
                                                        dateFormat="yyyy-MM-dd"
                                                      />*/}
                                                        {/*
                                                         <MaskedInput
                                                            mask="11-11-1111"
                                                            name="car_date_in"
                                                            placeholder="dd-mm-yyyy"
                                                            onChange={this.onMaskedEditChange1}
                                                            className="form-control"
                                                            ref = "car_date_in"
                                                            id = "car_date_in"
                                                            value={this.state.startcardatein_bb}
                                                        />
                                                        <input name="car_date_in_text"
                                                               ref="car_date_in_text"
                                                               value={this.state.startDateEdit1}
                                                               type="hidden"
                                                               className="form-control hidden"
                                                               id="car_date_in_text"

                                                        />*/}
                                                        {/*<DatePicker
                                                            //selected={this.state.startDateEdit1}
                                                            //dateFormat="yyyy-MM-DD"
                                                            //type="text"
                                                            className="form-control hidden"
                                                            ref="car_date_in"
                                                            id="car_date_in"
                                                            name="car_date_in"
                                                            placeholder=""
                                                            value={this.state.startDateEdit1}
                                                            //onChange={this.handleDateChange1}
                                                        />*/}
                                                    </div>

                                                  <div className="form-group col-sm-4">
                                                    <label htmlFor="car_date_in">ซื้อรถจาก</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           id="buy_car_from"
                                                           name="buy_car_from"
                                                           ref="buy_car_from"
                                                           value={this.state.buy_car_from}
                                                           placeholder="ซื้อรถจาก"
                                                           onChange={(e) => {
                                                             this.setState({
                                                               buy_car_from: e.target.value
                                                             })
                                                           }}
                                                    />
                                                  </div>

                                                  <div className="form-group col-sm-6">
                                                    <label htmlFor="branch">ตำแหน่งรถจอด</label>
                                                    <select className="form-control"
                                                            id="park_location"
                                                            ref="park_location"
                                                            onChange={this.onInputChange}
                                                            value={this.state.park_location}
                                                    >
                                                      {carParkingOptions}
                                                    </select>
                                                  </div>


                                                  <div className="form-group col-sm-3">
                                                    <label htmlFor="car_grade">เกรดรถ</label>
                                                    <select className="form-control"
                                                            id="car_grade"
                                                            name="car_grade"
                                                            ref="car_grade"
                                                            onChange={this.onInputChange}
                                                            value={this.state.car_grade}
                                                    >
                                                      <option value="">-- กรุณาเลือก --</option>
                                                      <option value="A">A</option>
                                                      <option value="B">B</option>
                                                      <option value="C">C</option>
                                                      <option value="D">D</option>
                                                      <option value="E">E</option>
                                                    </select>
                                                  </div>

                                                  <div className="form-group col-sm-3">

                                                    <BDatePicker
                                                      isDateFill={isDateFill}
                                                      name={'warantee_date_end'}
                                                      value={this.state.warantee_date_end}
                                                      label={'วันที่ warantee สิ้นสุด'}
                                                      onUpdateDate={(buddhist_date, christ_date) => {
                                                        this.setState({
                                                          warantee_date_end: christ_date
                                                        })
                                                      }}
                                                    />
                                                  </div>

                                                    {/*{port === 9038 || port === 9039 ?
                                                    <div className="form-group col-sm-12">
                                                        <label htmlFor="car-number">รหัสสินค้า / รหัสรถ</label>
                                                        <input
                                                               type="text"
                                                               className="form-control"
                                                               id="code_item"
                                                               name="code_item"
                                                               value={this.state.code_item}
                                                               onChange={this.onInputChange}
                                                        />
                                                    </div>
                                                        : null
                                                    }
*/}

                                                  <div className="form-group col-sm-12">
                                                    <label htmlFor="car-number">รหัสสินค้า / รหัสรถ</label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id="code_item"
                                                      name="code_item"
                                                      value={this.state.code_item}
                                                      onChange={this.onInputChange}
                                                    />
                                                  </div>

                                                    <div className="form-group col-sm-12">
                                                        <label htmlFor="car_name">ชื่อรถ</label>
                                                        <input type="text" className="form-control"
                                                               ref="car_name"
                                                               id="car_name"
                                                               name="car_name"
                                                               placeholder=""
                                                               value={ this.state.car_name_new === '' ? this.state.car_name : this.state.car_name_new }
                                                               onChange={(e)=> {
                                                                 this.setState({
                                                                   car_name_new: e.target.value
                                                                 })
                                                               }}
                                                        />
                                                    </div>

                                                    <div className="form-group col-sm-12">
                                                        <label htmlFor="detail">รายละเอียดรถ</label>
                                                        <input type="text" className="form-control"
                                                               ref="car_detail"
                                                               id="car_detail"
                                                               name="car_detail"
                                                               placeholder=""
                                                               value={this.state.car_detail_new === '' ? this.state.car_detail : this.state.car_detail_new  }
                                                               onChange={(e)=> {
                                                                 this.setState({
                                                                   car_detail_new: e.target.value
                                                                 })
                                                               }}
                                                        />
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="status">สถานะรถ</label>
                                                        <select className="form-control"
                                                                id="car_status_id"
                                                                ref="car_status_id"
                                                                value={this.state.car_status_id}
                                                                onChange={this.onInputChange}
                                                        >
                                                            {car_status_list}
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="car_date_regis">วันที่จดทะเบียน (วัน-เดือน-ปี พ.ศ.)</label>
                                                        <MaskedInput
                                                            mask="11-11-1111"
                                                            name="car_date_regis"
                                                            placeholder="dd-mm-yyyy"
                                                            onChange={this.onMaskedEditChange3}
                                                            className="form-control"
                                                            ref = "car_date_regis"
                                                            id = "car_date_regis"
                                                            value={this.state.startcardateregis_bb}
                                                        />
                                                        {/*<DatePicker
                                                            selected={this.state.startDateEdit3}
                                                            dateFormat="yyyy-MM-DD"
                                                            type="text"
                                                            className="form-control hidden"
                                                            ref="car_date_regis"
                                                            id="car_date_regis"
                                                            name="car_date_regis"
                                                            placeholder=""
                                                            value={this.state.car_date_regis}
                                                            onChange={this.handleDateChange3}
                                                        />*/}
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="brand">ยี่ห้อ</label>
                                                        <select className="form-control"
                                                                id="car_brand_id"
                                                                ref="car_brand_id"
                                                                onChange={this.handleCarBrandChange.bind(this)}
                                                                value={this.state.car_brand_id}
                                                        >
                                                            {car_brand_list}
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="model">รุ่นรถ</label>
                                                        <select className="form-control"
                                                                id="car_model_id"
                                                                name="car_model_id"
                                                                ref="car_model_id"
                                                                onChange={this.handleCarModelChange.bind(this)}
                                                                value={this.state.car_model_id}
                                                        >
                                                            {car_brand_model}
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="model-sub">รุ่นย่อย</label>
                                                        <select className="form-control"
                                                                id="car_sub_model_id"
                                                                name="car_sub_model_id"
                                                                ref="car_sub_model_id"
                                                                value={this.state.car_sub_model_id}
                                                                onChange={this.handleCarSubModelChange}
                                                        >
                                                            {car_sub_model}
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="gear">ระบบเกียร์รถ</label>
                                                        <select className="form-control"
                                                                id="car_gear_id"
                                                                ref="car_gear_id"
                                                                value={this.state.car_gear_id}
                                                                onChange={(e) => {
                                                                  let car_gear_id = e.target.value
                                                                  let tmp_car_gear = e.target.options[e.target.selectedIndex].text
                                                                  this.setState({
                                                                    car_gear_id: car_gear_id,
                                                                    tmp_car_gear: tmp_car_gear
                                                                  }, () => this.makeCarName() )
                                                                }}
                                                        >
                                                            {car_gear_list}
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="color">สี</label>
                                                        <select className="form-control"
                                                                id="car_color_id"
                                                                ref="car_color_id"
                                                                value={this.state.car_color_id}
                                                                onChange={(e) => {
                                                                  let car_color_id = e.target.value
                                                                  let tmp_car_color = e.target.options[e.target.selectedIndex].text
                                                                  this.setState({
                                                                    car_color_id: car_color_id,
                                                                    tmp_car_color: tmp_car_color
                                                                  }, () => this.makeCarName() )
                                                                }}
                                                        >
                                                            {car_color_list}
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="year">รถปี ค.ศ.</label>
                                                        <select className="form-control"
                                                                id="car_year_id"
                                                                name="car_year_id"
                                                                ref="car_year_id"
                                                                value={this.state.car_year_id}
                                                                onChange={this.handleCarYearChange}
                                                        >
                                                            {car_year_list}
                                                        </select>
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="license-plate-new">ป้ายทะเบียนใหม่ *</label>
                                                        <input type="text" className="form-control"
                                                               id="car_license_plate_new"
                                                               name="car_license_plate_new"
                                                               placeholder=""
                                                               value={this.state.car_license_plate_new}
                                                               onChange={this.onInputChange}
                                                        />
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="province">จังหวัด</label>
                                                        <select className="form-control"
                                                                id="province_new_id"
                                                                ref="province_new_id"
                                                                value={this.state.province_new_id}
                                                                onChange={this.onInputChange}
                                                        >
                                                            {province_new_list}
                                                        </select>
                                                    </div>

                                                    {/*<div className="form-group col-sm-6">
                                                        <label htmlFor="miles">เลขไมล์ปัจจุบัน</label>
                                                        <input type="text"
                                                               className="form-control"
                                                               id="car_miles"
                                                               name="car_miles"
                                                               placeholder="เลขไมล์ปัจจุบัน"
                                                               value={this.state.car_miles}
                                                               onChange={this.onInputChange}
                                                        />
                                                    </div>*/}

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="car-number">เลขตัวรถ</label>
                                                        <input style={styles.toUpper}
                                                               type="text"
                                                               className="form-control"
                                                               id="car_number"
                                                               name="car_number"
                                                               value={this.state.car_number}
                                                               onChange={this.onInputChange}
                                                        />
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="num-engines">เลขเครื่องยนต์</label>
                                                        <input style={styles.toUpper}
                                                               type="text"
                                                               className="form-control"
                                                               id="car_num_engines"
                                                               name="car_num_engines"
                                                               value={this.state.car_num_engines}
                                                               onChange={this.onInputChange}
                                                        />
                                                    </div>

                                                    <div
                                                        className={
                                                            this.state.form_controls.length > 0 ?
                                                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                                :  "hidden"
                                                        }
                                                    >
                                                        <label htmlFor="type">ประเภท</label>
                                                        <select className="form-control"
                                                                id="car_type_id"
                                                                ref="car_type_id"
                                                                value={this.state.car_type_id}
                                                                onChange={this.onInputChange}
                                                        >
                                                            {car_type_list}
                                                        </select>
                                                    </div>

                                                    <div
                                                        className={
                                                            this.state.form_controls.length > 0 ?
                                                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                                :  "hidden"
                                                        }
                                                    >
                                                        <label htmlFor="structure">ลักษณะ</label>
                                                        <select className="form-control"
                                                                id="car_structure_id"
                                                                ref="car_structure_id"
                                                                value={this.state.car_structure_id}
                                                                onChange={this.onInputChange}
                                                        >
                                                            {car_structure_list}
                                                        </select>
                                                    </div>

                                                    <div
                                                        className={
                                                            this.state.form_controls.length > 0 ?
                                                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                                :  "hidden"
                                                        }
                                                    >
                                                        <label htmlFor="hassock">เบาะรถ</label>
                                                        <select className="form-control"
                                                                id="car_hassock_id"
                                                                ref="car_hassock_id"
                                                                value={this.state.car_hassock_id}
                                                                onChange={this.onInputChange}
                                                        >
                                                            {car_hassock_list}
                                                        </select>
                                                    </div>

                                                    <div
                                                      className={
                                                        this.state.form_controls.length > 0 ?
                                                          (this.state.form_controls.filter(form_control => form_control.field_name === 'car_system_drive_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                          :  "hidden"
                                                      }
                                                    >
                                                        <label htmlFor="system-drive">ระบบขับเคลื่อน</label>
                                                        <select className="form-control"
                                                                id="car_system_drive_id"
                                                                ref="car_system_drive_id"
                                                                value={this.state.car_system_drive_id}
                                                                onChange={this.onInputChange}
                                                        >
                                                            {car_system_drive_list}
                                                        </select>
                                                    </div>

                                                    <div
                                                         className={
                                                           this.state.form_controls.length > 0 ?
                                                             (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_engine_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                             :  "hidden"
                                                         }
                                                    >
                                                        <label htmlFor="type-engine">ประเภทเครื่องยนต์</label>
                                                        <select className="form-control"
                                                                id="car_type_engine_id"
                                                                ref="car_type_engine_id"
                                                                value={this.state.car_type_engine_id}
                                                                onChange={this.onInputChange}
                                                        >
                                                            {car_type_engine_list}
                                                        </select>
                                                    </div>

                                                    <div
                                                         className={
                                                           this.state.form_controls.length > 0 ?
                                                             (this.state.form_controls.filter(form_control => form_control.field_name === 'car_brand_engine' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                             :  "hidden"
                                                         }
                                                    >
                                                        <label htmlFor="brand-engine">ยี่ห้อเครื่องยนต์</label>
                                                        <input type="text"
                                                               className="form-control"
                                                               id="car_brand_engine"
                                                               name="car_brand_engine"
                                                               value={this.state.car_brand_engine}
                                                               onChange={this.onInputChange}
                                                        />
                                                    </div>

                                                    <div
                                                         className={
                                                           this.state.form_controls.length > 0 ?
                                                             (this.state.form_controls.filter(form_control => form_control.field_name === 'car_gas_number' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                             :  "hidden"
                                                         }
                                                    >
                                                        <label htmlFor="car_gas_number">เลขถังแก๊ส</label>
                                                        <input type="text"
                                                               className="form-control"
                                                               id="car_gas_number"
                                                               name="car_gas_number"
                                                               value={this.state.car_gas_number}
                                                               onChange={this.onInputChange}
                                                        />
                                                    </div>

                                                    <div
                                                         className={
                                                           this.state.form_controls.length > 0 ?
                                                             (this.state.form_controls.filter(form_control => form_control.field_name === 'car_weight' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                             :  "hidden"
                                                         }
                                                    >
                                                        <label htmlFor="weight">น้ำหนักรถ (กิโลกรัม)</label>
                                                        <input type="text" className="form-control"
                                                               id="car_weight"
                                                               name="car_weight"
                                                               value={this.state.car_weight}
                                                               onChange={this.onInputChange}
                                                        />
                                                    </div>

                                                    <div
                                                      className={
                                                        this.state.form_controls.length > 0 ?
                                                          (this.state.form_controls.filter(form_control => form_control.field_name === 'car_size_engine' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                          :  "hidden"
                                                      }
                                                    >
                                                        <label htmlFor="size-engine">ขนาดเครื่องยนต์ (ซีซี)</label>
                                                        <input type="text" className="form-control"
                                                               id="car_size_engine"
                                                               name="car_size_engine"
                                                               value={this.state.car_size_engine}
                                                               onChange={this.onInputChange}
                                                        />
                                                    </div>

                                                    <div
                                                        className={
                                                            this.state.form_controls.length > 0 ?
                                                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                                :  "hidden"
                                                        }
                                                    >
                                                        <label htmlFor="car_date_tax">วันที่ต่อภาษีรถยนต์ (วัน-เดือน-ปี พ.ศ.)</label>
                                                        <MaskedInput
                                                            mask="11-11-1111"
                                                            name="car_date_tax"
                                                            placeholder="dd-mm-yyyy"
                                                            onChange={this.onMaskedEditChange2}
                                                            className="form-control"
                                                            id = "car_date_tax"
                                                            value={this.state.startcardatetax_bb}
                                                        />
                                                        {/*<DatePicker
                                                                selected={this.state.startDateEdit2}
                                                                dateFormat="yyyy-MM-dd"
                                                                type="text"
                                                                className="form-control hidden"
                                                                ref="car_date_tax"
                                                                id="car_date_tax"
                                                                name="car_date_tax"
                                                                placeholder=""
                                                                value={this.state.car_date_tax}
                                                                onChange={this.handleDateChange2}
                                                        />*/}
                                                    </div>

                                                    <div
                                                        className={
                                                            this.state.form_controls.length > 0 ?
                                                                (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                                :  "hidden"
                                                        }
                                                    >
                                                        <label htmlFor="tax-price">ราคาภาษีรถยนต์ / ปี</label>
                                                        <input type="text" className="form-control"
                                                               id="car_tax"
                                                               name="car_tax"
                                                               value={this.state.car_tax}
                                                               onChange={this.onInputChange}
                                                        />
                                                    </div>

                                                  <div
                                                      className={
                                                          this.state.form_controls.length > 0 ?
                                                              (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                              :  "hidden"
                                                      }
                                                  >
                                                    <label htmlFor="insurance_type_id">ประเภทประกันภัย</label>
                                                    <select className="form-control"
                                                            id="insurance_type_id"
                                                            value={this.state.insurance_type_id}
                                                            onChange={this.onInputChange}
                                                    >
                                                      <option value="0"> --- เลือกประเภทประกันภัย --- </option>
                                                      {insurance_types}
                                                    </select>
                                                  </div>

                                                  <div
                                                      className={
                                                          this.state.form_controls.length > 0 ?
                                                              (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                              :  "hidden"
                                                      }
                                                  >
                                                    <label htmlFor="insurance_company_id">บ.ประกันภัย</label>
                                                    <select className="form-control"
                                                            id="insurance_company_id"
                                                            value={this.state.insurance_company_id}
                                                            onChange={this.onInputChange}
                                                    >
                                                      <option value="0"> --- เลือก บ.ประกันภัย --- </option>
                                                      {insurance_companies}
                                                    </select>
                                                  </div>

                                                  <div
                                                      className={
                                                          this.state.form_controls.length > 0 ?
                                                              (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                              :  "hidden"
                                                      }
                                                  >
                                                      {/*<label htmlFor="receipt_date">วันสิ้นสุดประกัน</label>
                                                      <br/>
                                                      <DatePicker
                                                        type="text"
                                                        className="form-control col-sm-12"
                                                        id="insurance_end_date"
                                                        name="insurance_end_date"
                                                        placeholder=""
                                                        selected={this.state.insurance_end_date || ''}
                                                        value={this.state.insurance_end_date || ''}
                                                        onChange={this.handleInsuranceEndDate}
                                                        dateFormat="yyyy-MM-dd"
                                                      />*/}
                                                    <BDatePicker
                                                      isDateFill={0}
                                                      name={'insurance_end_date'}
                                                      value={this.state.insurance_end_date}
                                                      label={'วันที่สิ้นสุดประกัน '}
                                                      onUpdateDate={(buddhist_date, christ_date) => {
                                                        this.setState({
                                                          insurance_end_date: christ_date
                                                        })
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                              <div className="row" style={styles.bottomLine}>

                                                <div
                                                  className={
                                                    this.state.form_controls.length > 0 ?
                                                      (this.state.form_controls.filter(form_control => form_control.field_name === 'car_miles_center' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                                      :  "hidden"
                                                  }
                                                >
                                                  <label htmlFor="car_miles_center">ไมล์ศูนย์ </label>
                                                  <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    placeholder="ไมล์ศูนย์"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1}
                                                    size={10}
                                                    value={this.state.car_miles_center}
                                                    allowNegative={false}
                                                    onValueChange={(values) => {
                                                      const {value} = values
                                                      this.setState({car_miles_center: value})

                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  className={
                                                    this.state.form_controls.length > 0 ?
                                                      (this.state.form_controls.filter(form_control => form_control.field_name === 'car_miles_center_date' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                                      :  "hidden"
                                                  }
                                                >

                                                  <BDatePicker
                                                    isDateFill={isDateFill}
                                                    name={'car_miles_center_date'}
                                                    value={this.state.car_miles_center_date}
                                                    label={'วันเช็คไมล์ศูนย์'}
                                                    onUpdateDate={(buddhist_date, christ_date) => {
                                                      this.setState({
                                                        car_miles_center_date: christ_date
                                                      })
                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  className="form-group col-sm-3"
                                                >
                                                  <label htmlFor="miles">เลขไมล์ปัจจุบัน (ไมล์หน้ารถ) </label>
                                                  <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={true}
                                                    placeholder="เลขไมล์ปัจจุบัน (ไมล์หน้ารถ)"
                                                    min={0}
                                                    max={1000000000}
                                                    step={1}
                                                    size={10}
                                                    allowNegative={false}
                                                    value={this.state.car_miles}
                                                    onValueChange={(values) => {
                                                      const {value} = values
                                                      this.setState({car_miles: value})

                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  className={
                                                    this.state.form_controls.length > 0 ?
                                                      (this.state.form_controls.filter(form_control => form_control.field_name === 'car_miles_status' ))[0].visibility===0 ? "hidden" : "form-group col-sm-3"
                                                      :  "hidden"
                                                  }
                                                >
                                                  <label htmlFor="car_miles_status">สถานะไมล์</label>
                                                  <select className="form-control"
                                                          id="car_miles_status"
                                                          name="car_miles_status"
                                                          value={this.state.car_miles_status}
                                                          onChange={(e) => {
                                                            let val = e.target.value;
                                                            this.setState({
                                                              car_miles_status: val
                                                            })
                                                          }}
                                                  >
                                                    <option value="">-- กรุณาเลือก --</option>
                                                    <option value="ไมล์แท้">ไมล์แท้</option>
                                                    <option value="ไมล์แก้ไข">ไมล์แก้ไข</option>
                                                  </select>
                                                </div>

                                              </div>

                                              <div className="row" style={styles.bottomLine}>
                                                <div
                                                    className={
                                                        this.state.form_controls.length > 0 ?
                                                            (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                            :  "hidden"
                                                    }
                                                >
                                                  <label htmlFor="deposit_registration_id">พรบ.</label>
                                                  <select className="form-control"
                                                          id="compulsory_insurance"
                                                          value={this.state.compulsory_insurance}
                                                          onChange={this.onInputChange}
                                                  >
                                                    <option value="">-- กรุณาเลือก --</option>
                                                    <option value="มี">มี</option>
                                                    <option value="ไม่มี">ไม่มี</option>
                                                  </select>
                                                </div>

                                                <div
                                                    className={
                                                        this.state.form_controls.length > 0 ?
                                                            (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                            :  "hidden"
                                                    }
                                                >
                                                  <label htmlFor="insurance_company_id">บ.พรบ</label>
                                                  <select className="form-control"
                                                          id="compulsory_insurance_company"
                                                          ref="compulsory_insurance_company"
                                                          value={this.state.compulsory_insurance_company}
                                                          onChange={this.onInputChange}
                                                  >
                                                    <option value="0"> --- เลือก บ.พรบ --- </option>
                                                    {compulsory_insurance_company}
                                                  </select>
                                                </div>

                                                <div className="form-group col-sm-6">

                                                  <BDatePicker
                                                    isDateFill={isDateFill}
                                                    name={'compulsory_insurance_end_date'}
                                                    value={this.state.compulsory_insurance_end_date}
                                                    label={'วันที่สิ้นสุด พรบ.'}
                                                    onUpdateDate={(buddhist_date, christ_date) => {
                                                      this.setState({
                                                        compulsory_insurance_end_date: christ_date
                                                      })
                                                    }}
                                                  />

                                                </div>

                                                <div
                                                    className={
                                                        this.state.form_controls.length > 0 ?
                                                            (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                            :  "hidden"
                                                    }
                                                >
                                                  <label htmlFor="car_spare_key">กุญแจสำรอง</label>
                                                  <select className="form-control"
                                                          id="car_spare_key"
                                                          value={this.state.car_spare_key}
                                                          onChange={this.onInputChange}
                                                  >
                                                    <option value="">-- กรุณาเลือก --</option>
                                                    <option value="มี">มี</option>
                                                    <option value="ไม่มี">ไม่มี</option>
                                                  </select>
                                                </div>

                                                <div
                                                    className={
                                                        this.state.form_controls.length > 0 ?
                                                            (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                            :  "hidden"
                                                    }
                                                >
                                                  <label htmlFor="car_manual">คู่มือรถ</label>
                                                  <select className="form-control"
                                                          id="car_manual"
                                                          value={this.state.car_manual}
                                                          onChange={this.onInputChange}
                                                  >
                                                    <option value="">-- กรุณาเลือก --</option>
                                                    <option value="มี">มี</option>
                                                    <option value="ไม่มี">ไม่มี</option>
                                                  </select>

                                                </div>

                                                <div
                                                  className={
                                                    this.state.form_controls.length > 0 ?
                                                      (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                      :  "hidden"
                                                  }
                                                >
                                                  <label htmlFor="car_book_service">คู่มือบริการ (Book Service)</label>
                                                  <select className="form-control"
                                                          name="car_book_service"
                                                          value={this.state.car_book_service}
                                                          onChange={this.handleOnChange}
                                                  >
                                                    <option value="">-- กรุณาเลือก --</option>
                                                    <option value="มี">มี</option>
                                                    <option value="ไม่มี">ไม่มี</option>
                                                  </select>
                                                </div>

                                              </div>

                                              <div className="row" style={styles.bottomLine}>
                                                <div className="form-group col-sm-6">
                                                  <label htmlFor="liciense_status_id">สถานะเล่มทะเบียน</label>
                                                  <select className="form-control"
                                                          id="liciense_status_id"
                                                          ref="liciense_status_id"
                                                          value={this.state.liciense_status_id}
                                                          onChange={this.onInputChange}
                                                  >
                                                    {liciense_status}
                                                  </select>
                                                </div>

                                                <div className="form-group col-sm-6">
                                                  <label htmlFor="change_book_status_id">การเปลี่ยนเล่ม</label>
                                                  <select
                                                    className="form-control"
                                                    id="change_book_status_id"
                                                    name="change_book_status_id"
                                                    ref="change_book_status_id"
                                                    value={this.state.change_book_status_id}
                                                    onChange={this.onInputChange}
                                                  >
                                                    {change_book_status_option}
                                                  </select>
                                                </div>

                                                <div className="form-group col-sm-6">
                                                  <label htmlFor="car_adapt_status_id">การดัดแปลง</label>
                                                  <select
                                                    className="form-control"
                                                    id="car_adapt_status_id"
                                                    name="car_adapt_status_id"
                                                    ref="car_adapt_status_id"
                                                    value={this.state.car_adapt_status_id}
                                                    onChange={this.onInputChange}
                                                  >
                                                    {car_adapt_status_option}
                                                  </select>
                                                </div>

                                              </div>


                                              <div className="row" style={styles.bottomLine}>



                                                  <div className="form-group col-sm-6">
                                                    <label htmlFor="finance_id">ไฟแนนซ์รอเล่มทะเบียน</label>
                                                    <select className="form-control"
                                                            id="finance_id"
                                                            value={this.state.finance_id}
                                                            onChange={this.onInputChange}
                                                    >
                                                      <option value="0"> --- เลือกไฟแนนซ์ --- </option>
                                                      {car_finance_list}
                                                    </select>
                                                  </div>

                                                <div
                                                  className={
                                                    this.state.form_controls.length > 0 ?
                                                      (this.state.form_controls.filter(form_control => form_control.field_name === 'car_type_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                      :  "hidden"
                                                  }
                                                >
                                                  <label htmlFor="deposit_registration_id">มัดจำเล่มทะเบียน</label>
                                                  <select className="form-control"
                                                          id="deposit_registration_id"
                                                          value={this.state.deposit_registration_id}
                                                          onChange={this.onInputChange}
                                                  >
                                                    <option value="0">-- เลือกมัดจำเล่มทะเบียน --</option>
                                                    <option value="1">มัดจำเล่ม</option>
                                                    <option value="2">ไม่มัดจำ</option>
                                                  </select>
                                                </div>

                                                  <div className="form-group col-sm-6">
                                                    {/*<label htmlFor="receipt_date">วันที่รับเล่ม</label>
                                                    <br/>
                                                    <DatePicker
                                                      type="text"
                                                      className="form-control col-sm-12"
                                                      name="receipt_date"
                                                      placeholder=""
                                                      selected={this.state.receipt_date || ''}
                                                      value={this.state.receipt_date || ''}
                                                      onChange={this.handleReceiptDate}
                                                      dateFormat="yyyy-MM-dd"
                                                    />*/}
                                                    <BDatePicker
                                                      isDateFill={0}
                                                      name={'receipt_date'}
                                                      value={this.state.receipt_date}
                                                      label={'วันที่รับเล่ม '}
                                                      onUpdateDate={(buddhist_date, christ_date) => {
                                                        this.setState({
                                                          receipt_date: christ_date
                                                        })
                                                      }}
                                                    />
                                                  </div>

                                                  <div
                                                      className={
                                                          this.state.form_controls.length > 0 ?
                                                              (this.state.form_controls.filter(form_control => form_control.field_name === 'floorplan_credit' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                              :  "hidden"
                                                      }
                                                  >
                                                    <label htmlFor="floorplan_credit">ยอดเงินฟลอร์แพลน(จำนวนเงิน)</label>
                                                    <input type="text"
                                                           className="form-control hidden "
                                                           name="floorplan_credit"
                                                           placeholder=""
                                                           ref="floorplan_credit"
                                                           value={this.state.floorplan_credit}
                                                           onChange={() => {}}
                                                    />
                                                    <NumberFormat
                                                      className="form-control"
                                                      thousandSeparator={true}
                                                      prefix={'฿'}
                                                      placeholder="ยอดเงินฟลอร์แพลน"
                                                      min={0}
                                                      max={1000000000}
                                                      step={1000}
                                                      size={10}
                                                      allowNegative={false}
                                                      value={this.state.floorplan_credit}
                                                      onValueChange={(values) => {
                                                        const {value} = values
                                                        this.setState({floorplan_credit: value})
                                                      }}
                                                    />
                                                  </div>

                                                  <div
                                                      className={
                                                          this.state.form_controls.length > 0 ?
                                                              (this.state.form_controls.filter(form_control => form_control.field_name === 'finance_floorplan_id' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                              :  "hidden"
                                                      }
                                                  >
                                                    <label htmlFor="finance_floorplan_id">ไฟแนนซ์ฟลอร์แพลน</label>
                                                    <select className="form-control"
                                                            id="finance_floorplan_id"
                                                            value={this.state.finance_floorplan_id}
                                                            onChange={this.onInputChange}
                                                    >
                                                      <option value="0"> --- เลือกไฟแนนซ์ฟลอร์แพลน --- </option>
                                                      {car_floorplan_finance_list}
                                                    </select>
                                                  </div>

                                                    <div
                                                        className={
                                                            this.state.form_controls.length > 0 ?
                                                                (this.state.form_controls.filter(form_control => form_control.field_name === 'floorplan_start_date' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                                :  "hidden"
                                                        }
                                                    >
                                                        {/*<label htmlFor="floorplan_start_date">วันเริ่มเล่มฟลอร์แพลน</label>
                                                        <br/>
                                                        <DatePicker
                                                            type="text"
                                                            className="form-control col-sm-12"
                                                            name="floorplan_start_date"
                                                            placeholder=""
                                                            selected={this.state.floorplan_start_date || ''}
                                                            value={this.state.floorplan_start_date || ''}
                                                            onChange={this.handleFloorplanStartDate}
                                                            dateFormat="yyyy-MM-dd"
                                                        />*/}

                                                       <BDatePicker
                                                        isDateFill={0}
                                                        name={'floorplan_start_date'}
                                                        value={this.state.floorplan_start_date}
                                                        label={'วันเริ่มเล่มฟลอร์แพลน '}
                                                        onUpdateDate={(buddhist_date, christ_date) => {
                                                          this.setState({
                                                            floorplan_start_date: christ_date
                                                          })
                                                        }}
                                                      />

                                                    </div>

                                                  <div
                                                      className={
                                                          this.state.form_controls.length > 0 ?
                                                              (this.state.form_controls.filter(form_control => form_control.field_name === 'floorplan_due_date' ))[0].visibility===0 ? "hidden" : "form-group col-sm-6"
                                                              :  "hidden"
                                                      }
                                                  >
                                                    {/*<label htmlFor="floorplan_due_date">วันครบกำหนดเล่มฟลอร์แพลน</label>
                                                    <br/>
                                                    <DatePicker
                                                      type="text"
                                                      className="form-control col-sm-12"
                                                      name="floorplan_due_date"
                                                      placeholder=""
                                                      selected={this.state.floorplan_due_date || ''}
                                                      value={this.state.floorplan_due_date || ''}
                                                      onChange={this.handleFloorplanDueDate}
                                                      dateFormat="yyyy-MM-dd"
                                                    />*/}

                                                    <BDatePicker
                                                      isDateFill={0}
                                                      name={'floorplan_due_date'}
                                                      value={this.state.floorplan_due_date}
                                                      label={'วันครบกำหนดฟลอร์แพลน '}
                                                      onUpdateDate={(buddhist_date, christ_date) => {
                                                        this.setState({
                                                          floorplan_due_date: christ_date
                                                        })
                                                      }}
                                                    />

                                                  </div>

                                                    <div
                                                        className={
                                                            this.state.form_controls.length > 0 ?
                                                                (this.state.form_controls.filter(form_control => form_control.field_name === 'floorplan_annual_interest' ))[0].visibility===0 ? "hidden" : "form-group col-sm-4"
                                                                :  "hidden"
                                                        }
                                                    >
                                                        <label htmlFor="floorplan_annual_interest">% ดอกเบี้ยฟลอร์แพลนต่อเดือน</label>
                                                        <input type="text"
                                                               className="form-control hidden "
                                                               name="floorplan_annual_interest"
                                                               placeholder=""
                                                               ref="floorplan_annual_interest"
                                                               value={this.state.floorplan_annual_interest}
                                                               onChange={()=>{}}
                                                            // onChange={this.handleOnChangeNoSet}
                                                        />
                                                        <NumberFormat
                                                            className="form-control"
                                                            thousandSeparator={true}
                                                            suffix={' %'}
                                                            placeholder="% ดอกเบี้ยฟลอร์แพลนต่อเดือน"
                                                            decimalScale={2}
                                                            min={0}
                                                            max={1000000000}
                                                            step={1000}
                                                            size={10}
                                                            allowNegative={false}
                                                            value={this.state.floorplan_annual_interest}
                                                            onValueChange={(values) => {
                                                                const {value} = values
                                                                this.setState({floorplan_annual_interest: value})
                                                            }}
                                                        />
                                                    </div>

                                                </div>

                                              <div className="row" style={styles.bottomLine}>
                                                <div className="form-group col-sm-12">
                                                  <label htmlFor="web_info">ข้อความค่างวดแสดงที่เว็บ</label>
                                                  <input
                                                    type="text" className="form-control"
                                                    name="web_info"
                                                    value={this.state.web_info}
                                                    placeholder="ข้อความค่างวดแสดงที่เว็บ เช่น ดาวน์ 7 บาท / งวด 9,XXX * 84"
                                                    onChange={(e)=> {
                                                      this.setState({
                                                        web_info: e.target.value
                                                      })
                                                    }}
                                                  />
                                                </div>
                                              </div>

                                                <div className="row" style={styles.bottomLine}>
                                                    <div className="form-group col-sm-12">
                                                        <label htmlFor="web_info">หมายเหตุ</label>
                                                        <input
                                                            type="text" className="form-control"
                                                            name="car_explain"
                                                            value={this.state.car_explain}
                                                            placeholder=""
                                                            onChange={(e)=> {
                                                                this.setState({
                                                                    car_explain: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div className="card-footer text-right">
                                        <button type="reset"
                                                className="btn btn-sm btn-danger mr-2"
                                                onClick={this.props.onToggle}
                                        >
                                            <i className="icon-close"/> ยกเลิก
                                        </button>
                                        {
                                            car_status.length > 0 ?
                                                <button type="submit"
                                                        className="btn btn-sm btn-warning">
                                                    <i className="fa fa-pencil"/> แก้ไข
                                                </button>
                                                : null
                                        }

                                    </div>

                                </div>

                            </div>
                        </div>
                    </form>


        )
    }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
    marginTop: 16
  },
  toUpper: {
    textTransform: 'uppercase',
  }
}

export default connect(mapStateToProps)(CarEditGeneral);
