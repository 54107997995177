import React, { Component } from 'react';
import { th } from 'date-fns/locale';
import DataTable from 'react-data-table-component';
import {APIURL, PORT} from "../../config/config";
import axios from "axios";
import {DateUtil} from "../../utils/dateUtil";
import {NumberUtil} from "../../utils/number-util";
import Loading from "../Loading";
import {ExcelUtil} from "../../utils/excelUtil";
import {border, contentBoldStyle, contentStyle} from "../../utils/exceljsStyles";
import {format} from "date-fns";

const port = Number(window.env.PORT);

const customStyles = {
    rows: {
        style: {
            minHeight: '35px', // override the row height
        },
    },
    headCells: {
        style: {
            fontWeight: 'bold',
            paddingLeft: '0px', // override the cell padding for head cells
            paddingRight: '0px',
        }
    },
    cells: {
        style: {
            fontSize: 12,
            paddingLeft: '0px', // override the cell padding for head cells
            paddingRight: '0px',
        }
    }
}

const getPriceListHeader = (id, car_brand_name, carByBrandSum) => {
    let car = {
        car_id: 'H'+id,
        no: '',
        car_brand_name: car_brand_name,
        car_model: carByBrandSum,
        car_year_name: '',
        car_color_name: '',
        car_gear_name: '',
        license_plate_old: '',
        license_plate_new: '',
        car_date_in_month: '',
        car_date_in_text: '',
        is_car_vat: '',
        car_sale_price: '',
        car_spare_key: '',
        car_book_service: '',
        car_miles: '',
        content_type: 'head'
    }
    return car
}

const getPriceListDetail = (id, carByBrand) => {
    let no = id+1
    let car_id = carByBrand.car_id
    let brand_name = carByBrand.car_brand_name
    let car_model_name = carByBrand.car_model_name
    let car_sub_model_name = carByBrand.car_sub_model_name
    let car_year_name = carByBrand.car_year_name
    let car_color_name = carByBrand.car_color_name
    let car_gear_name = carByBrand.car_gear_id === 'a' ? 'AT':'MT'
    let car_license_plate_old = carByBrand.car_license_plate_old
    let province_name_old = carByBrand.province_name_old

    let car_license_plate_new = carByBrand.car_license_plate_new
    let province_name_new = carByBrand.province_name_new

    let license_plate_old = car_license_plate_old+' '+province_name_old
    let license_plate_new = car_license_plate_new+' '+province_name_new
    let car_date_in = carByBrand.car_date_in
    let car_date_in_month = car_date_in !== null && car_date_in !== '0000-00-00' ? DateUtil.carDateInMonth(car_date_in) : '-'
    let car_date_in_text = DateUtil.convertShortMonthYearThai(car_date_in)

    let is_car_vat = carByBrand.person_vat_id === 2 ? 'YES' : 'NO'
    let car_sale_price = PORT === 9043 ? NumberUtil.addCommasZeroInt((carByBrand.car_sale_price/1000)) : NumberUtil.addCommasZeroInt((carByBrand.car_sale_price))

    let car_mile_comma = NumberUtil.addCommasZeroInt(carByBrand.car_miles) //ระยะทางการใช้งาน
    let car_miles = NumberUtil.replaceCarMiles(car_mile_comma)

    // let car_model = car_model_name+'/'+car_sub_model_name
    let car_model = carByBrand.car_model_name_full
    let car_spare_key = carByBrand.car_spare_key === '' ? '-' : carByBrand.car_spare_key
    let car_book_service = carByBrand.car_book_service

    let car = {
        car_id: car_id,
        no: no,
        car_brand_name: brand_name,
        car_model: car_model,
        car_year_name: car_year_name,
        car_color_name: car_color_name,
        car_gear_name: car_gear_name,
        license_plate_old,
        license_plate_new,
        car_date_in_month,
        car_date_in_text,
        is_car_vat,
        car_sale_price,
        car_spare_key,
        car_book_service,
        car_miles,
        content_type: 'detail'
    }
    return car
}

class PriceList extends Component{
    constructor (props) {
        super(props);
        this.state = {
            isLoading: false,
            cars: [],
            car_brand_names: [],
            columns: [
                {
                    name: 'No.',
                    selector: row => row.no,
                    sortable: true,
                    center: true,
                    width:'38px'
                },
                {
                    name: 'ยี่ห้อ',
                    selector: row => row.car_brand_name,
                    sortable: true,
                    width:'120px'
                },
                {
                    name: 'รุ่น/รุ่นย่อย',
                    selector: row => row.car_model,
                    sortable: true,
                    width:'280px'
                },
                {
                    name: 'ปี',
                    selector: row => row.car_year_name,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'สี',
                    selector: row => row.car_color_name,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'เกียร์',
                    selector: row => row.car_gear_name,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'ทะเบียนเก่า',
                    selector: row => row.license_plate_old,
                    sortable: true,
                    width:'120px'
                },
                {
                    name: 'ทะเบียนใหม่',
                    selector: row => row.license_plate_new,
                    sortable: true,
                    width:'120px'
                },
                {
                    name: 'ระยะ(ด.)',
                    selector: row => row.car_date_in_month,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'ซื้อเข้า',
                    selector: row => row.car_date_in_text,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'VAT',
                    selector: row => row.is_car_vat,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'ราคา(K)',
                    selector: row => row.car_sale_price,
                    sortable: true,
                    width:'80px'
                },
                {
                    name: 'Key',
                    selector: row => row.car_spare_key,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'BS',
                    selector: row => row.car_book_service,
                    sortable: true,
                    width:'56px'
                },
                {
                    name: 'Mile',
                    selector: row => row.car_miles,
                    sortable: true,
                    width:'100px'
                },
            ],
            branchs: [],
            branch_id: '',
            branch_name: '',
            countBrandAndGroupHeader: []
        }

        this.loadCarData = this.loadCarData.bind(this);
        this.loadBranchData = this.loadBranchData.bind(this);
        this.exportExcelPriceList = this.exportExcelPriceList.bind(this);
    }

    componentDidMount () {
        this.loadBranchData();
    }

    loadBranchData () {
            // let username = this.state.user.user
            // const is_account_branch = this.state.is_account_branch
            let url = APIURL + '/branch/'
            // if (is_account_branch === 1 ) {
            //     url = APIURL + '/branch/user/'+username
            // }
        // branch_id, branch_name

            axios.get(url)
                .then(res => {
                    let branchs = res.data
                    let index = port === 9043 ? branchs.length - 1 : 0
                    let branch = branchs[index]
                    this.setState({
                        branchs: branchs,
                        branch_id: branch.branch_id,
                        branch_name: branch.branch_name,
                    }, () => {
                        this.loadCarData()
                    });
                }).catch(error => {
                this.setState({ branch: [] })
            })

    }

    loadCarData() {
        let branch_id = this.state.branch_id

        this.setState({
            isLoading: true
        },() => {

            let data = {
                branch_id: branch_id,
                car_status_id: '',
                car_structure_id: '',
                car_gear_id: '',
                car_brand_id: '',
                order_by: '',
                car_color_id: '',
                search_text: '',
                is_star: ''
            }

            let uri = APIURL + '/car/criteria';
            // let uri = APIURL + '/car'
            axios.post(uri, data).then(res => {
                let data = res.data
                let _ = require('lodash');
                let carBrandNameUniq = _.uniqBy(data, 'car_brand_name');
                let carBrandNameSorted = _.orderBy(carBrandNameUniq, ['car_brand_name'], ['asc'])
                let countBrandAndGroupHeader = 0
                let cars = []
                for(let i=0; i < carBrandNameSorted.length  ;i++) {
                    let car_sort = carBrandNameSorted[i]
                    let car_brand_name = car_sort.car_brand_name.toUpperCase()
                    let carByBrandFilters = data.filter(d => d.car_brand_name === car_brand_name)
                    let carByBrands = _.orderBy(carByBrandFilters, ['car_model_name_full'], ['asc'])
                    let carByBrandSum = carByBrands.length

                    if ( car_brand_name === 'TOYOTA')  {
                        // group by กลุ่มรถ
                        let carStructureGroupUniq = _.uniqBy(carByBrands, 'car_structure_group');
                        let carStructureGroupSort = _.orderBy(carStructureGroupUniq, ['car_structure_group'], ['asc'])

                        for (let i = 0; i < carStructureGroupSort.length ; i++) {
                            let car_structure_group = carStructureGroupSort[i].car_structure_group
                            let car_brand_and_group = car_brand_name+'/'+car_structure_group
                            let carByBrandAndGroups = carByBrands.filter(d => d.car_structure_group === car_structure_group)

                            let car = getPriceListHeader('T'+i, car_brand_and_group, carByBrandAndGroups.length)
                            cars.push(car)
                            countBrandAndGroupHeader++

                            for (let j=0; j < carByBrandAndGroups.length ;j++) {
                                let carByBrand = carByBrandAndGroups[j]
                                let car = getPriceListDetail(j, carByBrand)
                                cars.push(car)
                            }
                        }

                    } else {
                        let car = getPriceListHeader(i, car_brand_name, carByBrandSum)
                        cars.push(car)
                        countBrandAndGroupHeader++

                        for (let j=0; j < carByBrands.length ;j++) {
                            let carByBrand = carByBrands[j]
                            let car = getPriceListDetail(j, carByBrand)
                            cars.push(car)
                        }
                    }
                } //

                this.setState({
                    cars: cars,
                    countBrandAndGroupHeader: countBrandAndGroupHeader,
                    car_brand_names: carBrandNameUniq,
                    isLoading: false
                })
            })
        })
    }

    getDataCarSearchWithCriteria() {
        let branch_id = this.state.branch_id


    }

    exportExcelPriceList() {
        let cars = this.state.cars

        this.setState({
            isLoading: true
        })

        let excelData = cars.map((car, index) => {
            delete car.car_id
            return car
        })

        const ExcelJS = require("exceljs/dist/exceljs");
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("PriceList");

        let columns = [
            { header: "No.", key: "no", width: 6,},
            { header: "ยี่ห้อ", key: "car_brand_name", width: 16,},
            { header: "รุ่น/รุ่นย่อย", key: "car_model", width: 20,},
            { header: "ปี", key: "car_year_name", width: 8,},
            { header: "สี", key: "car_color_name", width: 8,},
            { header: "เกียร์", key: "car_gear_name", width: 8,},
            { header: "ทะเบียนเก่า", key: "license_plate_old", width: 16,},
            { header: "ทะเบียนใหม่", key: "license_plate_new", width: 16,},
            { header: "ระยะ(ด.)", key: "car_date_in_month", width: 10,},
            { header: "ซื้อเข้า", key: "car_date_in_text", width: 10,},
            { header: "VAT", key: "is_car_vat", width: 10,},
            { header: "ราคา(K)", key: "car_sale_price", width: 10,},
            { header: "Key", key: "car_spare_key", width: 10,},
            { header: "BS", key: "car_book_service", width: 10,},
            { header: "Mile", key: "car_miles", width: 10,},
        ];

        sheet.columns = columns

        const promise = Promise.all(excelData.map(async (car, index) => {
            let rowNumber = index;
            sheet.addRow(car)
            sheet.getRow(rowNumber).eachCell((cell, colNumber ) =>  {
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.border = border;
            });

            if (rowNumber === 0) {
                sheet.getRow(rowNumber).font = contentBoldStyle
            } else {
                sheet.getRow(rowNumber).font = contentStyle
            }
        }));

        promise.then(() => {
            sheet.getRow(1).font = contentBoldStyle
            sheet.getRow(1).border = border
            sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };

            workbook.xlsx.writeBuffer().then(function (data) {
                const blob = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                let curr = format(new Date(), 'yyyyMMdd_HHmmss')
                const fileName = 'pricelist_'+curr+'.xlsx'

                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });

            setTimeout(() => {
                this.setState({
                    isLoading: false
                })
            } , 1200);

        });

    }

    render() {

        const {
            columns,
            cars,
            countBrandAndGroupHeader
        } = this.state

        const conditionalRowStyles = [
            {
                when: row => row.content_type === 'head',
                style: {
                    backgroundColor: 'orange',
                    color: 'white',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            },
            {
                when: row => row.content_type === 'detail' && row.is_car_vat === 'NO',
                style: {
                    color: 'blue',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            }
            // You can also pass a callback to style for additional customization
            // {
            //     when: row => row.calories < 400,
            //     style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
            // },
        ];

        let branch_list = this.state.branchs.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })

        let car_count = cars.length - countBrandAndGroupHeader

        return (
            <div className="row">

                <Loading isLoading={this.state.isLoading} />

                <div className="col-sm-12 col-md-12">
                    <div className="card card-accent-primary">
                        <div className="card-header">
                            <div className="row">
                            <div className="col-6 form-inline">
                                <select className={"form-control ml-2 mr-1 mb-1" }
                                        name="branch_id"
                                        style={{width: 180}}
                                        value={this.state.branch_id}
                                        onChange={(e) => {
                                            let branchs= this.state.branchs
                                            let branch_id = e.target.value

                                            let branch = branchs.filter(b => b.branch_id === Number(branch_id))[0]

                                            this.setState({branch_id: branch_id, branch_name: branch.branch_name}, () =>{
                                                this.loadCarData()
                                            })
                                        }}
                                >
                                    {branch_list}
                                </select>
                                &nbsp;&nbsp;&nbsp;
                                <strong>รถคงเหลือ {car_count} คัน</strong>
                            </div>

                            <div className="col-6">
                                <button type="button"
                                    className="float-right btn btn-success"
                                    style={{marginTop: 2}}
                                    onClick={() => {
                                        this.exportExcelPriceList()
                                    }}
                                >
                                    Excel
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-block">
                        <div className="row">
                        {
                            cars.length !== 0 && <DataTable
                                columns={columns}
                                data={cars}
                                keyField={'car_id'}
                                wrap={true}
                                allowOverflow={true}
                                customStyles={customStyles}
                                conditionalRowStyles={conditionalRowStyles}
                                fixedHeader={true}
                                fixedHeaderScrollHeight={'550px'}
                                dense
                            />
                        }
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export default PriceList
