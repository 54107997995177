import React, { Component } from 'react';
import { th } from 'date-fns/locale';
import axios from 'axios';
import { APIURL, CLOUDFRONT_URL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../config/config';
import Loading from '../Loading';
import { DateUtil } from '../../utils/dateUtil';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NumberUtil } from '../../utils/number-util';
import { isNumber } from 'chart.js/helpers';
import { AlertError, AlertSuccess } from '../Alert/Alert';
import './table.css';
import {MonthUtil} from "../../utils/monthUtil";
import {format} from "date-fns";
import {connect} from "react-redux";
import {getSumCostBeforeSell} from "../../services/costProfitService";
import {
  titleStyle,
  contentStyle,
  contentBoldStyle,
  border
} from "../../utils/exceljsStyles";
import {ExcelUtil} from "../../utils/excelUtil";

class TrackInformationCarFinance extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      user: JSON.parse(this.props.user),
      cars: [],
      car_status: [],
      car_parking_list: [],
      book_status_text_list: [],
      repair_status_text_list: [],
      buy_car_from_list: [],
      finance_leasing_list: [],
      liciense_status: [],
      branch: [],
      car_brands: [],
      car_gears: [],
      car_years: [],
      leasing_settings: [],
      orders: [
        {order_id: 'car_in_desc', order_name: 'วันที่ซื้อรถเข้า : ใหม่ไปเก่า'},
        {order_id: 'car_in_asc', order_name: 'วันที่ซื้อรถเข้า : เก่าไปใหม่'},
        {order_id: 'car_brand_desc', order_name: 'ยี่ห้อ : มากไปน้อย'},
        {order_id: 'car_brand_asc', order_name: 'ยี่ห้อ : น้อยไปมาก'},
        {order_id: 'car_date_tax_desc', order_name: 'วันครบกำหนดภาษี : มากไปน้อย'},
        {order_id: 'car_date_tax_asc', order_name: 'วันครบกำหนดภาษี : น้อยไปมาก'},
        {order_id: 'year_desc', order_name: 'ปีรถ : ใหม่ไปเก่า'},
        {order_id: 'year_asc', order_name: 'ปีรถ : เก่าไปใหม่'},
        {order_id: 'overdue_date_tax_desc', order_name: 'ภาษีขาด : มากไปน้อย'},
        {order_id: 'overdue_date_tax_asc', order_name: 'ภาษีขาด : น้อยไปมาก'},
      ],
      width: 0,
      height: 0,
      order_by: '',
      park_location: '',
      car_status_id: '',
      book_status_text: '',
      liciense_status_id: '',
      buy_car_from: '',
      branch_id: '',
      car_brand_id: '',
      car_gear_id: '',
      car_year_id: '',
      month_year_list: [],
      carin_month_year: '',
      car_license_plate: '',
      repair_status_text_search: '',
      finance_leasing_search: '',
    }

    this.loadCarFinanceData = this.loadCarFinanceData.bind(this);
    this.reloadCarData = this.reloadCarData.bind(this);

    this.onChangeInputRowLeft = this.onChangeInputRowLeft.bind(this);
    this.onChangeInputRowRight = this.onChangeInputRowRight.bind(this);
    this.onChangeFinanceLeasingRowLeft = this.onChangeFinanceLeasingRowLeft.bind(this);

    this.onClickSave = this.onClickSave.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.loadCarStatusData = this.loadCarStatusData.bind(this);
    this.loadLicienseStatus = this.loadLicienseStatus.bind(this);
    this.loadBranchData = this.loadBranchData.bind(this);
    this.loadCarBrandData = this.loadCarBrandData.bind(this);
    this.loadCarGearData = this.loadCarGearData.bind(this);
    this.loadCarYearData = this.loadCarYearData.bind(this);
    this.loadCarLeasingSetting = this.loadCarLeasingSetting.bind(this);
    this.onChangeCarLeasing = this.onChangeCarLeasing.bind(this);
    this.loadCarInNotSellMonthList = this.loadCarInNotSellMonthList.bind(this);
    this.getDataCarTrackInformationWithCriteria = this.getDataCarTrackInformationWithCriteria.bind(this);
    this.exportExcel1 = this.exportExcel1.bind(this);
    this.exportExcel2 = this.exportExcel2.bind(this);
  }

  componentDidMount () {
    this.loadCarInNotSellMonthList();
    this.loadCarParking();
    this.loadCarStatusData();
    this.loadLicienseStatus();
    this.loadBranchData();
    this.loadCarBrandData();
    this.loadCarGearData();
    this.loadCarYearData();
    this.loadCarLeasingSetting();
    this.loadCarFinanceData();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount () {
    this.mounted = false;
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  loadCarInNotSellMonthList() {
    let uri = APIURL + '/car/list/no_sell/month'
    axios.get(uri)
        .then(res => {
          if( res.data.length > 0) {
            let months = res.data

            let month_year_list =  months.map(month => {
              let month_list_thai = MonthUtil.convertMonthYear(month.month_list)
              return {
                ...month,
                month_list_thai: month_list_thai
              }
            });

            this.setState({
              month_year_list: month_year_list
            });

          }
        }).catch(error => {

    })
  }

  loadCarStatusData () {
    axios.get(APIURL + '/car_status/new')
      .then(res => {
        this.setState({car_status: res.data})
      }).catch(error => {
      this.setState({car_status: []})
    })
  }

  loadLicienseStatus() {
    axios.get(APIURL + '/liciense_status/')
      .then(res => {
        this.setState({liciense_status: res.data})
      }).catch(error => {
      this.setState({liciense_status: []})
    })
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branch: res.data})
      }).catch(error => {
      this.setState({branch: []})
    })
  }

  loadCarBrandData () {
    axios.get(APIURL + '/car_brand/')
      .then(res => {
        this.setState({car_brands: res.data})
      }).catch(error => {
      this.setState({car_brands: []})
    })
  }

  loadCarGearData () {
    axios.get(APIURL + '/car_gear/')
      .then(res => {
        this.setState({car_gears: res.data})
      }).catch(error => {
      this.setState({car_gears: []})
    })
  }

  loadCarYearData () {
    axios.get(APIURL + '/car_year/')
      .then(res => {
        this.setState({car_years: res.data})
      }).catch(error => {
      this.setState({car_years: []})
    })
  }

  loadCarLeasingSetting() {
    let url = APIURL + '/leasing_setting/';
    axios.get(url)
        .then(res => {

            let leasing_settings = res.data.map((v, i) => {
              return {...v,  car_leasing_status: 'new', car_leasing_id: 0, car_id: 0}
            })

            this.setState({ leasing_settings: leasing_settings })
        });
  }

  reloadCarData() {
    this.setState({
      order_by: '',
      park_location: '',
      car_status_id: '',
      book_status_text: '',
      liciense_status_id: '',
      buy_car_from: '',
      branch_id: '',
      car_brand_id: '',
      car_gear_id: '',
      car_year_id: '',
      carin_month_year: ''
    }, () => this.loadCarFinanceData())
  }

  loadCarFinanceData() {

     this.setState({
       isLoading: true
     },() => {
       let uri = APIURL + '/car/trackfinance_with_leasing'
       axios.post(uri, {}).then(res => {

         let cars = res.data
         let data = cars.map(car => {
           return {...car, car_edit: 1, edit_left: 1 ,edit_right: 1}
         });

         // book_status_text
         let _ = require('lodash');
         let bookStatusTextUniq = _.uniqBy(cars, 'book_status_text');
         let bookStatusSorted = _.orderBy(bookStatusTextUniq, ['book_status_text'], ['asc']);
         let bookStatusTextArrs = _.map(bookStatusSorted, 'book_status_text');

         // let bookStatusTextFilter = _.filter(
         //   bookStatusTextUniq, function (o) {
         //     return o.book_status_text !== '';
         //   }
         // );

         let repairStatusTextUniq = _.uniqBy(cars, 'repair_status_text');
         let repairStatusTextSorted = _.orderBy(repairStatusTextUniq, ['repair_status_text'], ['asc']);
         let repairStatusText = _.map(repairStatusTextSorted, 'repair_status_text');
         let repairStatusFilter = repairStatusText.filter(text => text !== "")

         let buyCarFromUniq = _.uniqBy(cars, 'buy_car_from');
         let buyCarFromSorted = _.orderBy(buyCarFromUniq, ['buy_car_from'], ['asc']);
         let buyCarFromArrs = _.map(buyCarFromSorted, 'buy_car_from');

         // finance_leasing = ไฟแนนซ์ที่เลือก
         let car_finance_leasings = cars.filter(car => car.finance_leasing !== null)
         let financeLeasingUniq = _.uniqBy(car_finance_leasings, 'finance_leasing');
         let financeLeasingSorted = _.orderBy(financeLeasingUniq, ['finance_leasing'], ['asc']);
         let financeLeasingArrs = _.map(financeLeasingSorted, 'finance_leasing');

         // console.log(repairStatusFilter)
         this.setState({
           cars: data,
           book_status_text_list: bookStatusTextArrs,
           repair_status_text_list: repairStatusFilter,
           buy_car_from_list: buyCarFromArrs,
           finance_leasing_list: financeLeasingArrs,
           isLoading: false
         });
       })
     });
  }

  getDataCarTrackInformationWithCriteria() {
    let order_by = this.state.order_by
    let park_location = this.state.park_location
    let car_status_id = this.state.car_status_id
    let book_status_text = this.state.book_status_text
    let liciense_status_id = this.state.liciense_status_id
    let buy_car_from = this.state.buy_car_from
    let branch_id = this.state.branch_id
    let car_brand_id = this.state.car_brand_id
    let car_gear_id = this.state.car_gear_id
    let car_year_id = this.state.car_year_id
    let month_year_list = this.state.month_year_list
    let carin_month_year = this.state.carin_month_year
    let car_license_plate = this.state.car_license_plate
    let repair_status_text_search = this.state.repair_status_text_search
    let finance_leasing_search = this.state.finance_leasing_search

    let end_date = ''
    let start_date = ''

    if (carin_month_year !== '') {
      let month_year = month_year_list.filter(my => my.month_list === carin_month_year)
      end_date = month_year[0].end_date
      start_date = month_year[0].start_date
    }

    let data = {
      order_by,
      park_location,
      car_status_id,
      book_status_text,
      liciense_status_id,
      buy_car_from,
      branch_id,
      car_brand_id,
      car_gear_id,
      car_year_id,
      end_date,
      start_date,
      car_license_plate,
      repair_status_text_search,
      finance_leasing_search
    }

    // trackcriteria
    let url = APIURL+'/car/trackcriteria'
    this.setState({
      isLoading: true
    }, () => {
      axios.post(url, data)
        .then(res => {

          this.setState({
            cars: res.data,
            isLoading: false
          })

        }).catch(error => {
        console.log(error)
      });
    });
  }

  onChangeInputRowLeft(carSelect, index, event) {
    let car_id = carSelect.car_id
    let name = event.target.name
    let value = event.target.value

    let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, [name]: value, car_edit: 2, edit_left: 2 } : car )

    this.setState({
      cars: car_list
    });
  }

  onChangeFinanceLeasingRowLeft(carSelect, index, event) {
    let car_id = carSelect.car_id
    let name = event.target.name
    let value = event.target.value

    // set : finance_average_price from leasing

    let car_list = this.state.cars.map(car =>{
      // car.car_id === car_id ? {...car, [name]: value, car_edit: 2, edit_left: 2 } : car
      if ( car.car_id === car_id ) {
        let car_leasings = car.car_leasings
        let car_leasing = car_leasings.filter(leasing => leasing.car_leasing_name === value)[0]
        let finance_average_price = car_leasing.car_leasing_price_median
        return  {...car, [name]: value, finance_average_price: finance_average_price, car_edit: 2, edit_left: 2 }
      }
      return car
    })

    this.setState({
      cars: car_list
    });

  }

  onChangeInputRowRight(carSelect, index, event) {

    let car_id = carSelect.car_id
    let name = event.target.name
    let value = event.target.value
    let number_fields = [
      'finance_average_price',
      'monthly_installment_48',
      'monthly_installment_60',
      'monthly_installment_72',
      'monthly_installment_84',
      'monthly_installment_96'
    ]

    if (number_fields.includes(name)) {
      // check is number
      value = NumberUtil.removeComma(String(value))

      if(!isNumber(value)) {
        AlertError('กรุณากรอกตัวเลข')
        return
      }
      value = NumberUtil.addCommasZeroInt(value);
    }

    let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, [name]: value, car_edit: 2, edit_right: 2 } : car )

    this.setState({
      cars: car_list
    });

  }

  onClickSave(car, index,) {
    let user = this.props.user
    let username = user.user
    let data = {
      park_location: car.park_location,
      // repair_status_text: car.repair_status_text,
      // sell_status_text: car.sell_status_text,
      // book_status_text: car.book_status_text,
      fb_page: car.fb_page,
      finance_average_price: NumberUtil.removeComma(car.finance_average_price),
      finance_leasing: car.finance_leasing,
      finance_note: car.finance_note,
      monthly_installment_48: NumberUtil.removeComma(car.monthly_installment_48),
      monthly_installment_60: NumberUtil.removeComma(car.monthly_installment_60),
      monthly_installment_72: NumberUtil.removeComma(car.monthly_installment_72),
      monthly_installment_84: NumberUtil.removeComma(car.monthly_installment_84),
      monthly_installment_96: NumberUtil.removeComma(car.monthly_installment_96),
      car_id: car.car_id,
      car_leasings: car.car_leasings,
      username: username
    }

    let car_id = car.car_id
    this.setState({
      isLoading: true
    }, () => {

      let url = APIURL+'/car/trackfinance/'+car_id
      axios.put(url, data).then(res => {
        if(res.status === 200) {
          let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, car_edit: 1, edit_left: 1 ,edit_right: 1 } : car )
          this.setState({
            cars: car_list,
            isLoading: false
          }, () => {
            AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')
          });

        }
      })
        .catch(error => {
          AlertError("พบข้อผิดพลาด : " + error)
          this.setState({
            isLoading: false
          })
        })

    })
  }

  loadCarParking() {
    let url = APIURL+'/car_parking'
    axios.get(url).then(res => {
      let data = res.data
      this.setState({
        car_parking_list: data
      })
    })
  }

  onChangeCarLeasing(car, index, leasing, index_leasing, event) {
    //  {leasing.car_leasing_name} : {leasing.car_leasing_price_median}
    let name = event.target.name
    let val = event.target.value

    val = NumberUtil.removeComma(String(val))

    if (!isNumber(val)) {
      AlertError('กรุณากรอกตัวเลข')
      return
    }

    let valComma = NumberUtil.addCommasZeroInt(val);

    let car_id = car.car_id
    let car_leasings = car.car_leasings
    let car_leasing_old = car_leasings[index_leasing]
    // car_leasing_old.car_leasing_price_median = val
    let car_leasing_status = car_leasing_old.car_leasing_status === 'new' ? 'insert' : car_leasing_old.car_leasing_status === 'exist' ? 'update' : car_leasing_old.car_leasing_status
    let car_leasing_new = {...car_leasing_old, car_leasing_price_median: valComma, car_leasing_status: car_leasing_status}

    car_leasings[index_leasing] = car_leasing_new

    let car_list = this.state.cars.map(car => car.car_id === car_id ? {...car, car_leasings: car_leasings, car_edit: 2, edit_right: 2 } : car )

    this.setState({
      cars: car_list
    });
  }

  exportExcel1 () {
    let cars = this.state.cars
    let type = this.state.user.type

    this.setState({
      isLoading: true
    })

    let car_leasing_setting = []
    let excelData = cars.map((car, index) => {
      let order = index +1
      let car_license_plate_new = car.car_license_plate_new
      let car_license_plate_old = car.car_license_plate_old
      let car_license_plate = car_license_plate_new+' | ' +car_license_plate_old
      // let car_date_tax = car.car_date_tax
      // let car_date_tax_text = DateUtil.convertShortDateThaiDash(car_date_tax)
      // let car_date_in = car.car_date_in
      // let car_date_in_text = DateUtil.convertShortDateThaiDash(car_date_in)
      // finance_leasing , finance_note
      // let finance_note = car.finance_note
      let car_brand_name = car.car_brand_name
      let car_model_name = car.car_model_name
      let branch_name = car.branch_name
      let car_sale_price = NumberUtil.addCommas(car.car_sale_price)
      let finance_average_price = car.finance_average_price
      let finance_leasing = car.finance_leasing === null ? '-' : car.finance_leasing
      let finance_note = car.finance_note === '' || car.finance_note === null ? '-': car.finance_note
      let fb_page = car.fb_page
      let repair_status_text = car.repair_status_text
      let car_leasings = car.car_leasings

      let car_cost_with_vat = car.car_cost+car.vat_buy
      let car_cost_other_sum = getSumCostBeforeSell(car)
      let sum_car_cost = car_cost_with_vat + car_cost_other_sum

      if (index === 0) {
        car_leasing_setting = car_leasings
      }

      let row = {
        order: order,
        thumbnail: car.car_upload_filename,
        park_location: car.park_location,
        buy_car_from: car.buy_car_from,
        branch_name: branch_name,
        car_license_plate: car_license_plate,
        car_brand_name: car_brand_name,
        car_model_name: car_model_name,
        repair_status_text: repair_status_text,
        car_gear_name: car.car_gear_name,
        car_color_name: car.car_color_name,
        car_year_name: car.car_year_name,
        fb_page: fb_page,
        car_upload_filename: car.car_upload_filename,
      }

      if (type === 'admin') {
        row = {...row,
          car_cost_with_vat:  NumberUtil.addCommasZeroInt(car_cost_with_vat),
          car_cost_other_sum:  NumberUtil.addCommasZeroInt(car_cost_other_sum),
          sum_car_cost:  NumberUtil.addCommasZeroInt(sum_car_cost),
        }
      }

      row = {...row,
        car_sale_price: NumberUtil.addCommasZeroInt(car_sale_price),
        finance_note: finance_note,
        finance_average_price: NumberUtil.addCommasZeroInt(finance_average_price),
        finance_leasing: finance_leasing
      }

        for (let i=0; i < car_leasings.length; i++) {
        let car_leasing = car_leasings[i]
        let car_leasing_name = car_leasing.car_leasing_name
        let car_leasing_price_median = NumberUtil.addCommasZeroInt(car_leasing.car_leasing_price_median)
        row = {...row, [car_leasing_name]:  car_leasing_price_median}
      }

      return row
    });

    const ExcelJS = require("exceljs/dist/exceljs");
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("ตั้งค่าไฟแนนซ์");

    let columns = [
      { header: "ลำดับ", key: "order", width: 6,},
      { header: "รูปรถ", key: "thumbnail", width: 16,},
      { header: "สถานที่จอด", key: "park_location", width: 16,},
      { header: "หัวบิล/ซื้อรถจาก", key: "buy_car_from", width: 16,},
      { header: "สาขา", key: "branch_name", width: 16,},
      { header: "ทะเบียน", key: "car_license_plate", width: 16,},
      { header: "ยี่ห้อ", key: "car_brand_name", width: 16,},
      { header: "รุ่น", key: "car_model_name", width: 16,},
      { header: "ประเภทรถ", key: "repair_status_text", width: 16,},
      { header: "เกียร์", key: "car_gear_name", width: 12,},
      { header: "สี", key: "car_color_name", width: 12,},
      { header: "ปี", key: "car_year_name", width: 12,},
      { header: "ดอกเบี้ยเริ่มต้น", key: "fb_page", width: 12,}
    ];

    if (type === 'admin') {
      columns.push({ header: "ทุนซื้อ", key: "car_cost_with_vat", width: 12,})
      columns.push({ header: "ทุนอื่นๆ", key: "car_cost_other_sum", width: 12,})
      columns.push({ header: "รวมทุน", key: "sum_car_cost", width: 12,})
    }

    columns.push({ header: "ราคาขาย", key: "car_sale_price", width: 12,})
    columns.push({ header: "ราคากลาง", key: "finance_average_price", width: 12,})
    columns.push({ header: "ไฟแนนซ์ที่เลือก", key: "finance_leasing", width: 12,})
    columns.push({ header: "เงื่อนไขไฟแนนซ์", key: "finance_note", width: 12,})

    for (let i=0; i < car_leasing_setting.length; i++) {
      let car_leasing = car_leasing_setting[i]
      let car_leasing_name = car_leasing.car_leasing_name
      let header = { header: car_leasing_name, key:car_leasing_name, width: 11,}
      columns.push(header)
    }

    sheet.columns = columns

    let curr = format(new Date(), 'dd/MM/yyyy HH:mm:ss')
    // let currFileName = format(new Date(), 'yyyyMMdd_HHmmss')

    let rows = [
      ['ตั้งค่าไฟแนนซ์ 1'],
      ['พิมพ์วันที่ : '+curr],
    ]
    sheet.insertRows(1, rows);

    const promise = Promise.all(
        excelData.map(async (car, index) => {
          // data แถวแรก แถวที่ 4
          let rowNumber = index + 3;
          delete car.thumbnail
          sheet.addRow(car)

          const result = await ExcelUtil.toDataURL(car.car_upload_filename);
          const splitted = car.car_upload_filename.split(".");
          const extName = splitted[splitted.length - 1];

          const imageId2 = workbook.addImage({
            base64: result.base64Url,
            extension: extName,
          });

          sheet.getRow(rowNumber).eachCell((cell, colNumber ) =>  {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.border = border;
          });

          if (rowNumber === 3) {
            sheet.getRow(rowNumber).font = contentBoldStyle
          } else {
            sheet.getRow(rowNumber).font = contentStyle
          }

          sheet.getRow(rowNumber+1).height = 80

          sheet.addImage(imageId2, {
            tl: { col: 1.1, row: rowNumber+0.1 },
            ext: { width: 96, height: 96 },
          });

          if((excelData.length-1) === index) {
            // set last row
            rowNumber = rowNumber +1
            sheet.getRow(rowNumber).eachCell((cell, colNumber ) =>  {
              cell.alignment = { vertical: 'middle', horizontal: 'center' };
              cell.border = border;
            });
            sheet.getRow(rowNumber).font = contentStyle;
          }

        }));

    promise.then(() => {
      sheet.getRow(1).font = titleStyle
      sheet.getRow(2).font = contentStyle
      sheet.getRow(3).font = contentBoldStyle
      sheet.getRow(3).border = border
      sheet.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };

      for(let i=0; i < excelData.length; i++) {
        let row = i + 4
        let cellName = 'B'+String(row)
        sheet.getCell(cellName).border = border
      }

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        let curr = format(new Date(), 'yyyyMMdd_HHmmss')
        const fileName = 'ตั้งค่าไฟแนนซ์1_export_'+curr+'.xlsx'

        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });

      setTimeout(() => {
        this.setState({
          isLoading: false
        })
      } , 1200)

    });

  }

  exportExcel2 () {
    let cars = this.state.cars

    this.setState({
      isLoading: true
    })

    let car_leasing_setting = []
    let excelData = cars.map((car, index) => {
      let order = index +1
      let car_license_plate_new = car.car_license_plate_new
      let car_license_plate_old = car.car_license_plate_old
      let car_license_plate = car_license_plate_new+' | ' +car_license_plate_old
      // let car_date_tax = car.car_date_tax
      // let car_date_tax_text = DateUtil.convertShortDateThaiDash(car_date_tax)
      // let car_date_in = car.car_date_in
      // let car_date_in_text = DateUtil.convertShortDateThaiDash(car_date_in)
      // finance_leasing , finance_note
      // let finance_note = car.finance_note
      let car_brand_name = car.car_brand_name
      let car_model_name = car.car_model_name
      let branch_name = car.branch_name
      let car_sale_price = NumberUtil.addCommasZeroInt(car.car_sale_price)
      let finance_average_price = NumberUtil.addCommasZeroInt(car.finance_average_price)
      let finance_leasing = car.finance_leasing === null ? '-' : car.finance_leasing
      let finance_note = car.finance_note === null ? ' ' : car.finance_note
      let fb_page = car.fb_page
      let car_leasings = car.car_leasings

      if (index === 0) {
        car_leasing_setting = car_leasings
      }

      let row = {
        order: order,
        thumbnail: car.car_upload_filename,
        park_location: car.park_location,
        buy_car_from: car.buy_car_from,
        branch_name: branch_name,
        car_license_plate: car_license_plate,
        car_brand_name: car_brand_name,
        car_model_name: car_model_name,
        car_gear_name: car.car_gear_name,
        car_color_name: car.car_color_name,
        car_year_name: car.car_year_name,
        fb_page: fb_page,
        car_sale_price: car_sale_price,
        finance_average_price: finance_average_price,
        finance_leasing: finance_leasing,
        finance_note: finance_note,
        monthly_installment_48: NumberUtil.addCommasZeroInt(car.monthly_installment_48),
        monthly_installment_60: NumberUtil.addCommasZeroInt(car.monthly_installment_60),
        monthly_installment_72: NumberUtil.addCommasZeroInt(car.monthly_installment_72),
        monthly_installment_84: NumberUtil.addCommasZeroInt(car.monthly_installment_84),
        car_upload_filename: car.car_upload_filename,
      }

      // for (let i=0; i < car_leasings.length; i++) {
      //   let car_leasing = car_leasings[i]
      //   let car_leasing_name = car_leasing.car_leasing_name
      //   let car_leasing_price_median = car_leasing.car_leasing_price_median
      //   row = {...row, [car_leasing_name]:  car_leasing_price_median}
      // }

      return row
    });

    const ExcelJS = require("exceljs/dist/exceljs");
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("ตั้งค่าไฟแนนซ์");

    /*let titleStyle = {
      name: "AngsanaUPC",
      family: 4,
      size: 18,
      bold: true,
    };

    let contentStyle = {
      name: "AngsanaUPC",
      family: 4,
      size: 16,
      bold: false,
    };

    let contentBoldStyle = {
      name: "AngsanaUPC",
      family: 4,
      size: 16,
      bold: true,
    };

    let border= {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    } */

    let columns = [
      { header: "ลำดับ", key: "order", width: 6,},
      { header: "รูปรถ", key: "thumbnail", width: 16,},
      { header: "สถานที่จอด", key: "park_location", width: 16,},
      { header: "หัวบิล/ซื้อรถจาก", key: "buy_car_from", width: 16,},
      { header: "สาขา", key: "branch_name", width: 16,},
      { header: "ทะเบียน", key: "car_license_plate", width: 16,},
      { header: "ยี่ห้อ", key: "car_brand_name", width: 16,},
      { header: "รุ่น", key: "car_model_name", width: 16,},
      { header: "เกียร์", key: "car_gear_name", width: 12,},
      { header: "สี", key: "car_color_name", width: 12,},
      { header: "ปี", key: "car_year_name", width: 12,},
      { header: "ดอกเบี้ยเริ่มต้น", key: "fb_page", width: 12,},
      { header: "ราคาขาย", key: "car_sale_price", width: 12,},
      { header: "ราคากลาง", key: "finance_average_price", width: 12,},
      { header: "ไฟแนนซ์ที่เลือก", key: "finance_leasing", width: 12,},

      { header: "เงื่อนไขไฟแนนซ์", key: "finance_note", width: 14,},
      { header: "ผ่อน 4 ปี (48 งวด)", key: "monthly_installment_48", width: 14,},
      { header: "ผ่อน 5 ปี (60 งวด)", key: "monthly_installment_60", width: 14,},
      { header: "ผ่อน 6 ปี (72 งวด)", key: "monthly_installment_72", width: 14,},
      { header: "ผ่อน 7 ปี (84 งวด)", key: "monthly_installment_84", width: 14,},
    ];

    /* for (let i=0; i < car_leasing_setting.length; i++) {
      let car_leasing = car_leasing_setting[i]
      let car_leasing_name = car_leasing.car_leasing_name
      let header = { header: car_leasing_name, key:car_leasing_name, width: 11,}
      columns.push(header)
    } */

    sheet.columns = columns

    let curr = format(new Date(), 'dd/MM/yyyy HH:mm:ss')
    // let currFileName = format(new Date(), 'yyyyMMdd_HHmmss')

    let rows = [
      ['ตั้งค่าไฟแนนซ์ 2'],
      ['พิมพ์วันที่ : '+curr],
    ]
    sheet.insertRows(1, rows);

    const promise = Promise.all(
        excelData.map(async (car, index) => {
          // data แถวแรก แถวที่ 4
          let rowNumber = index + 3;
          delete car.thumbnail
          sheet.addRow(car)

          const result = await ExcelUtil.toDataURL(car.car_upload_filename);
          const splitted = car.car_upload_filename.split(".");
          const extName = splitted[splitted.length - 1];

          const imageId2 = workbook.addImage({
            base64: result.base64Url,
            extension: extName,
          });

          sheet.getRow(rowNumber).eachCell((cell, colNumber ) =>  {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.border = border;
          });

          if (rowNumber === 3) {
            sheet.getRow(rowNumber).font = contentBoldStyle
          } else {
            sheet.getRow(rowNumber).font = contentStyle
          }

          sheet.getRow(rowNumber+1).height = 80

          sheet.addImage(imageId2, {
            tl: { col: 1.1, row: rowNumber+0.1 },
            ext: { width: 96, height: 96 },
          });

          if((excelData.length-1) === index) {
            // set last row
            rowNumber = rowNumber +1
            sheet.getRow(rowNumber).eachCell((cell, colNumber ) =>  {
              cell.alignment = { vertical: 'middle', horizontal: 'center' };
              cell.border = border;
            });
            sheet.getRow(rowNumber).font = contentStyle;
          }

        }));

    promise.then(() => {
      sheet.getRow(1).font = titleStyle
      sheet.getRow(2).font = contentStyle
      sheet.getRow(3).font = contentBoldStyle
      sheet.getRow(3).border = border
      sheet.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };

      for(let i=0; i < excelData.length; i++) {
        let row = i + 4
        let cellName = 'B'+String(row)
        sheet.getCell(cellName).border = border
      }

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        let curr = format(new Date(), 'yyyyMMdd_HHmmss')
        const fileName = 'ตั้งค่าไฟแนนซ์2_export_'+curr+'.xlsx'

        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });

      setTimeout(() => {
        this.setState({
          isLoading: false
        })
      } , 1200)

    })

  }

  render () {

    let {
      book_status_text_list,
      repair_status_text_list,
      finance_leasing_list,
      cars,
      width,
      car_parking_list,
      car_status,
      liciense_status,
      buy_car_from_list,
      branch,
      car_brands,
      car_gears,
      orders,
      leasing_settings,
      month_year_list
    } = this.state

    // let cars = this.state.cars
    // let width = this.state.width
    let type = this.state.user.type

    let width_box = 100

    if (width <= 980){
      width_box = 80
    }

    let carParkingOptions = car_parking_list.map((parking, index) => {
      return <option value={parking.car_parking_name} key={index}> {parking.car_parking_name} </option>
    })

    let carStatusOptions = car_status.map((status, index) => {
      return <option value={status.car_status_id} key={index}> {status.car_status_name} </option>
    })

    let bookStatusOptions = book_status_text_list.map((book_status_text, index) => {
      let book_status_str = book_status_text === '' ? 'ไม่ระบุ': book_status_text
      let book_status_val = book_status_text === '' ? 'empty': book_status_text
      return <option value={book_status_val} key={index}> {book_status_str} </option>
    })

    let licienseStatusOptions = liciense_status.map((liciense_status, index) => {
      return (
        <option key={index} value={liciense_status.liciense_status_id}>{liciense_status.liciense_status_name}</option>
      )
    })

    let buyCarFromOptions = buy_car_from_list.map((buy_car_from, index) => {
      return <option value={buy_car_from} key={index}> {buy_car_from} </option>
    })

    let carBranchOptions = branch.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    let carBrandOptions = car_brands.map((car_brand, index) => {
      return (
        <option key={index} value={car_brand.car_brand_id}>{car_brand.car_brand_name}</option>
      )
    })

    let carGearOptions = car_gears.map((car_gear, index) => {
      return (
        <option key={index} value={car_gear.car_gear_id}>{car_gear.car_gear_name}</option>
      )
    })

    let carYearOptions = this.state.car_years.map((car_year, index) => {
      return (
        <option key={index} value={car_year.car_year_id}>{car_year.car_year_name}</option>
      )
    })

    let carLeasingSettings = leasing_settings.map((leasing, index) => {
      return (
          <option key={index} value={leasing.name_credit}>{leasing.name_credit}</option>
      )
    })

    let order_list = orders.map((order, index) => {
      return (
        <option key={index} value={order.order_id}>{order.order_name}</option>
      )
    })

    let month_year_list_option = month_year_list.map((month_year, index) => (
        <option key={index} value={month_year.month_list}>{month_year.month_list_thai}</option>
    ));


    let carList = cars.map((car, index) => {

      let car_date_tax = car.car_date_tax
      // let car_date_tax_text = DateUtil.convertShortDateThaiDash(car_date_tax)
      let car_date_in = car.car_date_in
      // let car_date_in_text = DateUtil.convertShortDateThaiDash(car_date_in)
      let car_license_plate_new = car.car_license_plate_new
      let car_license_plate_old = car.car_license_plate_old
      let car_brand_name = car.car_brand_name
      let car_model_name = car.car_model_name
      let repair_status_text = car.repair_status_text
      let car_sale_price = NumberUtil.addCommas(car.car_sale_price)
      // finance_leasing , finance_note
      let finance_leasing = car.finance_leasing
      let finance_note = car.finance_note
      let branch_name = car.branch_name

      // let date_ago = car_date_tax !== null && car_date_tax !== '0000-00-00' ? DateUtil.carDateTaxOverdue(car_date_tax) : '-'
      // let repair_status = ''
      // let sell_status = ''
      // let book_status = ''
      // let finance_average_price = ''
      // let monthly_installment_4 = ''
      // let monthly_installment_5 = ''
      // let monthly_installment_6 = ''
      // let monthly_installment_7 = ''
      // let monthly_installment_8 = ''

      let image_url = ''
      if (car.car_upload_filename !== null) {
        if (car.car_upload_filename.includes('https')) {
          // image_url = car.car_upload_filename
          let car_upload_key = car.car_upload_key
          image_url = CLOUDFRONT_URL+car_upload_key
        } else {
          image_url = IMAGE_FULL_URL + car.car_upload_filename
        }
      } else {
        image_url = IMAGE_NOPHOTO_URL
      }

      let car_id = car.car_id
      let car_leasings = car.car_leasings

      let car_cost_with_vat = car.car_cost+car.vat_buy
      let car_cost_other_sum = getSumCostBeforeSell(car)
      let sum_car_cost = car_cost_with_vat + car_cost_other_sum

      let order = index+1
      return <tr key={index}>
        <td>
          {
            car.edit_left === 2 ?
              <button
                className={car.car_edit === 1 ? "btn btn-sm btn-success" : (car.car_edit === 2) ? "btn btn-sm btn-warning" : "btn btn-sm " }
                disabled={car.car_edit===3}
                onClick={() => this.onClickSave(car, index)}
              > บันทึก </button>
              :  order
          }
        </td>
        <td> <LazyLoadImage src={image_url} width={80} height="auto" effect="blur"/> </td>
        <td>
          <select className="form-control"
                  name="park_location"
                  style={{width: width_box}}
                  value={this.state.cars[index].park_location}
                  onChange={(event)=> this.onChangeInputRowLeft(car, index, event)}
          >
            {carParkingOptions}
          </select>
        </td>
        <td> {car.buy_car_from}</td>
        <td> {branch_name}</td>
        <td className=" text-nowrap"> ใหม่ : {car_license_plate_new} <br/> เดิม : {car_license_plate_old} </td>
        <td> {car_brand_name} </td>
        <td> {car_model_name} </td>
        <td> {repair_status_text} </td>
        <td> {car.car_gear_name} </td>
        <td> {car.car_color_name} </td>
        <td> {car.car_year_name} </td>
        <td>
          <input type="text" name="fb_page" style={styles.input} maxLength={50}
                 value={this.state.cars[index].fb_page}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>{NumberUtil.addCommasZeroInt(car_cost_with_vat)}</td>
        <td>{NumberUtil.addCommasZeroInt(car_cost_other_sum)}</td>
        <td>{NumberUtil.addCommasZeroInt(sum_car_cost)}</td>
        <td>{NumberUtil.addCommasZeroInt(car_sale_price)}</td>
        <td>
          <input
            type="text"
            maxLength={8}
            name="finance_average_price"
            style={styles.input}
            value={NumberUtil.addCommasZeroInt(String(this.state.cars[index].finance_average_price))}
            onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <select className="form-control"
                  name="finance_leasing"
                  style={{width: width_box}}
                  value={this.state.cars[index].finance_leasing || ''}
                  onChange={(event)=> this.onChangeFinanceLeasingRowLeft(car, index, event)}
          >
            <option value="">เลือกไฟแนนซ์</option>
            {carLeasingSettings}
          </select>
        </td>
        <td>
          <input
              type="text"
              maxLength={130}
              name="finance_note"
              style={{...styles.input, width: 130}}
              value={this.state.cars[index].finance_note || ''}
              onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>

        <td>
          <input name="monthly_installment_48"
                 maxLength={8}
                 type="text"
                 style={styles.input}
                 value={NumberUtil.addCommasZeroInt(String(this.state.cars[index].monthly_installment_48))}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input
            name="monthly_installment_60"
            style={styles.input}
            type="text"
            maxLength={8}
            value={NumberUtil.addCommasZeroInt(String(this.state.cars[index].monthly_installment_60))}
            onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input name="monthly_installment_72"
                 style={styles.input}
                 type="text"
                 maxLength={8}
                 value={NumberUtil.addCommasZeroInt(String(this.state.cars[index].monthly_installment_72))}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td>
          <input name="monthly_installment_84"
                 style={styles.input}
                 type="text"
                 maxLength={8}
                 value={NumberUtil.addCommasZeroInt(String(this.state.cars[index].monthly_installment_84))}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>
        <td className=" text-nowrap"> ใหม่ : {car_license_plate_new} <br/> เดิม : {car_license_plate_old} </td>
        {/*<td>
          <input name="monthly_installment_96"
                 style={styles.input}
                 type="text"
                 maxLength={8}
                 value={this.state.cars[index].monthly_installment_96}
                 onChange={(event)=> this.onChangeInputRowRight(car, index, event)}
          />
        </td>*/}
        {
          car_leasings.map((leasing, index_leasing) => (
              <td key={index_leasing}>
                <input name={leasing.car_leasing_name}
                       style={styles.input}
                       type="text"
                       maxLength={8}
                       value={NumberUtil.addCommasZeroInt(String(this.state.cars[index].car_leasings[index_leasing].car_leasing_price_median))}
                       onChange={(event)=> this.onChangeCarLeasing(car, index, leasing, index_leasing, event)}
                />

              </td>
          ))
        }
        <td className="text-center" >
          {
            car.edit_right === 2 ?
              <button
                className={car.car_edit === 1 ? "btn btn-sm btn-success" : (car.car_edit === 2) ? "btn btn-sm btn-warning" : "btn btn-sm " }
                disabled={car.car_edit===3}
                onClick={() => this.onClickSave(car, index)}
              > บันทึก </button>
              :  <i className="icon-close" />
          }
        </td>

      </tr>
    })


    let repairStatusOptions = repair_status_text_list.map((repair_status_text, index) => {
      return <option value={repair_status_text} key={index}> {repair_status_text} </option>
    })

    let financeLeasingOptions = finance_leasing_list.map((finance_leasing, index) => {
      return <option value={finance_leasing} key={index}> {finance_leasing} </option>
    })


    return (
      <div className="row">
        <Loading isLoading={this.state.isLoading} />

        <div className="col-sm-12 col-md-12">
          <div className="card card-accent-primary">
            <div className="card-header card-customer">
             <strong>ติดตามข้อมูลรถคงเหลือ {cars.length} คัน</strong>
              &nbsp;&nbsp;
              <button
                  type="button"
                  className="btn btn-sm btn-head-bar ml-2"
                  style={{...styles.buttonHeight}}
                  onClick={() => {
                    this.reloadCarData()
                  }}
              >
                <i className="icon-refresh"/>
              </button>
            </div>
            <div className="card-block">
              <div className="row">
                <div className="col-12 form-inline">
                  <select
                      className="form-control mr-1"
                      style={{...styles.selectFilter, width: 120}}
                      name="carin_month_year"
                      value={this.state.carin_month_year}
                      onChange={(e) => {
                        let month_list = e.target.value
                        this.setState({
                          carin_month_year: month_list,
                        });
                      }}
                  >
                    <option value=''>เลือกเดือน</option>
                    {month_year_list_option}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="park_location"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.park_location}
                          onChange={(e) => {
                            this.setState({park_location: e.target.value})
                          }}
                  >
                    <option value="">สถานที่จอด</option>
                    {carParkingOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="park_location"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.car_status_id}
                          onChange={(e) => {
                            this.setState({car_status_id: e.target.value})
                          }}
                  >
                    <option value="">สถานะรถ</option>
                    {carStatusOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="book_status_text"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.book_status_text}
                          onChange={(e) => {
                            this.setState({book_status_text: e.target.value})
                          }}
                  >
                    <option value="">ที่อยู่เล่ม</option>
                    {bookStatusOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="book_status_text"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.liciense_status_id}
                          onChange={(e) => {
                            this.setState({liciense_status_id: e.target.value})
                          }}
                  >
                    <option value="">สถานะเล่ม</option>
                    {licienseStatusOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="buy_car_from"
                          style={{...styles.selectFilter, width: 130}}
                          value={this.state.buy_car_from}
                          onChange={(e) => {
                            this.setState({buy_car_from: e.target.value})
                          }}
                  >
                    <option value="">หัวบิล/ซื้อรถจาก</option>
                    {buyCarFromOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="branch_id"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.branch_id}
                          onChange={(e) => {
                            this.setState({branch_id: e.target.value})
                          }}
                  >
                    <option value="">เจ้าของ/สาขา</option>
                    {carBranchOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="car_brand_id"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.car_brand_id}
                          onChange={(e) => {
                            this.setState({car_brand_id: e.target.value})
                          }}
                  >
                    <option value="">ยี่ห้อ</option>
                    {carBrandOptions}
                  </select>


                </div>
              </div>
              <div className="row">
                <div className="col-12 form-inline">
                  <select className="form-control mr-1 mb-1"
                          name="car_gear_id"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.car_gear_id}
                          onChange={(e) => {
                            this.setState({car_gear_id: e.target.value})
                          }}
                  >
                    <option value="">เกียร์</option>
                    {carGearOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="car_year_id"
                          style={{...styles.selectFilter, width: 80}}
                          value={this.state.car_year_id}
                          onChange={(e) => {
                            this.setState({car_year_id: e.target.value})
                          }}
                  >
                    <option value="">ปีรถ</option>
                    {carYearOptions}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="order_by"
                          style={{...styles.selectFilter, width: 180}}
                          value={this.state.order_by}
                          onChange={(e) => {
                            this.setState({order_by: e.target.value})
                          }}
                  >
                    <option value="">เรียงตาม</option>
                    {order_list}
                  </select>

                  <select className="form-control mr-1 mb-1"
                          name="order_by"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.repair_status_text_search}
                          onChange={(e) => {
                            this.setState({repair_status_text_search: e.target.value})
                          }}
                  >
                    <option value="">ประเภทรถ</option>
                    {repairStatusOptions}
                  </select>


                  <select className="form-control mr-1 mb-1"
                          name="order_by"
                          style={{...styles.selectFilter, width: 120}}
                          value={this.state.finance_leasing_search}
                          onChange={(e) => {
                            this.setState({finance_leasing_search: e.target.value})
                          }}
                  >
                    <option value="">ไฟแนนซ์ที่เลือก</option>
                    {financeLeasingOptions}
                  </select>

                  <input
                      className="form-control mr-1 ml-2"
                      style={{width: 150}}
                      placeholder={'ทะเบียนรถ'}
                      value={this.state.car_license_plate}
                      name="car_license_plate"
                      onChange={(e) => {
                        this.setState({car_license_plate: e.target.value})
                      }}
                  />

                  <button
                      className="btn btn-sm btn-primary mr-1 ml-2"
                      onClick={() => this.getDataCarTrackInformationWithCriteria()}
                      style={{...styles.buttonHeight, marginTop: 2}}
                  >
                    <i className="icon-search"/>ค้นหา
                  </button>

                  <button
                      className="btn btn-sm btn-success mr-1 ml-2"
                      onClick={() => {
                        this.exportExcel1()
                      }}
                      style={{...styles.buttonHeight, marginTop: 2}}
                  >
                    <i className="icon-search"/>Excel 1
                  </button>

                  <button
                      className="btn btn-sm btn-success mr-1 ml-2"
                      onClick={() => {
                        this.exportExcel2()
                      }}
                      style={{...styles.buttonHeight, marginTop: 2}}
                  >
                    <i className="icon-search"/>Excel 2
                  </button>

                </div>
              </div>
              <div className="row">
                <div className="col-12 text-danger">
                  *** เงื่อนไขการจัดไฟแนนซ์ : เงื่อนไขเฉพาะคัน เช่น รถติด backlist TTB
                </div>

                <div className="col-12 tableFixHead" >
                      <table className="table table-striped table-responsive">
                        <thead>
                        <tr>
                          <th className="text-center th-sticky" >ลำดับ</th>
                          <th style={{width: 150}} className=" text-nowrap">รูป</th>
                          <th style={{width: 150}} className=" text-nowrap th-sticky">สถานที่จอด</th>
                          <th style={{width: 150}} className=" text-nowrap">หัวบิล/ซื้อรถจาก</th>
                          <th style={{width: 150}} className=" text-nowrap">สาขา</th>
                          <th style={{width: 150}} className=" text-nowrap">ทะเบียน</th>
                          <th style={{width: 150}} className=" text-nowrap">ยี่ห้อ</th>
                          <th style={{width: 150}} className=" text-nowrap">รุ่น</th>
                          <th style={{width: 150}} className=" text-nowrap">ประเภทรถ</th>

                          <th style={{width: 150}} className=" text-nowrap">เกียร์</th>
                          <th style={{width: 150}} className=" text-nowrap">สี</th>
                          <th style={{width: 150}} className=" text-nowrap">ปี</th>

                          <th style={{width: 150}} className=" text-nowrap">ดอกเบี้ยเริ่มต้น</th>

                          <th style={{width: 150}} className={type ==='admin' ? "text-nowrap": "hidden" } >ทุนซื้อ</th>
                          <th style={{width: 150}} className={type ==='admin' ? "text-nowrap": "hidden" } >ทุนอื่นๆ</th>
                          <th style={{width: 150}} className={type ==='admin' ? "text-nowrap": "hidden" } >รวมทุน</th>

                          <th style={{width: 150}} className=" text-nowrap">ราคาขาย</th>

                          <th style={{width: 150}} className=" text-nowrap">ราคากลาง</th>
                          <th style={{width: 150}} className=" text-nowrap">ไฟแนนซ์</th>
                          <th style={{width: 150}} className=" text-nowrap">เงื่อนไขไฟแนนซ์</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 4 ปี (48 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 5 ปี (60 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 6 ปี (72 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ผ่อน 7 ปี (84 งวด)</th>
                          <th style={{width: 150}} className=" text-nowrap">ทะเบียน</th>

                          {/*<th style={{width: 150}} className=" text-nowrap">ผ่อน 8 ปี (96 งวด)</th>*/}
                          {
                            leasing_settings.map((leasing, index) => (
                                <th  key={index} className="text-nowrap">
                                  {leasing.name_credit}
                                </th>
                            ))
                          }
                          <th style={{width: 150}} className=" text-nowrap">จัดการ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {carList}
                        </tbody>
                      </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  input: {
    width: 80,
  },
  searchCar: {
    marginLeft: 0,
    marginTop: 5,
    marginBottom: -8,
    width: 180,
    borderRadius:5,
    display: 'inline'
  },
  buttonHeight: {
    height: 36,
    paddingTop: 0,
    paddingBottom:0,
    borderRadius:5,
    display: 'inline',
    marginTop: 0,
    marginBottom: 4,
  },
  selectFilter: {
    marginTop: 5,
    marginBottom: 0,
    borderRadius:5,
    display: 'inline'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(TrackInformationCarFinance);
