import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/th';
import NumberFormat from 'react-number-format';
import {CLOUDFRONT_URL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL} from '../../config/config';
import PreviewImage from '../../views/PreviewImage/';
import {  Modal, ModalBody } from 'reactstrap';
import {checkFormControls, checkGroupPermission} from "../../services/grouppermission";

class ExpenseWithdrawList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {},
            preview_image_url: '',
            preview_image_select: false
        }
        this.togglePreviewImage = this.togglePreviewImage.bind(this)
        this.saveCarImage = this.saveCarImage.bind(this)
    }

    componentWillMount () {
        //const queryString = require('query-string');
        this.setState({
            user: JSON.parse(this.props.user),
        })
    }

    onPreviewSelected(url){
        this.setState({
            preview_image_url: url,
            preview_image_select: true
        });
    }

    togglePreviewImage() {
        this.setState({
            preview_image_select: !this.state.preview_image_select
        });
    }

    saveCarImage(e,urlImage){
        e.preventDefault();
        let a = document.createElement('a');
        a.href = urlImage;
        a.target = '_blank';
        a.download = urlImage;
        a.click();
        a.remove();
        return false;
    }

    render(){
        const form_controls = this.props.form_controls
        const menuID = this.props.menuID

        const emptyList = <tr><td colSpan={11} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>
        const expenseListData = this.props.expense_list.map((expense,index)=>{

            /*let image_url = '';

            if(expense.expense_image_file === null) {
                image_url = IMAGE_NOPHOTO_URL
            } else {
                if (expense.expense_image_file.includes('https')) {
                    // image_url = expense.expense_image_file
                    image_url = CLOUDFRONT_URL+expense.expense_image_key
                } else {
                    if (expense.expense_image_file === 'nophoto.png') {
                        image_url = IMAGE_NOPHOTO_URL
                    } else {
                        image_url = IMAGE_FULL_URL + expense.expense_image_file
                    }
                }
            }*/

           /* let image_url2 = ''
            if(expense.expense_image_file2 === 'nophoto.png') {
                image_url2 = IMAGE_NOPHOTO_URL
            } else {
                if (expense.expense_image_file2 !== null && expense.expense_image_file2!== undefined) {
                    if (expense.expense_image_file2.includes('https')) {
                        // image_url = expense.expense_image_file
                        image_url2 = CLOUDFRONT_URL+expense.expense_image_key2
                    }else{
                        image_url2 = IMAGE_FULL_URL + expense.expense_image_file2
                    }
                }else{
                    image_url2 = IMAGE_NOPHOTO_URL
                }
            }*/

           // let invoice_text = checkFormControls('invoice_no', form_controls) ? <> เลขที่บิล : {expense.invoice_no} </> : null
           // let buy_from_company_text = checkFormControls('buy_from_company', form_controls) ? <>  <br/> ซื้อจาก : {expense.buy_from_company} </> : null
           // <p className="text-left mt-1">เลขที่บิล : {expense.invoice_no} <br/> ซื้อจาก : {expense.buy_from_company}</p>

            return (
                <tr key={index}>
                    <td className="text-center">{index+1}</td>
                    <td className="text-center text-nowrap">
                        {moment(expense.expense_date).locale('th').format('LL')}
                        {/*<br/>
                        <p className="text-left mt-1">
                            {invoice_text}
                            {buy_from_company_text}
                        </p>*/}

                    </td>

                    {/*<td className="text-center text-nowrap">
                        { expense.pay_by !== null || expense.pay_by !== ''?  expense.pay_by : '-'}
                    </td>*/}

                    <td className="text-center text-nowrap">{expense.branch_name}</td>
                    <td className=" text-nowrap">
                        <strong> หมวดค่าใช้จ่าย : </strong> {expense.income_expense_item_name}
                        <br/>
                        <strong> รายละเอียด : </strong> {expense.expense_name === '' ? '-': expense.expense_name}

                    </td>
                    {/*<td className="text-center text-nowrap">
                        {
                            image_url===IMAGE_NOPHOTO_URL ? null : <>
                                <a rel="noopener noreferrer" href={image_url}
                                   target="_blank"
                                   onClick={(e)=>this.saveCarImage(e, image_url)}
                                   className={image_url===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                                    <span>download</span>
                                </a>
                                <br/>
                                <br/>
                            </>
                        }
                        <img
                            src={image_url}
                            style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                            alt="เอกสาร1"
                            onClick={()=>this.onPreviewSelected(image_url)}
                        />
                    </td>*/}
                    {/*<td className="text-center">
                        {
                            image_url2===IMAGE_NOPHOTO_URL ? null : <>
                                <a rel="noopener noreferrer" href={image_url2}
                                   target="_blank"
                                   onClick={(e)=>this.saveCarImage(e, image_url2)}
                                   className={image_url2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                                    <span>download</span>
                                </a>
                                <br/>
                                <br/>
                            </>
                        }
                        <img
                            src={image_url2}
                            style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                            alt="เอกสาร2"
                            onClick={()=>this.onPreviewSelected(image_url2)}
                        />
                    </td>*/}

                    <td className="text-center text-nowrap">
                        <NumberFormat value={expense.expense_amount}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      decimalScale={2} fixedDecimalScale
                                      prefix={'฿'}/>

                        {/*{
                            expense.include_vat === 1 ? (
                                <>
                                    <br/>
                                    ** รวม VAT
                                </>
                            ) : (expense.expense_amount_vat === 0 ? <><br/> ** NO VAT</>: <><br/> ** ไม่รวม VAT</>)
                        }*/}

                    </td>
                   {/* <td className="text-center">
                        <NumberFormat value={expense.expense_amount_vat}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      decimalScale={2} fixedDecimalScale
                                      prefix={'฿'}/>
                    </td>*/}
                    <td className="text-center text-nowrap">
                        {expense.fullname}
                        <br/>
                        {moment(expense.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}
                    </td>
                    <td className={"text-center"} style={{width: 80}} >

                        {
                            checkGroupPermission(menuID, this.props.grouppermissions).modified ?
                                <button
                                    type="button"
                                    className="btn btn-warning btn-sm btn-block"
                                    style={{width: 90}}
                                    onClick={() => this.props.onEditExpenseModal(expense)}
                                >
                                    <i className="icon-pencil">&nbsp;แก้ไข</i>
                                </button>
                                : null
                        }

                        {/*{
                            checkGroupPermission(menuID, this.props.grouppermissions).printed ?
                                <button
                                    type="button"
                                    className="btn btn-success btn-sm btn-block mt-2"
                                    style={{width: 90}}
                                    onClick={() => this.props.onSaveFileModal(expense)}
                                >
                                    <i className="icon-doc">&nbsp;แนบไฟล์</i>
                                </button>
                                : null
                        }*/}

                        {
                            checkGroupPermission(menuID, this.props.grouppermissions).deleted ?
                                <button className="btn btn-danger btn-sm btn-block mt-2"
                                        style={{width: 90}}
                                        onClick={() => this.props.onDeleteHandle(expense)}
                                >
                                    <i className="fa fa-remove"/> ลบ
                                </button>
                                : null
                        }

                        {
                            checkGroupPermission(menuID, this.props.grouppermissions).modified === 0
                            &&
                            checkGroupPermission(menuID, this.props.grouppermissions).printed === 0
                            &&
                            checkGroupPermission(menuID, this.props.grouppermissions).deleted === 0
                                ? <i className="icon-close"/> : null

                        }

                    </td>
                </tr>
            )
        });

        const expenseList = this.props.expense_list.length === 0 ? emptyList : expenseListData

        return (
            <>
                <table className="table table-striped mt-1">
                    <thead>
                    <tr>
                        <th className="text-center text-nowrap">#</th>
                        <th className="text-center text-nowrap">วันที่ใช้เบิก</th>
                        {/*<th className="text-center text-nowrap">ผู้เบิก</th>*/}
                        <th className="text-center text-nowrap">สาขา</th>
                        <th className="text-center text-nowrap">รายละเอียด</th>
                        {/*<th className="text-center text-nowrap">ไฟล์เอกสาร 1</th>
                        <th className="text-center text-nowrap">ไฟล์เอกสาร 2</th>*/}
                        <th className="text-center text-nowrap">ยอดเบิกเงิน</th>
                        {/*<th className="text-center text-nowrap">VAT</th>*/}
                        <th className="text-center text-nowrap">ผู้บันทึก</th>
                        <th className={"text-center"}>
                            จัดการ
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {expenseList}
                    </tbody>
                </table>

                {/*<div>
                    *** ยอดเงิน = ยอดเงินรวม VAT ถ้ามี
                </div>*/}

                <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                    <ModalBody>
                        <PreviewImage
                            imageUrl={this.state.preview_image_url}
                        />
                    </ModalBody>
                </Modal>
                {/*<ul className="pagination">
                    <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                    <li className="page-item active">
                        <a className="page-link" href="#">1</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">4</a></li>
                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                </ul>*/}
            </>
        )
    }
}

const styles = {
    overflowY: {
        overflow: 'scroll',
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ExpenseWithdrawList)
